/** Converts string to Date correctly formatted */
import {isNull} from "lodash";

export function convertDate(value: Date | string): Date | null {
    if (value instanceof Date) {
        return value;
    }
    if (/^\d{4}-(\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i.test(value)) {
        return new Date(value);
    }
    return null;
}

/** Converts a Base64 String to a File */
export function dataURLtoFile(data: string, filename: string): File | null {
    let arr = data.split(',');
    if (arr.length !== 2) {
        return null;
    }
    const [prefix, raw] = arr;
    const mimeMatch = prefix.match(/:(.*?);/);
    if (!mimeMatch) {
        return null;
    }
    let mime = mimeMatch[1];
    let bstr = new Buffer(raw, 'base64').toString('utf-8');
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
}

/** Generates a random string with n length */
export function randomAZ(n: number): string {
    return Array(n)
        .fill(null)
        .map(() => Math.random() * 100 % 25 + 'a'.charCodeAt(0))
        .map(a => String.fromCharCode(a))
        .join('');
}

/** Generates a safe css class name for given string */
export function safeCSSId(str: string): string {
    let encoded = encodeURIComponent(str).toLowerCase();
    encoded = encoded.replace(/\./gi, '-');
    return encoded.replace(/\.|%[0-9a-z]{2}/gi, '');
}

/** Converts string from snake case to camel case */
export function toCamelCase(e: string): string {
    return e.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
}

/** Converts string from camel case to snake case */
export function toSnakeCase(e: string): string {
    const match = e.match(/([A-Z])/g)
    if (match) {
        return match.reduce((str, c) => str.replace(new RegExp(c), '_' + c.toLowerCase()), e)
            .substring((e.slice(0, 1).match(/([A-Z])/g)) ? 1 : 0);
    }

    return e;
}

/**
 * Parses given version string to major, minor, patch, prerelease and meta versions
 * @returns - major, minor, patch, prerelease, meta
 */
export function parseSemver(version: string): [number, number, number, string, string] | null {
    let SEMVER_REGEX = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][\da-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][\da-zA-Z-]*))*))?(?:\+([\da-zA-Z-]+(?:\.[\da-zA-Z-]+)*))?$/;
    const match = version.match(SEMVER_REGEX);

    if (isNull(match)) {
        return null;
    }

    const [major, minor, patch, prerelease, meta]: RegExpMatchArray = match;

    return [parseInt(major), parseInt(minor), parseInt(patch), prerelease, meta];
}


/**
 * Function to generate URL search
 * @param search
 */
export function buildParams<T extends Record<string, string | string[]>>(search: T): string {
    if (!search) return "";

    const params = new URLSearchParams();

    Object.entries(search).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((v) => {
                params.append(key, v);
            });
        }
        else params.append(key, value.toString());
    });

    return params.toString();
}