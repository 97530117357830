import Header from "../../layouts/Header";
import {Button, Container, Form, FormGroup, Input, Label, UncontrolledAlert} from "reactstrap";
import React, {FunctionComponent, useContext, useState, ChangeEvent} from "react";
import {Redirect, useHistory} from "react-router-dom";
import {UserContext} from "../../user";
import {PasswordField} from "../../components/Forms";

const LoginView: FunctionComponent = (): JSX.Element => {
  const {login, currentUser} = useContext(UserContext);
  let history = useHistory();
  const [message, setMessage] = useState('');

  const submitLogin = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    login(event.currentTarget.email.value, event.currentTarget.password.value)
        .then((response) => {
          if (response) {
            history.push('/');
          } else {
            setMessage('' + response);
          }
        })
        .catch(reason => {
          setMessage('' + reason);
        })
  };

  if(currentUser){
    return <Redirect to={'/'} />;
  }

  return <>
    <Header />
    <Container fluid>
      {message ? <UncontrolledAlert color="danger">{message}</UncontrolledAlert> : null}
      <Form onSubmit={submitLogin}>
        <FormGroup>
          <Label for="email-field">Email</Label>
          <Input id="email-field" type="email" name="email" autoComplete="email" />
        </FormGroup>
        <FormGroup>
          <Label for="password-field">Password</Label>
          <PasswordField id="password-field" autoComplete="current-password" name="password" />
        </FormGroup>
        <FormGroup>
          <Button type="submit">Login</Button>
        </FormGroup>
      </Form>
    </Container>
  </>
}

export default LoginView;