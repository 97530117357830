import {IIconType} from "./type";

export const PloneLogo: IIconType = (props) => {
    const {fill, ...extra_props} = props;

    return <svg xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 40.69 40.69"
                version="1.1"  {...extra_props}>
        <path style={{fill: fill || '#000', stroke: 'none', fillRule: 'nonzero', fillOpacity: 1}}
              d="M 17.714844 7.164063 C 20.148438 7.164063 22.117188 9.132813 22.117188 11.5625 C 22.117188 13.996094 20.148438 15.96875 17.714844 15.96875 C 15.285156 15.96875 13.316406 13.996094 13.316406 11.5625 C 13.316406 9.132813 15.285156 7.164063 17.714844 7.164063 "/>
        <path style={{fill: fill || '#000', stroke: 'none', fillRule: 'nonzero', fillOpacity: 1}}
              d="M 31.070313 20.359375 C 31.070313 22.792969 29.097656 24.761719 26.664063 24.761719 C 24.238281 24.761719 22.265625 22.792969 22.265625 20.359375 C 22.265625 17.925781 24.238281 15.957031 26.664063 15.957031 C 29.101563 15.957031 31.070313 17.929688 31.070313 20.359375 "/>
        <path style={{fill: fill || '#000', stroke: 'none', fillRule: 'nonzero', fillOpacity: 1}}
              d="M 17.703125 24.777344 C 20.132813 24.777344 22.105469 26.75 22.105469 29.179688 C 22.105469 31.613281 20.132813 33.582031 17.703125 33.582031 C 15.269531 33.582031 13.300781 31.613281 13.300781 29.179688 C 13.300781 26.75 15.269531 24.777344 17.703125 24.777344 "/>
        <path style={{fill: fill || '#000', stroke: 'none', fillRule: 'nonzero', fillOpacity: 1}}
              d="M 31.792969 31.796875 C 28.855469 34.730469 24.820313 36.539063 20.339844 36.539063 C 15.863281 36.539063 11.828125 34.730469 8.890625 31.796875 C 5.957031 28.859375 4.148438 24.824219 4.148438 20.347656 C 4.148438 15.871094 5.957031 11.835938 8.894531 8.898438 C 11.832031 5.964844 15.863281 4.152344 20.34375 4.152344 C 24.820313 4.152344 28.855469 5.964844 31.792969 8.898438 C 34.726563 11.835938 36.535156 15.871094 36.535156 20.347656 C 36.535156 24.824219 34.726563 28.859375 31.792969 31.796875 M 20.34375 0.00390625 C 9.105469 0.00390625 0 9.109375 0 20.347656 C 0 31.582031 9.105469 40.6875 20.34375 40.691406 C 31.578125 40.6875 40.683594 31.582031 40.6875 20.347656 C 40.683594 9.109375 31.578125 0.00390625 20.34375 0.00390625 "/>
    </svg>;
};

export const ZopeLogo: IIconType = (props) => {
    const {fill, ...extra_props} = props;
    return <svg version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 14 14" {...extra_props}>
        <path style={{fill: fill || '#000'}}
              d="M13.867,7.049c0,3.762-3.05,6.813-6.813,6.813s-6.813-3.051-6.813-6.813c0-3.763,3.05-6.813,6.813-6.813
	S13.867,3.287,13.867,7.049z M7.214,13.205c3.458-0.154,4.421-2.066,4.715-2.426c-2.077-0.167-9.833-0.097-9.833-0.097
	S3.756,13.357,7.214,13.205z M0.959,5.95c0,0-0.373,1.931,0.385,3.309C3.012,5.93,9.62,2.556,9.793,2.37
	C9.479,2.131,4.5,1.657,2.345,3.082C1.331,3.851,0.959,5.95,0.959,5.95z M12.546,9.817c0,0,1.745-2.925-0.289-6.101
	c-0.494-0.34-7.898,5.177-8.564,6.004C8.17,9.624,12.546,9.817,12.546,9.817z M7.012,1.445c1.309-0.019,3.186,0.308,3.186,0.308
	S9.269,0.888,7.012,0.888c-2.256,0-3.55,1.116-3.55,1.116S5.704,1.465,7.012,1.445z"/>
    </svg>;
};