import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Card, CardBody, CardFooter, CardHeader, CardImg, Container, Progress, Spinner} from "reactstrap";
import Header from "../../layouts/Header";
import {Link, useHistory} from "react-router-dom";
import {buildParams} from "../../helper";
import _ from 'lodash';
import Select, {MultiValue} from 'react-select';
import {FaChevronRight} from "react-icons/all";
import {useUserContext} from "../../user";
import PipelineStatus from "./PipelineStatus";
import {
    GitLabNamespace,
    GitLabPipeline,
    GitLabProject,
    GitLabProjectData,
    PipelineStateKeys,
    PipelineStates,
    PipelineStateValue,
    RouteElement
} from "../../types";
import {BranchIcon, ReleaseIcon} from "../../components/Icons/gitlab";
import {LastUpdate, useQuery} from "../../components";
import useUnmountSignal from 'use-unmount-signal';
import classNames from "classnames";

interface ProjectEntryProps {
    project: GitLabProject
    selectedBranches: string[]
    selectedPipelineStates: string[]
    route: RouteElement
}

const ProjectEntry: FunctionComponent<ProjectEntryProps> = (props) => {
    const {project, selectedBranches, selectedPipelineStates, route} = props;
    const branches: Array<[string, GitLabPipeline | undefined]> = [];

    project.branches.forEach((ref) => {
        if (selectedBranches.length && !selectedBranches.includes(ref)) {
            return;
        }

        const pipeline = project.pipelines.find((pipeline) => pipeline.ref === ref);
        if (selectedPipelineStates.length) {
            if (!selectedPipelineStates.includes(pipeline ? pipeline.status : 'null')) {
                return;
            }
        }
        branches.push([ref, pipeline]);
    });

    branches.sort((a, b) => {
        const indexB = project.protected_branches.indexOf(b[0]);
        const indexA = project.protected_branches.indexOf(a[0]);
        return (indexA > indexB) ? -1 : (indexA === indexB) ? 0 : 1;
    });

    return <Card className={'project-entry shadow'}>
        <CardHeader>
            <CardImg className={'project-avatar'} src={project.avatar ?? project.namespace.avatar ?? '/fallback.svg'}/>
            <div className={'project-name-wrapper'}>
                <div className={'project-namespace'}>
                    <div className={'project-namespace'}>
                        <a title={project.namespace.name} href={project.namespace.url}>{project.namespace.name}</a>
                    </div>
                </div>
                <div className={'project-name'}>
                    <a title={project.name} href={project.url}>{project.name}</a>
                </div>
            </div>
            <Link to={`${route.path}/${project.namespace.full_path}/${project.path}`}><FaChevronRight/></Link>
        </CardHeader>
        <CardBody>
            <ul className={'project-pipelines'}>
                {branches.map(([ref, pipeline], key) => {
                    return <li
                        className={'project-pipeline'}
                        data-protected={project.protected_branches.indexOf(ref) > -1}
                        key={key}
                    >
                        <PipelineStatus branch={ref} pipeline={pipeline} className={'pipeline-status'}/>
                        <a className={'pipeline-ref'}
                           href={`${project.url}/-/tree/${ref}`}
                           target={'_blank'}
                           rel={'noopener noreferrer'}
                           style={{fontSize: '0.8rem'}}
                           title={ref}>
                            {ref}
                        </a>
                    </li>;
                })}
            </ul>
        </CardBody>
        <CardFooter>
            <span className="releases" title="Releases"><ReleaseIcon/> {project.releases.length}</span>
            <span className="branches" title="Branches"><BranchIcon/> {project.branches.length}</span>
        </CardFooter>
    </Card>
}
ProjectEntry.propTypes = {
    project: PropTypes.instanceOf(GitLabProject).isRequired,
    selectedBranches: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    selectedPipelineStates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    route: PropTypes.instanceOf(RouteElement).isRequired
}


const ProjectStateOverview: FunctionComponent<{ filter: IFilters }> = ({filter}) => {
    const [hoverOverview, setHoverOverview] = useState<boolean>(false);
    let branchCount = 0;
    filter.pipelineStates.forEach((f) => {
        branchCount += f.branchCount;
    });

    return <div className="state-overview">
        <Progress multi
                  onMouseOver={() => setHoverOverview(true)}
                  onMouseOut={() => setHoverOverview(false)}
                  className={classNames({'hover': hoverOverview})}
        >
            {filter.pipelineStates.filter(e => e.branchCount).map((e) => <Progress
                bar={true}
                key={e.value}
                max={branchCount}
                value={e.branchCount}
                style={{backgroundColor: e.color}}
            />)}
        </Progress>
    </div>;
}


interface IFilter {
    value: string
    label: string
    isSelected: boolean
    richLabel: JSX.Element
}

interface IPipelineFilter extends IFilter {
    value: PipelineStateKeys
    color: string
    branchCount: number
}

interface IFilters {
    pipelineStates: IPipelineFilter[]
    namespaces: IFilter[]
    branches: IFilter[]
    names: IFilter[]
}

type IQuery = {
    status?: string | string[]
    namespace?: string | string[]
    branch?: string | string[]
    name?: string | string[]
}

const ProjectListing: FunctionComponent<{ route: RouteElement }> = (props) => {
    const {route} = props;
    const {request, addNotification} = useUserContext();
    const history = useHistory();
    const unmountSignal = useUnmountSignal();
    const query = useQuery<IQuery>();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [projects, setProjects] = useState<GitLabProject[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
    const [filter, setFilter] = useState<IFilters>({
        pipelineStates: [],
        namespaces: [],
        branches: [],
        names: []
    });

    // Inital loading and refreshing of Projects
    useEffect(() => {
        if (unmountSignal.aborted) return;

        const updateProjects = (interval_duration?: number): NodeJS.Timer | undefined => {
            setIsLoading(true);
            request<GitLabProjectData[]>('/gitlab/projects', 'GET', undefined, unmountSignal)
                .then((projectsData) => {
                    setIsLoaded(true);
                    setIsLoading(false);
                    setLastUpdate(new Date());
                    setProjects(_projects => projectsData.map((projectData) => {
                        const index = _.findIndex(_projects, {id: projectData.id});
                        if (index >= 0) {
                            const project = _projects[index];
                            return project.update(projectData);
                        }
                        return new GitLabProject(projectData);
                    }));
                })
                .catch(reason => unmountSignal.aborted ? '' : addNotification('Fehler', reason.toString(), 'danger'));

            if (interval_duration) {
                return setInterval(() => updateProjects(), interval_duration);
            }
            return undefined;
        }
        const interval = updateProjects(60 * 1000);
        return () => clearInterval(interval);
    }, [request, addNotification, unmountSignal]);

    // Generate all possible Filters
    useEffect(() => {
        const {status, namespace, branch, name} = query;
        const states = status ? (status instanceof Array) ? status : [status] : [];
        const namespaces = namespace ? (namespace instanceof Array) ? namespace : [namespace] : [];
        const branches = branch ? (branch instanceof Array) ? branch : [branch] : [];
        const names = (name ? (name instanceof Array) ? name : [name] : []);

        type PipelineStateMatchValue = { stateValue: PipelineStateValue, branches: Set<string>, branchCount: number };
        let pipelineStateMatches: Record<string, PipelineStateMatchValue> = Object
            .entries(PipelineStates)
            .reduce((obj: Record<string, PipelineStateMatchValue>, [key, value]) => {
                obj[key] = {
                    stateValue: value,
                    branches: new Set(),
                    branchCount: 0
                };
                return obj;
            }, {});

        let branchMatches: Record<string, number> = {};
        let namespaceMatches: Record<string, { namespace: GitLabNamespace, matches: number }> = {};
        projects.forEach((project) => {
            const {namespace} = project;
            if (!namespaceMatches.hasOwnProperty(namespace.full_path)) {
                namespaceMatches[namespace.full_path] = {
                    namespace: namespace,
                    matches: 0
                };
            }
            namespaceMatches[namespace.full_path].matches++;

            if (!project.pipelines.length) {
                for (let branch of project.branches) {
                    pipelineStateMatches['null'].branches.add(branch);
                    pipelineStateMatches['null'].branchCount++;
                }
            }

            project.pipelines.forEach((pipeline) => {
                const {status, ref} = pipeline;
                pipelineStateMatches[status].branches.add(ref);
                pipelineStateMatches[status].branchCount++;
                branchMatches[ref] = branchMatches[ref] ? branchMatches[ref] + 1 : 1;
            });
        });

        setFilter({
            pipelineStates: Object.entries(pipelineStateMatches).map(([state, {stateValue, branches, branchCount}]) => {
                const style = {'--svg-status-fg': stateValue.color} as React.CSSProperties;
                return {
                    value: state as PipelineStateKeys,
                    label: stateValue.label,
                    richLabel: <span className="filter-value">
                        <stateValue.icon className="icon" style={style}/>
                        {" "}
                        <span className="title" title={stateValue.label}>{stateValue.label}</span> <small
                        className="matches">{branches.size}</small>
                    </span>,
                    isSelected: states.includes(state),
                    branchCount: branchCount,
                    color: stateValue.color
                };
            }),
            namespaces: Object.entries(namespaceMatches).map(([value, {namespace, matches}]) => {
                return {
                    value: value,
                    label: namespace.name,
                    richLabel: <span className="filter-value">
                        <img height={32} width={32} src={namespace.avatar ?? '/fallback.svg'} alt={""}/>{" "}
                        <span className="title" title={namespace.name}>{namespace.name}</span> <small
                        className="matches">{matches}</small>
                    </span>,
                    isSelected: namespaces.includes(value)
                };
            }),
            branches: Object.entries(branchMatches).map(([branch, matches]) => {
                return {
                    value: branch,
                    label: branch,
                    richLabel: <span className="filter-value"><span className="title"
                                                                    title={branch}>{branch}</span> <small
                        className="matches">{matches}</small></span>,
                    isSelected: branches.includes(branch)
                };
            }),
            names: projects.map(({name, avatar}) => {
                return {
                    value: name,
                    label: name,
                    richLabel: <span className="filter-value">
                        <img alt="" height={32} width={32} src={avatar ?? '/fallback.svg'}/>{" "}
                        <span className="title" title={name}>{name}</span>
                    </span>,
                    isSelected: names.includes(name)
                };
            })
        });
    }, [projects, query]);

    // Methods to update Filter based on selection
    const filterPipelineStatus = useCallback((selectedOptions: MultiValue<{ value: string, label: string }>) => {
        const activeSelections = selectedOptions.map(({value}) => value);
        setFilter(oldFilter => {
            const newFilter = {
                pipelineStates: oldFilter.pipelineStates.map((value) => {
                    value.isSelected = activeSelections.includes(value.value);
                    return value;
                }),
                namespaces: oldFilter.namespaces,
                branches: oldFilter.branches,
                names: oldFilter.names,
            };
            history.replace({
                search: buildParams({
                    namespace: newFilter.namespaces.filter(({isSelected}) => isSelected).map(({value}) => value),
                    branch: newFilter.branches.filter(({isSelected}) => isSelected).map(({value}) => value),
                    status: newFilter.pipelineStates.filter(({isSelected}) => isSelected).map(({value}) => value)
                })
            });

            return newFilter;
        })
    }, [history]);
    const filterNamespaces = useCallback((selectedOptions: MultiValue<{ value: string, label: string }>) => {
        const activeSelections = selectedOptions.map(({value}) => value);
        setFilter(oldFilter => {
            const newFilter = {
                pipelineStates: oldFilter.pipelineStates,
                namespaces: oldFilter.namespaces.map((value) => {
                    value.isSelected = activeSelections.includes(value.value);
                    return value;
                }),
                branches: oldFilter.branches,
                names: oldFilter.names,
            };
            history.replace({
                search: buildParams({
                    namespace: newFilter.namespaces.filter(({isSelected}) => isSelected).map(({value}) => value),
                    branch: newFilter.branches.filter(({isSelected}) => isSelected).map(({value}) => value),
                    status: newFilter.pipelineStates.filter(({isSelected}) => isSelected).map(({value}) => value)
                })
            });

            return newFilter;
        });
    }, [history]);
    const filterBranches = useCallback((selectedOptions: MultiValue<{ value: string, label: string }>) => {
        const activeSelections = selectedOptions.map(({value}) => value);
        setFilter(oldFilter => {
            const newFilter = {
                pipelineStates: oldFilter.pipelineStates,
                namespaces: oldFilter.namespaces,
                branches: oldFilter.branches.map((branchValue) => {
                    branchValue.isSelected = activeSelections.includes(branchValue.value);
                    return branchValue;
                }),
                names: oldFilter.names,
            }
            history.replace({
                search: buildParams({
                    namespace: newFilter.namespaces.filter(({isSelected}) => isSelected).map(({value}) => value),
                    branch: newFilter.branches.filter(({isSelected}) => isSelected).map(({value}) => value),
                    status: newFilter.pipelineStates.filter(({isSelected}) => isSelected).map(({value}) => value)
                })
            });

            return newFilter;
        });
    }, [history]);
    const filterNames = useCallback((selectedOptions: MultiValue<{ value: string, label: string }>) => {
        const activeSelections = selectedOptions.map(({value}) => value);
        setFilter(oldFilter => {
            const newFilter = {
                pipelineStates: oldFilter.pipelineStates,
                namespaces: oldFilter.namespaces,
                branches: oldFilter.branches,
                names: oldFilter.names.map((name) => {
                    name.isSelected = activeSelections.includes(name.value);
                    return name;
                }),
            }
            history.replace({
                search: buildParams<IQuery>({
                    namespace: newFilter.namespaces.filter(({isSelected}) => isSelected).map(({value}) => value),
                    branch: newFilter.branches.filter(({isSelected}) => isSelected).map(({value}) => value),
                    status: newFilter.pipelineStates.filter(({isSelected}) => isSelected).map(({value}) => value),
                    name: newFilter.names.filter(({isSelected}) => isSelected).map(({value}) => value),
                })
            });

            return newFilter;
        });
    }, [history]);

    // generation of active Filter
    const selectedFilterReducer = (arr: IFilter[], value: IFilter|IPipelineFilter) => {
        if (value.isSelected) {
            if('branchCount' in value && value.branchCount === 0){
                return arr;
            }
            arr.push(value);
        }
        return arr;
    };
    const selectedNamespaces = filter.namespaces.reduce(selectedFilterReducer, []);
    const selectedBranches = filter.branches.reduce(selectedFilterReducer, []);
    const selectedPipelineFilters = filter.pipelineStates.reduce(selectedFilterReducer, []);
    const selectedNames = filter.names.reduce(selectedFilterReducer, []);

    const project_filter = (project: GitLabProject): boolean => {
        let namespaceMatch = true;
        // region Namespace
        if (selectedNamespaces.length) {
            namespaceMatch = selectedNamespaces.some(({value}) => value === project.namespace.full_path);
        }
        // endregion

        let branchMatch = true;
        // region Branch
        if (selectedBranches.length) {
            branchMatch = selectedBranches.some(({value}) => (project.branches.includes(value)));
        }
        // endregion

        let pipelineStateMatch = true;
        // region PipelineState
        if (selectedPipelineFilters.length) {
            const selectedStates = selectedPipelineFilters.map((({value}) => value));
            pipelineStateMatch = project.branches.some((branch) => {
                const pipeline = project.pipelines.find(({ref}) => ref === branch);
                return pipeline ? selectedStates.includes(pipeline.status) : selectedStates.includes('null');
            });
        }
        // endregion

        let projectMatch = true;
        // region Project
        if (selectedNames.length) {
            projectMatch = selectedNames.some(({value}) => value === project.name);
        }
        // endregion

        // Test
        return namespaceMatch && branchMatch && pipelineStateMatch && projectMatch;
    }

    if (!isLoaded) {
        return <>
            <Header/>
            <div className="mt-3" style={{textAlign: 'center'}}><Spinner/></div>
        </>
    }

    const filteredProjects = projects.filter(project_filter);

    return <>
        <Header/>
        <Container className="mt--6 listing-view project-listing" fluid>
            <Card className="shadow list-actions">
                <div className="filter">
                    <div className="filter-pipelineStates">
                        <Select
                            isMulti
                            placeholder={"Status"}
                            value={selectedPipelineFilters}
                            options={filter.pipelineStates.sort((a, b) => {
                                return a.branchCount > b.branchCount ? .1 : a.branchCount < b.branchCount ? 1 : 0;
                            })}
                            onChange={filterPipelineStatus}
                            formatOptionLabel={(data) => data.richLabel}
                        />
                    </div>
                    <div className="filter-name">
                        <Select
                            isMulti
                            placeholder={"Name"}
                            value={selectedNames}
                            options={filter.names}
                            onChange={filterNames}
                            formatOptionLabel={(data) => data.richLabel}
                        />
                    </div>
                    <div className={'filter-namespaces'}>
                        <Select
                            isMulti
                            placeholder={"Namespace"}
                            value={selectedNamespaces}
                            options={filter.namespaces}
                            onChange={filterNamespaces}
                            formatOptionLabel={(data) => data.richLabel}
                        />
                    </div>
                    <div className={'filter-branches'}>
                        <Select
                            isMulti
                            placeholder={"Branches"}
                            value={selectedBranches}
                            options={filter.branches}
                            onChange={filterBranches}
                            formatOptionLabel={(data) => data.richLabel}
                        />
                    </div>
                </div>
                <ProjectStateOverview filter={filter}/>
            </Card>
            <div className={'project-entries'}>
                {filteredProjects.map((project, key) => <ProjectEntry
                    project={project}
                    key={key}
                    selectedBranches={filter.branches.filter(e => e.isSelected).map(e => e.value)}
                    selectedPipelineStates={filter.pipelineStates.filter(e => e.isSelected).map(e => e.value)}
                    route={route}
                />)}
            </div>
            <LastUpdate date={lastUpdate} loading={isLoading}/>
        </Container>
    </>;
};

export default ProjectListing;

