import {
  StatusCanceled,
  StatusCreated,
  StatusFailed,
  StatusManual,
  StatusPending,
  StatusPreparing,
  StatusRunning,
  StatusScheduled,
  StatusSkipped,
  StatusSuccess,
  StatusWaiting,
  StatusWarning
} from "../components/Icons/gitlab";
import {BaseModel} from "./base";
import _, {isNull} from "lodash";
import {parseSemver} from "../helper";
import {FunctionComponent, HTMLAttributes, SVGAttributes} from "react";
import {IIconType} from "../components/Icons/type";


// region GitLabJob
type JobStatusKeys =
    'created'
    | 'pending'
    | 'running'
    | 'failed'
    | 'success'
    | 'canceled'
    | 'skipped'
    | 'manual'
    | 'null';

interface JobStatus {
  icon: FunctionComponent<HTMLAttributes<HTMLElement>> | FunctionComponent<SVGAttributes<SVGElement>>,
  label: string,
  color: string
}

export const JobStatusEnum: Record<JobStatusKeys, JobStatus> = {
  created: {
    icon: StatusCreated,
    label: 'Erstellt',
    color: '#AB6100'
  },
  pending: {
    icon: StatusPending,
    label: 'Anstehend',
    color: '#AB6100'
  },
  running: {
    icon: StatusRunning,
    label: 'Laufend',
    color: '#1F75CB'
  },
  failed: {
    icon: StatusFailed,
    label: 'Fehlgeschlagen',
    color: '#DD2B0E'
  },
  success: {
    icon: StatusSuccess,
    label: 'Erfolgreich',
    color: '#108548'
  },
  canceled: {
    icon: StatusCanceled,
    label: 'Abgebrochen',
    color: '#303030'
  },
  skipped: {
    icon: StatusSkipped,
    label: 'Übersprungen',
    color: '#9e9e9e'
  },
  manual: {
    icon: StatusManual,
    label: 'Manuell',
    color: '#AB6100'
  },
  null: {
    icon: StatusWarning,
    label: 'Inexistent',
    color: '#000'
  }
} as const;

type GitLabJobData = {
  id: number
  url: string
  status: JobStatusKeys
  stage: string
  name: string
  ref: string
  author: string
  author_url: string
  author_image_url: string
  commit_url: string
  commit_title: string
  commit_message: string
  created_at: string
  started_at: string | null
  finished_at: string | null
  duration: number | null
  pipeline?: GitLabPipelineData | GitLabPipeline
  project?: GitLabProjectData | GitLabProject
}

export class GitLabJob extends BaseModel {
  url: string;
  status: JobStatusKeys;
  stage: string;
  name: string;
  ref: string;
  author: string;
  author_url: string;
  author_image_url: string;
  commit_url: string;
  commit_title: string;
  commit_message: string;
  created_at: Date = new Date(0);
  started_at: Date | null;
  finished_at: Date | null;
  duration: number | null;
  pipeline: GitLabPipeline | null;
  project: GitLabProject | null;

  constructor(jobData: GitLabJobData, project?: GitLabProject, pipeline?: GitLabPipeline) {
    super(jobData);
    this.url = jobData.url;
    this.status = jobData.status;
    this.stage = jobData.stage;
    this.name = jobData.name;
    this.ref = jobData.ref;
    this.author = jobData.author;
    this.author_url = jobData.author_url;
    this.author_image_url = jobData.author_image_url;
    this.commit_url = jobData.commit_url;
    this.commit_title = jobData.commit_title;
    this.commit_message = jobData.commit_message;
    this.created_at = this.convertDate(jobData.created_at) ?? new Date(0);
    this.started_at = this.convertDate(jobData.started_at) ?? new Date(0);
    this.finished_at = this.convertDate(jobData.finished_at) ?? new Date(0);
    this.duration = jobData.duration;
    this.pipeline = pipeline ? pipeline : null;
    this.project = project ? project : null;

    if (!pipeline && jobData.pipeline) {
      if (jobData.pipeline instanceof GitLabPipeline) {
        this.pipeline = jobData.pipeline;
      }
      else {
        this.pipeline = new GitLabPipeline(jobData.pipeline);
      }
    }
    if (!project && jobData.project) {
      if (jobData.project instanceof GitLabProject) {
        this.project = jobData.project;
      }
      else {
        this.project = new GitLabProject(jobData.project);
      }
    }
  }

  update(data: Partial<GitLabJobData>, project?: GitLabProject, pipeline?: GitLabPipeline): this {
    super.update(data);
    this.url = data.url ?? this.url;
    this.status = data.status ?? this.status;
    this.stage = data.stage ?? this.stage;
    this.name = data.name ?? this.name;
    this.ref = data.ref ?? this.ref;
    this.author = data.author ?? this.author;
    this.author_url = data.author_url ?? this.author_url;
    this.author_image_url = data.author_image_url ?? this.author_image_url;
    this.commit_url = data.commit_url ?? this.commit_url;
    this.commit_title = data.commit_title ?? this.commit_title;
    this.commit_message = data.commit_message ?? this.commit_message;
    this.created_at = this.convertDate(data.created_at) ?? this.created_at;
    this.started_at = this.convertDate(data.started_at) ?? this.started_at;
    this.finished_at = this.convertDate(data.finished_at) ?? this.finished_at;
    this.duration = data.duration ?? this.duration;

    if (data.pipeline) {
      if (data.pipeline instanceof GitLabPipeline) {
        this.pipeline = data.pipeline;
      }
      else {
        this.pipeline = this.pipeline ? this.pipeline.update(data.pipeline) : new GitLabPipeline(data.pipeline);
      }
    }
    this.pipeline = pipeline ?? this.pipeline;

    if (data.project) {
      if (data.project instanceof GitLabProject) {
        this.project = data.project;
      }
      else {
        this.project = this.project ? this.project.update(data.project) : new GitLabProject(data.project);
      }
    }
    this.project = project ?? this.project;

    return this;
  }
}

// endregion

// region GitLabPipeline
export type PipelineStateKeys =
    'created'
    | 'waiting_for_resource'
    | 'preparing'
    | 'pending'
    | 'running'
    | 'success'
    | 'failed'
    | 'canceled'
    | 'skipped'
    | 'manual'
    | 'schedule'
    | 'null';

export interface PipelineStateValue {
  icon: IIconType
  label: string
  color: string
}

export const PipelineStates: Readonly<Record<PipelineStateKeys, PipelineStateValue>> = {
  created: {
    icon: StatusCreated,
    label: 'Erstellt',
    color: '#AB6100'
  },
  waiting_for_resource: {
    icon: StatusWaiting,
    label: 'Warten auf Ressource',
    color: '#AB6100'
  },
  preparing: {
    icon: StatusPreparing,
    label: 'Vorbereitung',
    color: '#AB6100'
  },
  pending: {
    icon: StatusPending,
    label: 'Anstehend',
    color: '#AB6100'
  },
  running: {
    icon: StatusRunning,
    label: 'Laufend',
    color: '#1F75CB'
  },
  success: {
    icon: StatusSuccess,
    label: 'Erfolgreich',
    color: '#108548'
  },
  failed: {
    icon: StatusFailed,
    label: 'Fehlgeschlagen',
    color: '#DD2B0E'
  },
  canceled: {
    icon: StatusCanceled,
    label: 'Abgebrochen',
    color: '#303030'
  },
  skipped: {
    icon: StatusSkipped,
    label: 'Übersprungen',
    color: '#9e9e9e'
  },
  manual: {
    icon: StatusManual,
    label: 'Manuell',
    color: '#AB6100'
  },
  schedule: {
    icon: StatusScheduled,
    label: 'Geplant',
    color: '#AB6100'
  },
  null: {
    icon: StatusWarning,
    label: 'N/A',
    color: '#b9b9b9'
  }
};


type GitLabPipelineData = {
  id: number
  created_at: string | null
  updated_at: string | null
  project_id: number
  ref: string
  sha: string
  status: PipelineStateKeys
  web_url: string
  project?: GitLabProjectData | GitLabProject
  jobs?: Array<GitLabJobData | GitLabJob>
}

export class GitLabPipeline extends BaseModel {
  created_at: Date | null;
  updated_at: Date | null;
  ref: string;
  sha: string;
  status: PipelineStateKeys = 'null';
  web_url: string;
  project: GitLabProject | null;
  jobs: GitLabJob[] = [];

  constructor(pipelineData: GitLabPipelineData, project?: GitLabProject) {
    super(pipelineData);
    this.created_at = this.convertDate(pipelineData.created_at) ?? new Date(0);
    this.updated_at = this.convertDate(pipelineData.updated_at) ?? new Date(0);
    this.ref = pipelineData.ref;
    this.sha = pipelineData.sha;
    this.status = pipelineData.status;
    this.web_url = pipelineData.web_url;
    this.project = project ?? null;

    if (!project && pipelineData.project) {
      if (pipelineData.project instanceof GitLabProject) {
        this.project = pipelineData.project;
      }
      else {
        this.project = new GitLabProject(pipelineData.project);
      }
    }

    if (pipelineData.jobs && Array.isArray(pipelineData.jobs)) {
      this.jobs = pipelineData.jobs.map((jobData) => {
        if (jobData instanceof GitLabJob) {
          jobData.project = this.project;
          jobData.pipeline = this;
          return jobData;
        }
        return new GitLabJob(jobData, this.project ?? undefined, this);
      });
    }
  }

  update(data: Partial<GitLabPipelineData>, project?: GitLabProject): this {
    super.update(data);
    this.created_at = this.convertDate(data.created_at) ?? this.created_at;
    this.updated_at = this.convertDate(data.updated_at) ?? this.updated_at;
    this.ref = data.ref ?? this.ref;
    this.sha = data.sha ?? this.sha;
    this.status = data.status ?? this.status;
    this.web_url = data.web_url ?? this.web_url;

    if (data.project) {
      if (data.project instanceof GitLabProject) {
        this.project = data.project;
      }
      else {
        this.project = this.project ? this.project.update(data.project) : new GitLabProject(data.project);
      }
    }
    this.project = project ?? this.project;

    if (data.jobs) {
      this.jobs = data.jobs.map((jobData) => {
        if (jobData instanceof GitLabJob) {
          jobData.project = this.project;
          jobData.pipeline = this;
          return jobData;
        }

        const existingJob = this.jobs.find((e) => e.id === jobData.id);
        return existingJob ? existingJob.update(jobData) : new GitLabJob(jobData, this.project ?? undefined, this);
      });
    }

    return this;
  }

  /** is Pipeline of protected branch */
  get isProtected(): boolean | null {
    if (this.project) {
      return this.project.protected_branches.includes(this.ref);
    }
    return null;
  }
}

// endregion

// region GitLabRelease
type GitLabReleaseData = {
  tag_name: string
  description: string
  name: string
  created_at: string
  released_at: string
  milestones: object[]
}

export class GitLabRelease extends BaseModel {
  tag_name: string = '';
  description: string = '';
  name: string = '';
  created_at: Date | null = null;
  released_at: Date | null = null;
  milestones: Record<string, any>[] = [];
  project: GitLabProject | null = null;

  // generated
  major: number | null = null; // Major version of Release
  minor: number | null = null; // Minor version of Release
  patch: number | null = null; // Patch version of Release

  constructor(releaseData: GitLabReleaseData, project?: GitLabProject) {
    // @ts-ignore ToDo implement some id system for releases
    releaseData.id = null;
    super(releaseData);
    this.tag_name = releaseData.tag_name;
    this.description = releaseData.description;
    this.name = releaseData.name;
    this.created_at = this.convertDate(releaseData.created_at) ?? new Date(0);
    this.released_at = this.convertDate(releaseData.released_at) ?? new Date(0);
    this.milestones = releaseData.milestones;
    this.project = project ?? null;
    this.generateVersionData();
  }

  private generateVersionData() {
    if (!this.tag_name.startsWith('v')) {
      this.major = null;
      this.minor = null;
      this.patch = null;
      return;
    }

    const semver = this.tag_name.replace('v', '');
    const versions = parseSemver(semver);
    if (!isNull(versions)) {
      [this.major, this.minor, this.patch] = versions;
    }
  }

  update(releaseData: Partial<GitLabReleaseData>, project?: GitLabProject): this {
    this.tag_name = releaseData.tag_name ?? this.tag_name;
    this.description = releaseData.description ?? this.description;
    this.name = releaseData.name ?? this.name;
    this.created_at = this.convertDate(releaseData.created_at) ?? this.created_at;
    this.released_at = this.convertDate(releaseData.released_at) ?? this.released_at;
    this.milestones = releaseData.milestones ?? this.milestones;
    this.project = project ?? this.project;

    if (releaseData.tag_name) this.generateVersionData();
    return this;
  }

}

// endregion

// region GitLabProject
export type GitLabProjectData = {
  id: number
  name: string
  path: string
  description: string
  url: string
  avatar: string | null
  namespace: GitLabNamespaceData | GitLabNamespace
  archived: boolean
  releases: (GitLabReleaseData | GitLabRelease)[]
  branches: string[]
  protected_branches: string[]
  pipelines?: (GitLabPipelineData | GitLabPipeline)[]
}

type TGroupedReleases = Array<{
  label: string,
  version: number,
  releases: GitLabRelease[]
}>;

export class GitLabProject extends BaseModel {
  name: string;
  path: string;
  description: string;
  url: string;
  avatar: string | null;
  archived: boolean;
  releases: GitLabRelease[] = [];
  branches: string[];
  protected_branches: string[];
  namespace: GitLabNamespace;
  pipelines: GitLabPipeline[] = [];

  constructor(projectData: GitLabProjectData, namespace?: GitLabNamespace) {
    super(projectData);
    this.name = projectData.name;
    this.path = projectData.path;
    this.description = projectData.description;
    this.url = projectData.url;
    this.avatar = projectData.avatar;
    this.archived = projectData.archived;
    this.branches = projectData.branches;
    this.protected_branches = projectData.protected_branches;

    if (namespace) {
      this.namespace = namespace;
    }
    else {
      if (projectData.namespace instanceof GitLabNamespace) {
        this.namespace = projectData.namespace;
      }
      else {
        this.namespace = new GitLabNamespace(projectData.namespace);
      }
    }

    if (projectData.pipelines) {
      this.pipelines = projectData.pipelines.map((pipelineData) => {
        if (pipelineData instanceof GitLabPipeline) return pipelineData;
        return new GitLabPipeline(pipelineData, this);
      });
    }
    if (projectData.releases) {
      this.releases = projectData.releases.map((releaseData) => {
        if (releaseData instanceof GitLabRelease) return releaseData;
        return new GitLabRelease(releaseData, this);
      });
    }
  }

  update(projectData: Partial<GitLabProjectData>, namespace?: GitLabNamespace): this {
    super.update(projectData);
    this.name = projectData.name ?? this.name;
    this.path = projectData.path ?? this.path;
    this.description = projectData.description ?? this.description;
    this.url = projectData.url ?? this.url;
    this.avatar = projectData.avatar ?? this.avatar;
    this.archived = projectData.archived ?? this.archived;
    this.branches = projectData.branches ?? this.branches;
    this.protected_branches = projectData.protected_branches ?? this.protected_branches;

    if (projectData.namespace) {
      if (projectData.namespace instanceof GitLabNamespace) {
        this.namespace = projectData.namespace;
      }
      else {
        this.namespace = this.namespace ? this.namespace.update(projectData.namespace) : new GitLabNamespace(projectData.namespace);
      }
    }
    this.namespace = namespace ?? this.namespace;

    if (projectData.pipelines) {
      this.pipelines = projectData.pipelines.map((pipelineData) => {
        if (pipelineData instanceof GitLabPipeline) return pipelineData;
        const existingPipeline = this.pipelines.find((e) => e.id === pipelineData.id);
        return existingPipeline ? existingPipeline.update(pipelineData) : new GitLabPipeline(pipelineData);
      });
    }

    if (projectData.releases) {
      this.releases = projectData.releases.map((releaseData) => {
        if (releaseData instanceof GitLabRelease) return releaseData;
        const existingRelease = this.releases.find((e) => e.name === releaseData.name);
        return existingRelease ? existingRelease.update(releaseData) : new GitLabRelease(releaseData);
      });
    }

    return this;
  }

  /** Get latest Pipeline for given branch */
  getLatestPipeline(ref: string): GitLabPipeline | null {
    if (!this.pipelines) {
      return null;
    }
    if (!this.branches.includes(ref)) {
      return null;
    }
    let latestPipeline: GitLabPipeline | null = null;
    this.pipelines.forEach((pipeline) => {
      if (pipeline.ref !== ref) {
        return;
      }
      if (isNull(pipeline.updated_at)) {
        return;
      }
      if (isNull(latestPipeline)) {
        latestPipeline = pipeline;
      }
      // @ts-ignore 2564
      if (latestPipeline.updated_at < pipeline.updated_at) {
        latestPipeline = pipeline;
      }
    });
    return latestPipeline;
  }

  /** Get sorted list of Major releases */
  getGroupedReleases(): TGroupedReleases {
    const major_releases = this.releases.reduce((arr: TGroupedReleases, release: GitLabRelease) => {
      if (!release.major) {
        return arr;
      }

      const entry = _.find(arr, {version: release.major});
      if (entry) {
        entry.releases.push(release);
      }
      else {
        arr.push({
          label: `v${release.major}.x.x`,
          version: release.major,
          releases: [release]
        });
      }
      return arr;
    }, []);

    major_releases.sort((a, b) => {
      return a.version > b.version ? -1 : a.version < b.version ? 1 : 0;
    });

    return major_releases;
  }

  getURL(): string {
    return `/gitlab/projects/${this.namespace.full_path}/${this.name}`;
  }
}

// endregion

// region GitLabNamespace
type GitLabNamespaceData = {
  id: number
  name: string
  path: string
  kind: string
  full_path: string
  avatar: string
  url: string
  projects?: (GitLabProjectData | GitLabProject)[]
  namespace?: GitLabNamespaceData | GitLabNamespace
  namespaces?: (GitLabNamespaceData | GitLabNamespace)[]
}

export class GitLabNamespace extends BaseModel {
  name: string;
  path: string;
  kind: string;
  full_path: string;
  avatar: string | null;
  url: string;
  namespace: GitLabNamespace | null;
  namespaces: GitLabNamespace[] = [];
  projects: GitLabProject[] = [];

  constructor(namespaceData: GitLabNamespaceData, namespace?: GitLabNamespace) {
    super(namespaceData);
    this.name = namespaceData.name;
    this.path = namespaceData.path;
    this.kind = namespaceData.kind;
    this.full_path = namespaceData.full_path;
    this.avatar = namespaceData.avatar;
    this.url = namespaceData.url;

    if (namespace) {
      this.namespace = namespace;
    }
    else {
      if (namespaceData.namespace instanceof GitLabNamespace) {
        this.namespace = namespaceData.namespace;
      }
      else {
        this.namespace = namespaceData.namespace ? new GitLabNamespace(namespaceData.namespace) : null;
      }
    }

    if (namespaceData.projects) {
      this.projects = namespaceData.projects.map((projectData) => {
        if (projectData instanceof GitLabProject) return projectData;
        return new GitLabProject(projectData, this);
      });
    }
    if (namespaceData.namespaces) {
      this.namespaces = namespaceData.namespaces.map((namespaceData) => {
        if (namespaceData instanceof GitLabNamespace) return namespaceData;
        return new GitLabNamespace(namespaceData, this);
      });
    }
  }

  update(namespaceData: Partial<GitLabNamespaceData>, namespace?: GitLabNamespace): this {
    super.update(namespaceData);
    this.name = namespaceData.name ?? this.name;
    this.path = namespaceData.path ?? this.path;
    this.kind = namespaceData.kind ?? this.kind;
    this.full_path = namespaceData.full_path ?? this.full_path;
    this.avatar = namespaceData.avatar ?? this.avatar;
    this.url = namespaceData.url ?? this.url;

    if (namespaceData.namespace) {
      if (namespaceData.namespace instanceof GitLabNamespace) {
        this.namespace = namespaceData.namespace;
      }
      else {
        this.namespace = this.namespace ? this.namespace.update(namespaceData.namespace) : new GitLabNamespace(namespaceData.namespace);
      }
    }
    this.namespace = namespace ?? this.namespace;

    if (namespaceData.projects) {
      this.projects = namespaceData.projects.map((projectData) => {
        if (projectData instanceof GitLabProject) return projectData;
        const existingPipeline = this.projects.find((e) => e.id === projectData.id);
        return existingPipeline ? existingPipeline.update(projectData) : new GitLabProject(projectData);
      });
    }

    return this;
  }
}

// endregion

// region GitLabRunner
export type GitLabRunnerStatusKeys = 'active' | 'paused' | 'online' | 'offline';

export type GitLabRunnerData = {
  id: number
  name: string
  status: GitLabRunnerStatusKeys
  online: boolean
  is_shared: boolean
  active: boolean
  ip: string
  job: GitLabJobData | GitLabJob
}

export class GitLabRunner extends BaseModel {
  name: string;
  status: GitLabRunnerStatusKeys;
  online: boolean;
  is_shared: boolean;
  active: boolean;
  ip: string;
  job: GitLabJob | null;

  constructor(runnerData: GitLabRunnerData, job?: GitLabJob) {
    super(runnerData);
    this.name = runnerData.name;
    this.status = runnerData.status;
    this.online = runnerData.online;
    this.is_shared = runnerData.is_shared;
    this.active = runnerData.active;
    this.ip = runnerData.ip;

    if (job) {
      this.job = job;
    }
    else {
      if (runnerData.job instanceof GitLabJob) {
        this.job = runnerData.job;
      }
      else {
        this.job = runnerData.job ? new GitLabJob(runnerData.job) : null;
      }
    }
  }

  update(runnerData: Partial<GitLabRunnerData>, job?: GitLabJob): this {
    super.update(runnerData);
    this.name = runnerData.name ?? this.name;
    this.status = runnerData.status ?? this.status;
    this.online = runnerData.online ?? this.online;
    this.is_shared = runnerData.is_shared ?? this.is_shared;
    this.active = runnerData.active ?? this.active;
    this.ip = runnerData.ip ?? this.ip;

    if (runnerData.job) {
      if (runnerData.job instanceof GitLabJob) {
        this.job = runnerData.job;
      }
      else {
        this.job = this.job ? this.job.update(runnerData.job) : new GitLabJob(runnerData.job);
      }
    }
    this.job = job ?? this.job;

    return this;
  }
}

// endregion
