import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import 'moment-timezone';

import "./assets/scss/index.scss";
import baseRoute from "./routes";
import Layout from "./layouts";
import {UserProvider} from "./user";
import 'moment/locale/de';
import moment from "moment";


moment.updateLocale('de', {
    relativeTime: {
        s: (n: number) => {
            return `${n} sekunde${(n > 1) ? 'n' : ''}`;
        }
    }
});
moment.relativeTimeThreshold('s', 60);


ReactDOM.render(<React.StrictMode>
        <BrowserRouter>
            <UserProvider>
                <Layout route={baseRoute}/>
            </UserProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root"));
