import {User, UserPermissions, UserPermissionsKeys} from "./user";
import _ from "lodash";
import React from "react";
import {IconType} from "react-icons/lib";
import {FaQuestion} from "react-icons/fa";
import {IIconType} from "../components/Icons/type";

type TRouteComponent = React.FunctionComponent<{ route: RouteElement }>;
/** Raw Data for a Route */
export type RouteData = {
    // Path of the Route
    path: string
    // Component to Render on Route
    Component: TRouteComponent;
    // Name of Route for Navigation title
    name?: string
    // Description for the Route
    description?: string
    // Icon to show in Navigation
    icon?: React.FunctionComponent | IconType | IIconType
    // If Route should be listed in Navigation
    in_nav?: boolean
    // If Route should match exact
    exact?: boolean
    // Permission needed to view route
    permission?: UserPermissionsKeys | true
    // All Routes under the main Route
    subroutes?: RouteData[] | RouteElement[]
}

/** Model for a Route in the System */
export class RouteElement {
    // Path of the Route
    private __path__: string;
    // Component to Render on Route
    Component: TRouteComponent;
    // Name of Route for Navigation title
    name?: string;
    // Description for the Route
    description?: string;
    // Icon to show in Navigation
    __icon__: RouteData['icon'];
    // If Route should be listed in Navigation
    in_nav: boolean;
    // If Route should match exact
    exact: boolean;
    // If Route needs an authenticated user
    restricted: boolean = false;
    // Permission needed to view route
    private __permission__: UserPermissionsKeys | null = null;
    // All Routes under the main Route
    subroutes: RouteElement[] = [];
    // Parent Route
    private __parent__: RouteElement | null = null;

    constructor(routeData: RouteData, parent?: RouteElement) {
        if (parent) {
            this.__parent__ = parent;
        }
        routeData = _.cloneDeep(routeData);
        if (!routeData.path) {
            throw Error('no path defined');
        }
        this.__path__ = routeData.path;

        if (routeData.name) {
            this.name = routeData.name;
        }
        if (routeData.description) {
            this.description = routeData.description;
        }
        if (routeData.icon) {
            this.__icon__ = routeData.icon;
        }
        this.in_nav = routeData.in_nav ?? false;
        this.exact = routeData.exact ?? false;

        if (!routeData.Component) {
            throw Error('no component provided');
        }
        this.Component = routeData.Component;

        if (routeData.permission === true) {
            this.restricted = true;
        }
        else if (routeData.permission && UserPermissions.has(routeData.permission)) {
            this.__permission__ = routeData.permission;
        }

        if (routeData.subroutes) {
            this.subroutes = routeData.subroutes.map((subRoute) => {
                if (subRoute instanceof RouteElement) return subRoute;
                return new RouteElement(subRoute, this);
            });
        }
    }

    update(data: Partial<RouteData>, parent?: RouteElement): this {
        if (parent) {
            this.parent = parent;
        }

        this.__path__ = data.path ?? this.__path__;
        this.name = data.name ?? this.name;
        this.description = data.description ?? this.description;
        this.__icon__ = data.icon ?? this.__icon__;
        this.in_nav = data.in_nav ?? this.in_nav;
        this.exact = data.exact ?? this.exact;
        this.Component = data.Component ?? this.Component;

        if (data.permission) {
            if (typeof data.permission == "boolean") {
                this.restricted = data.permission;
                this.__permission__ = null;
            }
            else {
                if(!UserPermissions.has(data.permission)){
                    throw new Error(`Route permission must be a valid Permission. valid permissions: ${UserPermissions.keys()}`);
                }
                this.restricted = true;
                this.__permission__ = data.permission;
            }
        }

        if (data.subroutes) {
            this.subroutes = data.subroutes.map((subRoute) => {
                if (subRoute instanceof RouteElement) return subRoute;
                const existingRoute = this.subroutes.find((e) => e.__path__ === subRoute.path);
                return existingRoute ? existingRoute.update(subRoute, this) : new RouteElement(subRoute, this);
            });
        }

        return this;
    }

    toString = (): string => {
        //noinspection JSUnresolvedVariable
        return `&lt;${this.constructor.name} path="${this.path}"&gt;`;
    }

    /** Get path of Route */
    get path(): string {
        if (!_.isNull(this.parent)) {
            return this.parent.path + this.__path__;
        }
        return this.__path__;
    }

    /** Set new path for Route */
    set path(value: string) {
        this.__path__ = value;
    }

    get parent(): RouteElement | null {
        return this.__parent__;
    }

    get parentPath(): string {
        if(this.parent){
            return this.parent.path;
        }
        return '/';
    }

    set parent(parent: RouteElement | null) {
        this.__parent__ = parent;
    }

    get permission(): UserPermissionsKeys | null {
        if (this.__permission__) {
            return this.__permission__;
        }
        else if (this.parent) {
            return this.parent.permission;
        }
        return null;
    }

    get icon(): React.FunctionComponent | IconType {
        if (this.__icon__) return this.__icon__;
        return FaQuestion;
    }

    set icon(value: React.FunctionComponent | IconType) {
        this.__icon__ = value;
    }

    /** Check if provided User has permission to view Route */
    userHasPermission = (user: User | null): boolean => {
        if (this.permission === null) {
            return true;
        }
        if (!user) {
            return false;
        }

        return user.hasPermission(this.permission);
    }
}
