import React, {useEffect, useState} from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane
} from "reactstrap";
import Header from "../../layouts/Header";
import {Link, useHistory, useParams} from "react-router-dom";
import Moment from 'react-moment';
import {GitLabPipeline, GitLabProject, GitLabProjectData, GitLabRelease, RouteElement} from '../../types';
import _ from 'lodash';
import classnames from 'classnames';
import {BranchIcon, PipelineIcon, ReleaseIcon} from "../../components/Icons/gitlab";
import {FaChevronLeft, FaReadme} from "react-icons/all";
import {useUserContext} from "../../user";
import PipelineStatus from "./PipelineStatus";
import {SortTable, TableColumn} from "../../components/Table";
import PropTypes from "prop-types";
import Toc from "react-toc";
import Markdown from "../../components/Markdown";
import {REFRESH_INTERVAL} from "../../config";
import useUnmountSignal from "use-unmount-signal";
import {LastUpdate} from "../../components";


const ProjectReadMe: React.FunctionComponent<{ project: GitLabProject }> = (props) => {
  const {project} = props;
  const {request, addNotification} = useUserContext();
  const unmountSignal = useUnmountSignal();
  const [text, setText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (unmountSignal.aborted) return;
    request<string>(`/gitlab/projects/${project.id}/readme`, 'GET', undefined, unmountSignal)
        .then((b64_readme) => {
          let readme = new Buffer(b64_readme, 'base64').toString('utf-8');
          if (readme.includes('[[_TOC_]]')) {
            readme = readme.replace('[[_TOC_]]', '');
            readme = readme.replace('## Navigation', '');
          }
          setText(readme);
        })
        .catch((reason) => {
          setError(reason.toString());
        })
        .finally(() => {
          setIsLoading(false);
        })
  }, [request, project, addNotification, setText, unmountSignal]);

  if (isLoading) {
    return <div className="text-center"><Spinner size={"sm"}/></div>;
  }
  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  return <div className="project-readme">
    <nav className="toc">
      <Toc markdownText={text} highestHeadingLevel={2}/>
    </nav>
    <div className="text">
      <Markdown text={text}/>
    </div>
  </div>;
};
ProjectReadMe.propTypes = {
  project: PropTypes.instanceOf(GitLabProject).isRequired
};


const ProjectPipelineTabs: React.FunctionComponent<{ project: GitLabProject }> = (props) => {
  const {project} = props;
  const {pipelines} = project;
  type TTabs = 'all' | 'branches';
  const tabs: { id: TTabs, label: string }[] = [
    {
      id: 'all',
      label: 'Alle'
    },
    {
      id: 'branches',
      label: 'Branches'
    }
  ];

  const [activeTab, setActiveTab] = useState<TTabs>('all');

  const toggle = (tab: TTabs) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const pipelines_branch: GitLabPipeline[] = _.reduce(project.pipelines, (arr: GitLabPipeline[], pipeline: GitLabPipeline) => {
    if (!project.branches.includes(pipeline.ref)) {
      return arr;
    }
    const index = _.findIndex(arr, {ref: pipeline.ref});
    if (index >= 0) {
      const c_pipeline = arr[index];
      if (_.isNull(pipeline.updated_at)) {
        return arr;
      }
      if (_.isNull(c_pipeline.updated_at) || pipeline.updated_at > c_pipeline.updated_at) {
        arr[index] = pipeline;
      }
    }
    else {
      arr.push(pipeline);
    }
    return arr;
  }, []);

  const tableColumns: Record<string, TableColumn> = {
    status: {
      label: 'Status',
      render: (pipeline) => {
        if (!(pipeline instanceof GitLabPipeline)) throw new TypeError('pipeline not instance of GitLabPipeline');
        return <PipelineStatus branch={pipeline.ref} pipeline={pipeline}/>;
      }
    },
    id: {
      label: 'ID'
    },
    ref: {
      label: 'Branch'
    },
    sha: {
      label: 'SHA',
      className: 'd-none d-xl-table-cell'
    },
    updated_at: {
      label: 'Letzte Aktivität',
      render: (pipeline) => {
        if (!(pipeline instanceof GitLabPipeline)) throw new TypeError('pipeline not instance of GitLabPipeline');
        return <Moment
            fromNow
            date={pipeline.updated_at || undefined}
            withTitle
            titleFormat={"HH:mm:ss, D.MM.YYYY"}
        />
      }
    }
  };

  return <>
    <Nav tabs>
      {tabs.map((tab, key) => {
        return <NavItem key={key}>
          <NavLink className={classnames({active: activeTab === tab.id})} onClick={() => toggle(tab.id)}>
            {tab.label}
          </NavLink>
        </NavItem>;
      })}
    </Nav>
    <TabContent activeTab={activeTab}>
      <TabPane tabId={'all'}>
        <SortTable
            responsive
            striped
            columns={tableColumns}
            items={pipelines}
            defaultSortColumn={'id'}
            pagination
        />
      </TabPane>
      <TabPane tabId={'branches'}>
        <SortTable
            responsive
            striped
            columns={tableColumns}
            items={pipelines_branch}
            defaultSortColumn={'id'}
            pagination
        />
      </TabPane>
    </TabContent>
  </>
}
ProjectPipelineTabs.propTypes = {
  project: PropTypes.instanceOf(GitLabProject).isRequired
}


const ProjectBranches: React.FunctionComponent<{ project: GitLabProject }> = (props) => {
  const {project} = props;

  const branches = project.branches.sort((a, b) => {
    if (project.protected_branches.includes(a)) {
      if (project.protected_branches.includes(b)) {
        return 0;
      }
      else {
        return 1;
      }
    }
    else {
      if (project.protected_branches.includes(b)) {
        return -1;
      }
      else {
        return 0;
      }
    }
  }).reverse();

  return <ul>
    {branches.map((branch, key) => {
      const pipeline = project.getLatestPipeline(branch);
      return <li key={key}>
        <PipelineStatus branch={branch} pipeline={pipeline}/>{" "}
        <a style={{fontSize: '0.8rem', fontWeight: pipeline && pipeline.isProtected ? 'bold' : ''}}
           href={`${project.url}/-/tree/${branch}`}>{branch}</a>
      </li>;
    })}
  </ul>;
}
ProjectBranches.propTypes = {
  project: PropTypes.instanceOf(GitLabProject).isRequired
};


const ProjectReleaseEntry: React.FunctionComponent<{ release: GitLabRelease }> = (props) => {
  const {release} = props;
  const [isOpen, setIsOpen] = useState(false);

  return <>
    <Modal isOpen={isOpen}>
      <ModalHeader>
        Release: {release.name}
      </ModalHeader>
      <ModalBody>
        <dl className="table-grid">
          <dt>tag:</dt>
          <dd>{release.tag_name}</dd>
          <dt>Erstellt:</dt>
          <dd><Moment format="HH:mm, DD.MM.YYYY" date={release.created_at || undefined}/></dd>
          <dt>Veröffentlicht:</dt>
          <dd><Moment format="HH:mm, DD.MM.YYYY" date={release.released_at || undefined}/></dd>
        </dl>
        <Markdown text={release.description}/>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setIsOpen(false)}>Schließen</Button>
      </ModalFooter>
    </Modal>
    <Button block color={'link'} onClick={() => setIsOpen(true)}>
      {release.name}
    </Button>
  </>;
};
ProjectReleaseEntry.propTypes = {
  release: PropTypes.instanceOf(GitLabRelease).isRequired,
};


interface IProjectMajorReleaseEntry {
  index: number
  label: string
  releases: GitLabRelease[]
}

const ProjectMajorReleaseEntry: React.FunctionComponent<IProjectMajorReleaseEntry> = (props) => {
  const {label, releases, index} = props;
  const [isOpen, setIsOpen] = useState(index === 0);

  return <div className={classnames('major-release', {'open': isOpen})}>
    <h3 onClick={() => setIsOpen(o => !o)}>{label}</h3>
    <Collapse isOpen={isOpen}>
      {releases.map((release, key) => <ProjectReleaseEntry key={key} release={release}/>)}
    </Collapse>
  </div>;
};
ProjectMajorReleaseEntry.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  releases: PropTypes.arrayOf(PropTypes.instanceOf(GitLabRelease).isRequired).isRequired,
};

/** Detail view for a GitLab Project */
const ProjectDetails: React.FunctionComponent<{ route: RouteElement }> = (props) => {
  const {route} = props;
  const {request, addNotification} = useUserContext();
  const unmountSignal = useUnmountSignal();
  const [project, setProject] = useState<GitLabProject | null>(null);
  const params = useParams<{ namespace: string, subgroup?: string, name: string }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastUpdate, setLastUpdate] = useState<Date | null>(null);

  useEffect(() => {
    if(unmountSignal.aborted) return;

    let path = `${params.namespace}/${params.name}`;
    if (params.subgroup) {
      path = `${params.namespace}/${params.subgroup}/${params.name}`;
    }

    function intervalFunction(interval_duration?: number): NodeJS.Timer | undefined {
      setIsLoading(true);
      request<GitLabProjectData>(`/gitlab/projects/${path}`, 'GET', undefined, unmountSignal)
          .then((data) => {
            setIsLoading(false);
            setLastUpdate(new Date());
            setProject(_project => _project ? _project.update(data) : new GitLabProject(data));
          })
          .catch(reason => {
            if (!unmountSignal.aborted) addNotification('Fehler', reason.toString(), 'danger');
            history.push(route.parentPath);
          });
      if (interval_duration) {
        return setInterval(() => intervalFunction(), interval_duration);
      }
      return undefined;
    }

    const interval = intervalFunction(REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [params, addNotification, history, request, route.parentPath, unmountSignal]);

  if (!project) {
    return <>
      <Header/>
      <div className="mt-3" style={{textAlign: 'center'}}><Spinner/></div>
    </>;
  }

  return <>
    <Header/>
    <Container className="project-details-view mt--6" fluid>
      <Card className="details shadow">
        <CardHeader>
          <Link className={'back-link'} to={route.parentPath}><FaChevronLeft/></Link>
          <CardImg className={'logo'} src={project.avatar || undefined}/>
          <h1>
            <a className={"namespace"} href={project.namespace?.url}>{project.namespace?.name}</a>
            <a className={'name'} href={project.url}>{project.name}</a>
          </h1>
        </CardHeader>
        <CardBody>
          <div className="text-muted">{project.description}</div>
          <dl className="table-grid">
            <dt>Archiviert:</dt>
            <dd>{project.archived ? 'Ja' : 'Nein'}</dd>
          </dl>
        </CardBody>
      </Card>
      <Card className="branches shadow">
        <CardHeader>
          <h2><BranchIcon/> Branches</h2>
        </CardHeader>
        <CardBody>
          <ProjectBranches project={project}/>
        </CardBody>
      </Card>
      <Card className="pipelines shadow">
        <CardHeader>
          <h2><PipelineIcon/> Pipelines</h2>
        </CardHeader>
        <ProjectPipelineTabs project={project}/>
      </Card>
      <Card className="releases shadow">
        <CardHeader>
          <h2><ReleaseIcon/> Releases</h2>
        </CardHeader>
        <div className="list">
          {project.getGroupedReleases().map(
              (entry, key) => <ProjectMajorReleaseEntry key={key} index={key} {...entry} />)
          }
        </div>
      </Card>
      <Card className="readme shadow">
        <CardHeader><h2><FaReadme/> Readme</h2></CardHeader>
        <CardBody>
          <ProjectReadMe project={project}/>
        </CardBody>
      </Card>
      <LastUpdate date={lastUpdate} loading={isLoading}/>
    </Container>
  </>;
};
ProjectDetails.propTypes = {
  route: PropTypes.instanceOf(RouteElement).isRequired
}

export default ProjectDetails;
