import {
    LoginView,
    PloneDetails,
    PloneListing,
    ProfileView,
    ProjectDetails,
    ProjectListing,
    RouteView,
    RunnerListing,
    ServerDetails,
    ServerListing,
    UsersView
} from "./views";
import {BiServer, CgWebsite, FaGitlab, FaRunning, FaUserFriends, VscProject} from "react-icons/all";
import {RouteElement} from "./types";
import {useUserContext} from "./user";
import {useHistory} from "react-router-dom";
import React from "react";


const GitLabRoutes = new RouteElement({
    path: "/gitlab",
    name: "Gitlab",
    description: "Alle Module im Bezug zu Gitlab",
    icon: FaGitlab,
    Component: RouteView,
    in_nav: true,
    exact: true,
    permission: 'view_gitlab',
    subroutes: [
        {
            path: "/projects",
            name: "Projekte",
            description: "Liste aller Projekte",
            icon: VscProject,
            Component: ProjectListing,
            in_nav: true,
            exact: true,
            subroutes: [{
                path: "/:namespace/:subgroup?/:name",
                Component: ProjectDetails,
                in_nav: false,
                exact: false
            }]
        },
        {
            path: "/runners",
            name: "Runners",
            description: "Liste aller Runner",
            permission: 'view_gitlab_runner',
            icon: FaRunning,
            Component: RunnerListing,
            in_nav: true,
        }
    ]
});

const PloneRoutes = new RouteElement({
    path: "/plone",
    name: "Plones",
    description: "Liste aller Plones",
    icon: CgWebsite,
    Component: PloneListing,
    in_nav: true,
    permission: 'view_plone',
    exact: true,
    subroutes: [
        {
            path: "/:id",
            name: "Plone",
            Component: PloneDetails,
            in_nav: false
        },
    ]
});

const ServerRoutes = new RouteElement({
    path: '/server',
    name: 'Server',
    description: 'Liste aller Server',
    icon: BiServer,
    Component: ServerListing,
    in_nav: true,
    exact: true,
    permission: 'view_server',
    subroutes: [
        {
            path: '/:id',
            Component: ServerDetails,
            in_nav: false
        }
    ]
})

const LoginRoute = new RouteElement({
    path: "/login",
    Component: LoginView,
    in_nav: false,
    exact: true
});

const ProfileRoute = new RouteElement({
    path: "/profile",
    name: "Profil",
    Component: ProfileView,
    exact: false,
    in_nav: false,
    permission: true,
});

const UserRoute = new RouteElement({
    path: '/users',
    name: "Benutzer",
    description: "Liste aller Benutzer",
    Component: UsersView,
    icon: FaUserFriends,
    exact: false,
    in_nav: true,
    permission: 'view_user',
});

const baseRoute = new RouteElement({
    path: '/',
    Component: ({route}) => {
        const {currentUser} = useUserContext();
        const history = useHistory();
        if(!currentUser) history.push('/login');
        return <RouteView route={route} />;
    },
    exact: true,
    in_nav: false,
    subroutes: [
        GitLabRoutes,
        PloneRoutes,
        ServerRoutes,
        LoginRoute,
        ProfileRoute,
        UserRoute
    ]
});

export default baseRoute;
