import React, {FunctionComponent} from "react";

const Footer: FunctionComponent<React.HTMLAttributes<HTMLElement>> = (props: object): JSX.Element => {
  return <footer className="footer" {...props}>
    <div className="copyright text-center text-xl-left text-muted">
      © {new Date().getFullYear()}{" "}
      <a
        className="font-weight-bold ml-1"
        href="https://interaktiv.de"
        rel="noopener noreferrer"
        target="_blank"
      >
        Interaktiv
      </a>
    </div>
  </footer>;
};

export default Footer;
