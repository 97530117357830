import {FaSort, FaSortDown, FaSortUp} from "react-icons/fa";
import React from "react";
import PropTypes from "prop-types";

interface SortIconProps {
    currentColumn: string
    column: string
    dir: 'asc' | 'desc'
}

function SortIcon(props: SortIconProps) {
    const {currentColumn, column, dir, ...extra_props} = props;
    let Icon = FaSort;

    if (currentColumn === column) {
        Icon = (dir === 'asc') ? FaSortUp : FaSortDown;
    }

    return <Icon {...extra_props} />;
}

SortIcon.propTypes = {
    currentColumn: PropTypes.string.isRequired,
    column: PropTypes.string.isRequired,
    dir: PropTypes.oneOf(['asc', 'desc']).isRequired
};

export default SortIcon;
