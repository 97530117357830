import React, {FunctionComponent} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import {useUserContext} from "../user";
import {RouteElement} from "../types";
import classNames from "classnames";
import {safeCSSId} from "../helper";
import InteraktivLogo from "../assets/img/brand/interaktiv_logo.svg";
import PropTypes from "prop-types";


interface LayoutProps extends React.HTMLAttributes<HTMLElement> {
  route: RouteElement
}

/** Whole Layout of Webpage */
const Layout: FunctionComponent<LayoutProps> = (props: { route: RouteElement }): JSX.Element => {
  const {route} = props;
  const {currentUser, sessionValidated} = useUserContext();
  const {pathname} = useLocation();

  const className = classNames(pathname.split('/').map((part) => {
    if (!part) {
      return null;
    }
    return `route-${safeCSSId(part)}`;
  }));

  /** Generates a flat list of all Routes */
  const getRoutes = (routes: RouteElement[], add_key: string = ''): JSX.Element[] => {
    const routeList: JSX.Element[] = [];

    routes.forEach((route, key) => {
      const UKey = `${add_key ? add_key+'-' : ''}${key}`;
      const Component = route.Component;
      routeList.push(<Route
          exact={route.exact}
          path={route.path}
          children={() => (route.userHasPermission(currentUser)) ? <Component route={route}/> : <Redirect key={key} to={route.parentPath}/>}
          key={UKey}
      />);
      if (route.subroutes) {
        getRoutes(route.subroutes, UKey).forEach((route) => routeList.push(route));
      }
    });

    return routeList;
  };

  return <>
    <Sidebar routes={route.subroutes} logo={{
      innerLink: "/",
      imgSrc: InteraktivLogo,
      imgAlt: "Interaktiv Dashboard"
    }}/>
    <div className="main-content">
      <main className={className}>
        {sessionValidated ?
            <Switch>
              <Route exact path={route.path} children={<route.Component route={route} />} />
              {getRoutes(route.subroutes)}
            </Switch> : null}
      </main>
      <Footer/>
    </div>
  </>;
};
Layout.propTypes = {
  route: PropTypes.instanceOf(RouteElement).isRequired
}

export default Layout;
