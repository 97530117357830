import React, {useCallback, useEffect, useState} from "react";
import {Card, Container} from "reactstrap";
import Header from "../../layouts/Header";
import {Link, useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import {useUserContext} from "../../user";
import Spinner from "reactstrap/lib/Spinner";
import {Plone, PloneData, RouteElement} from "../../types";
import {SortTable, TableColumn} from "../../components/Table";
import {PloneLogo} from "../../components/Icons/plone";
import Moment from "react-moment";
import _ from "lodash";
import PropTypes from "prop-types";
import {LastUpdate, useQuery} from "../../components";
import {REFRESH_INTERVAL} from "../../config";


const PloneListing: React.FunctionComponent<{ route: RouteElement }> = (props): JSX.Element => {
    const {route} = props;
    const {request, addNotification} = useUserContext();
    const location = useLocation<{ pathname: string, domain: string }>();
    const history = useHistory();
    const {sort, dir} = useQuery<{ sort?: keyof Plone; dir?: TSortDir }>();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
    const [plones, setPlones] = useState<Plone[]>([]);
    const [sortColumn, setSortColumn] = useState<keyof Plone>('id');
    type TSortDir = 'asc' | 'desc';
    const [sortDir, setSortDir] = useState<TSortDir>('asc');

    useEffect(() => {
        const intervalFunction = (interval_duration?: number): NodeJS.Timer | undefined => {
            setIsLoading(true);
            request<PloneData[]>('/plone', 'GET')
                .then((plonesData) => {
                    setIsLoaded(true);
                    setIsLoading(false);
                    setLastUpdate(new Date());
                    setPlones(_plones => plonesData.map((ploneData) => {
                        const index = _.findIndex(_plones, {id: ploneData.id});
                        if (index >= 0) {
                            const plone = _plones[index];
                            plone.update(ploneData);
                            return plone;
                        }
                        return new Plone(ploneData);
                    }));

                })
                .catch((reason) => addNotification('Fehler', reason.toString(), 'danger'));

            if (interval_duration) {
                return setInterval(() => intervalFunction(), interval_duration);
            }
            return undefined;
        }
        const interval = intervalFunction(REFRESH_INTERVAL);
        return () => clearInterval(interval);
    }, [request, addNotification]);

    useEffect(() => {
        setSortColumn((sort) ? sort : 'id');
        setSortDir((dir === 'desc') ? 'desc' : 'asc');
    }, [sort, dir]);

    const openPloneDetailView = (plone: Record<string, any>) => {
        if (!(plone instanceof Plone)) throw new TypeError();
        history.push(`${route.path}/${plone.domain}`);
    }

    const onTableSort = useCallback((sortColumn: string, sortDir: TSortDir) => {
        const state = {sort: sortColumn, dir: sortDir};
        if (!_.isEqual(state, location.state)) {
            history.push({search: queryString.stringify(state)}, state);
        }
    }, [location.state, history]);

    // @ts-ignore: 2740
    const tableColumns: Record<Partial<keyof Plone>, TableColumn<Plone>> = {
        favicon: {
            label: 'Favicon',
            sortable: false,
            render: (plone) => {
                return <img className={'avatar-sm'} src={plone.favicon} alt=""/>;
            }
        },
        id: {
            label: 'ID',
        },
        domain: {
            label: 'Domain',
            render: (plone) => {
                return <a href={'https://' + plone.domain} target="_blank" rel="noopener noreferrer">{plone.domain}</a>;
            }
        },
        server: {
            label: 'Server',
            render: (plone) => {
                if (plone.server) {
                    return <Link onClick={(e) => e.stopPropagation()}
                                 to={plone.server.getURL()}>{plone.server.hostname}</Link>;
                }
                return '';
            }
        },
        update_warranty: {
            label: 'Update-Garantie',
            render: (plone) => {
                return plone.update_warranty ? 'Ja' : 'Nein';
            }
        },
        repository_name: {
            label: 'Repository',
        },
        repository_branch: {
            label: 'Branch'
        },
        plone_version: {
            label: 'Plone',
            render: (plone) => {
                return <><PloneLogo/> {plone.plone_version}</>;
            }
        },
        master_plone_template_version: {
            label: 'Master Plone'
        },
        tiles_version: {
            label: 'Tiles'
        },
        updated_at: {
            label: "Letztes Update",
            sortable: true,
            render: (plone) => {
                return <Moment withTitle titleFormat={"HH:mm, DD.MM.YYYY"} date={plone.updated_at} fromNow/>;
            }
        }
    };

    if (!isLoaded) {
        return <>
            <Header/>
            <div className="mt-3" style={{textAlign: 'center'}}><Spinner/></div>
        </>
    }

    return <>
        <Header/>
        <Container className="mt--6 listing-view plone-listing" fluid>
            <Card className="shadow">
                <SortTable
                    responsive={true}
                    striped
                    items={plones || []}
                    columns={tableColumns}
                    sortColumn={sortColumn}
                    sortDir={sortDir}
                    onEntryClick={openPloneDetailView}
                    onSort={onTableSort}
                />
            </Card>
            <LastUpdate date={lastUpdate} loading={isLoading}/>
        </Container>
    </>;
}
PloneListing.propTypes = {
    route: PropTypes.instanceOf(RouteElement).isRequired
}
export default PloneListing;

