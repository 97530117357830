import React, {useCallback, useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {RouteElement, Server, ServerData} from '../../types';
import {useUserContext} from '../../user';
import {FaCheck, FaChevronLeft, FaCog, FaTimes, SiNodedotjs, SiNpm, SiPython, SiYarn} from 'react-icons/all';
import Header from '../../layouts/Header';
import Select from "react-select";
import {
    Alert,
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    TabContent,
    TabPane,
} from 'reactstrap';
import PropTypes from "prop-types";
import {REFRESH_INTERVAL} from "../../config";
import Moment from "react-moment";
import {LastUpdate} from "../../components";
import classnames from "classnames";

interface IServerModal {
  isOpen: boolean
  onSubmit: (server: Server) => void
  onClose?: () => void
  server: Server
}

const ServerModal: React.FunctionComponent<IServerModal> = (props) => {
  const {isOpen, onSubmit, onClose, server} = props;
  const {request} = useUserContext();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  type possibleTabs = 'properties';
  const [activeTab, setActiveTab] = useState<possibleTabs>('properties');

  const toggleTab = (tab: possibleTabs) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onModalClose = () => {
    if (typeof onClose == "function") onClose();
  };

  const onFormSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setIsSubmitting(true);

    request<ServerData>(`/server/${server.id}`, 'PUT', data)
        .then((serverData) => onSubmit(server.update(serverData)))
        .catch(reason => setMessage(reason.toString()))
        .finally(() => {
          setIsSubmitting(false);
        })
  }, [onSubmit, request, server]);

  return <Modal isOpen={isOpen} size={"lg"} backdrop className={'server-modal'}>
    <Form onSubmit={onFormSubmit}>
      <ModalHeader>{`${server.hostname} bearbeiten`}</ModalHeader>
      <ModalBody>
        <Alert color="danger" isOpen={!!message} toggle={() => setMessage('')}>{message}</Alert>
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({active: activeTab === 'properties'})}
                     onClick={() => toggleTab('properties')}>
              Eigenschaften
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={'properties'}>
            <FormGroup>
              <Label for="deactivated-field">Deaktiviert</Label>
              <Input id="deactivated-field"
                     type="checkbox"
                     name="deactivated"
                     defaultChecked={server.deactivated}
              />
            </FormGroup>
            <FormGroup>
              <Label for="deactivated_reason-field">Deaktiviert</Label>
              <Input id="deactivated_reason-field"
                     type="text"
                     name="deactivated_reason"
                     defaultValue={server.deactivated_reason}
              />
            </FormGroup>
            <FormGroup>
              <Label for="hoster-field">Hoster</Label>
                <Select
                    defaultValue={server.selectedHosterOption}
                    options={server.hosterOptions}
                    name="hoster"
                    id="hoster-field"
                />
            </FormGroup>
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        {isSubmitting ?
            <Spinner size={'sm'}/>
            : <>
              <Button outline type="button" onClick={onModalClose}>Schließen</Button>
              <Button type="submit" color="primary">Speichern</Button>
            </>}
      </ModalFooter>
    </Form>
  </Modal>;
};
ServerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  server: PropTypes.instanceOf(Server).isRequired
};

const ServerDetails: React.FunctionComponent<{route: RouteElement}> = (props) => {
    const {route} = props;
    const {request, addNotification, currentUser} = useUserContext();
    const {id: server_id} = useParams<{id: string}>();
    const history = useHistory();
    const [server, setServer] = useState<Server | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        const intervalFunction = (interval_duration?: number): NodeJS.Timer | undefined => {
            request<ServerData>(`/server/${server_id}`, 'GET')
                .then((serverData) => {
                    setServer(server => server ? server.update(serverData) : new Server(serverData));
                    setIsLoading(false);
                    setLastUpdate(new Date());
                })
                .catch(reason => {
                    addNotification('Fehler', reason.toString(), 'danger');
                    history.push(route.parentPath);
                });

            if (interval_duration) {
                return setInterval(() => intervalFunction(), interval_duration);
            }
            return undefined;
        }
        const interval = intervalFunction(REFRESH_INTERVAL);
        return () => clearInterval(interval);
    }, [request, addNotification, server_id, history, route]);

    if (!server) {
        return <>
            <Header/>
            <div className="mt-3 text-center"><Spinner/></div>
        </>
    }

    /** Called when server was successfully edited */
    const editServer = (server: Server) => {
        setEdit(false);
        setServer(server);
    };

    return <>
        <Header/>
        <Container className="server-details-view mt--6" fluid>
            <Card className="shadow main-details md-4 mb-4">
                <CardHeader>
                    <Link className="back" to={route.parent?.path ?? '/server'}><FaChevronLeft fontSize='1.8rem'/></Link>
                    <h1>{server.hostname}</h1>
                    <ButtonGroup>
                        {currentUser && currentUser.hasPermission('edit_server') ?
                      <Button role="edit" color="primary" onClick={() => setEdit(true)}><FaCog/></Button> : null}
                    </ButtonGroup>
                </CardHeader>
                <CardBody className="grid">
                    <div className="server-properties">
                        <h2 className="text-muted text-center text-sm">Eigenschaften</h2>
                        <dl className="table-grid centered">
                            <dt>Hostname</dt>
                            <dd>{server.hostname}</dd>
                            <dt><abbr title="Fully Qualified Domain Name">FQDN</abbr></dt>
                            <dd>{server.fqdn}</dd>
                            <dt>CPU</dt>
                            <dd>{server.cpu_name} ({server.cpu_cores} Cores{server.cpu_frequency != null ? ` @ ${server.cpu_frequency} MHz` : ''})</dd>
                            <dt>RAM</dt>
                            <dd>{server.readable_memory}</dd>
                            <dt>Systemstart</dt>
                            <dd>
                                <Moment withTitle titleFormat={"llll"} date={server.start_time}/> Uhr
                            </dd>
                            <dt>Letztes Update</dt>
                            <dd>
                                {server.updated_at ? <Moment withTitle titleFormat={"HH:mm, DD.MM.YYYY"} date={server.updated_at} fromNow interval={1000}/> : 'Nie'}
                            </dd>
                            <dt>Deaktiviert</dt>
                            <dd>{server.deactivated ? <span><FaCheck/> {server.deactivated_reason}</span> : <FaTimes/>}</dd>
                            <dt>Hoster</dt>
                            <dd>{server.Hoster}</dd>
                        </dl>
                    </div>
                    <div className="server-versions">
                        <h2 className="text-muted text-center text-sm">Versionen</h2>
                        <dl className="table-grid centered">
                            <dt>Kernel</dt>
                            <dd><server.KernelIcon/> {server.kernel} {server.kernel_version}</dd>
                            <dt>Distribution</dt>
                            <dd><server.OSIcon/> {server.distribution} {server.distribution_version}</dd>
                            <dt>Python</dt>
                            <dd><SiPython/> {server.python2} / {server.python3}</dd>
                            {server.node ?
                                <>
                                    <dt>Node.js</dt>
                                    <dd><SiNodedotjs/> {server.node}</dd>
                                </> : ''
                            }
                            {server.npm ?
                                <>
                                    <dt><abbr title="Node Package Manager">NPM</abbr></dt>
                                    <dd><SiNpm/> {server.npm}</dd>
                                </> : ''
                            }
                            {server.yarn ?
                                <>
                                    <dt>Yarn</dt>
                                    <dd><SiYarn/> {server.yarn}</dd>
                                </> : ''
                            }
                        </dl>
                    </div>
                </CardBody>
            </Card>
            {server.plones.length ? <Card className="shadow plones">
                <CardHeader><h2>Plones <Badge>{server.plones.length}</Badge></h2></CardHeader>
                <CardBody>
                    <ul>
                        {server.plones?.map((plone) => <li key={plone.id} className={"plone"}>
                            <Link to={plone.getURL()} className="btn btn-info">
                                <img className={"icon"} width={32} height={32} src={plone.favicon ?? '/fallback.svg'} alt=""/>
                                <span className={"title"}>
                                    <span className={"name"}>{plone.name}</span>
                                    <small className={"domain"}>{plone.domain}</small>
                                </span>
                            </Link>
                        </li>)}
                    </ul>
                </CardBody>
            </Card>: null}
            <ServerModal server={server} isOpen={edit} onSubmit={editServer} onClose={() => setEdit(false)}/>
            <LastUpdate date={lastUpdate} loading={isLoading}/>
        </Container>
    </>;
}
ServerDetails.propTypes = {
    route: PropTypes.instanceOf(RouteElement).isRequired
}

export default ServerDetails;
