import {IIconType} from "./type";

export const StatusCanceled: IIconType = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fillRule="evenodd">
            <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" style={{fill: 'var(--svg-status-fg, #000)'}}/>
            <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" style={{fill: 'var(--svg-status-bg, #fff)'}}/>
            <path
                d="M5.2 3.8l4.9 4.9c.2.2.2.5 0 .7l-.7.7c-.2.2-.5.2-.7 0L3.8 5.2c-.2-.2-.2-.5 0-.7l.7-.7c.2-.2.5-.2.7 0"
                style={{fill: 'var(--svg-status-fg, #000)'}}/>
        </g>
    </svg>
)

export const StatusCreated: IIconType = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fillRule="evenodd">
            <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" style={{fill: 'var(--svg-status-fg, #000)'}}/>
            <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" style={{fill: 'var(--svg-status-bg, #fff)'}}/>
            <circle cx="7" cy="7" r="3.25" style={{fill: 'var(--svg-status-fg, #000)'}}/>
        </g>
    </svg>
)

export const StatusFailed: IIconType = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fillRule="evenodd">
            <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" style={{fill: 'var(--svg-status-fg, #000)'}}/>
            <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" style={{fill: 'var(--svg-status-bg, #fff)'}}/>
            <path
                d="M7 5.969L5.599 4.568a.29.29 0 0 0-.413.004l-.614.614a.294.294 0 0 0-.004.413L5.968 7l-1.4 1.401a.29.29 0 0 0 .004.413l.614.614c.113.114.3.117.413.004L7 8.032l1.401 1.4a.29.29 0 0 0 .413-.004l.614-.614a.294.294 0 0 0 .004-.413L8.032 7l1.4-1.401a.29.29 0 0 0-.004-.413l-.614-.614a.294.294 0 0 0-.413-.004L7 5.968z"
                style={{fill: 'var(--svg-status-fg, #000)'}}/>
        </g>
    </svg>
)

export const StatusManual: IIconType = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fillRule="evenodd">
            <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" style={{fill: 'var(--svg-status-fg, #000)'}}/>
            <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" style={{fill: 'var(--svg-status-bg, #fff)'}}/>
            <path
                d="M10.5 7.63V6.37l-.787-.13c-.044-.175-.132-.349-.263-.61l.481-.652-.918-.913-.657.478a2.346 2.346 0 0 0-.612-.26L7.656 3.5H6.388l-.132.783c-.219.043-.394.13-.612.26l-.657-.478-.918.913.437.652c-.131.218-.175.392-.262.61l-.744.086v1.261l.787.13c.044.218.132.392.263.61l-.438.651.92.913.655-.434c.175.086.394.173.613.26l.131.783h1.313l.131-.783c.219-.043.394-.13.613-.26l.656.478.918-.913-.48-.652c.13-.218.218-.435.262-.61l.656-.13zM7 8.283a1.285 1.285 0 0 1-1.313-1.305c0-.739.57-1.304 1.313-1.304.744 0 1.313.565 1.313 1.304 0 .74-.57 1.305-1.313 1.305z"
                style={{fill: 'var(--svg-status-fg, #000)'}}/>
        </g>
    </svg>
)

export const StatusPending: IIconType = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fillRule="evenodd">
            <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" style={{fill: 'var(--svg-status-fg, #000)'}}/>
            <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" style={{fill: 'var(--svg-status-bg, #fff)'}}/>
            <path
                d="M4.7 5.3c0-.2.1-.3.3-.3h.9c.2 0 .3.1.3.3v3.4c0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3V5.3m3 0c0-.2.1-.3.3-.3h.9c.2 0 .3.1.3.3v3.4c0 .2-.1.3-.3.3H8c-.2 0-.3-.1-.3-.3V5.3"
                style={{fill: 'var(--svg-status-fg, #000)'}}/>
        </g>
    </svg>
)

export const StatusPreparing: IIconType = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
        <g fillRule="evenodd">
            <g fillRule="nonzero">
                <path d="M0 7a7 7 0 1114 0A7 7 0 010 7z" style={{fill: 'var(--svg-status-fg, #000)'}}/>
                <path fill="#FFF" d="M13 7A6 6 0 101 7a6 6 0 0012 0z" style={{fill: 'var(--svg-status-bg, #fff)'}}/>
            </g>
            <circle cx="7" cy="7" r="1" style={{fill: 'var(--svg-status-fg, #000)'}}/>
            <circle cx="10" cy="7" r="1" style={{fill: 'var(--svg-status-fg, #000)'}}/>
            <circle cx="4" cy="7" r="1" style={{fill: 'var(--svg-status-fg, #000)'}}/>
        </g>
    </svg>
)

export const StatusRunning: IIconType = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fillRule="evenodd">
            <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" style={{fill: 'var(--svg-status-fg, #000)'}}/>
            <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" style={{fill: 'var(--svg-status-bg, #fff)'}}/>
            <path d="M7 3c2.2 0 4 1.8 4 4s-1.8 4-4 4c-1.3 0-2.5-.7-3.3-1.7L7 7V3"
                  style={{fill: 'var(--svg-status-fg, #000)'}}/>
        </g>
    </svg>
)

export const StatusScheduled: IIconType = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="7" cy="7" r="7" style={{fill: 'var(--svg-status-fg, #fff)'}}/>
        <circle fill="#FFF" style={{fill: 'var(--svg-status-bg, #fff)'}} cx="7" cy="7" r="6"/>
        <g transform="translate(2.75 2.75)" fillRule="nonzero">
            <path
                d="M4.165 7.81a3.644 3.644 0 1 1 0-7.29 3.644 3.644 0 0 1 0 7.29zm0-1.042a2.603 2.603 0 1 0 0-5.206 2.603 2.603 0 0 0 0 5.206z"
                style={{fill: 'var(--svg-status-fg, #000)'}}/>
            <rect x="3.644" y="2.083" width="1.041" height="2.603" rx=".488"
                  style={{fill: 'var(--svg-status-fg, #000)'}}/>
            <rect x="3.644" y="3.644" width="2.083" height="1.041" rx=".488"
                  style={{fill: 'var(--svg-status-fg, #000)'}}/>
        </g>
    </svg>
)

export const StatusSkipped: IIconType = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14z" style={{fill: 'var(--svg-status-fg, #000)'}}/>
        <path d="M7 13A6 6 0 1 0 7 1a6 6 0 0 0 0 12z" fill="#FFF" fillRule="nonzero"
              style={{fill: 'var(--svg-status-bg, #fff)'}}/>
        <path
            d="M6.415 7.04L4.579 5.203a.295.295 0 0 1 .004-.416l.349-.349a.29.29 0 0 1 .416-.004l2.214 2.214a.289.289 0 0 1 .019.021l.132.133c.11.11.108.291 0 .398L5.341 9.573a.282.282 0 0 1-.398 0l-.331-.331a.285.285 0 0 1 0-.399L6.415 7.04zm2.54 0L7.119 5.203a.295.295 0 0 1 .004-.416l.349-.349a.29.29 0 0 1 .416-.004l2.214 2.214a.289.289 0 0 1 .019.021l.132.133c.11.11.108.291 0 .398L7.881 9.573a.282.282 0 0 1-.398 0l-.331-.331a.285.285 0 0 1 0-.399L8.955 7.04z"
            style={{fill: 'var(--svg-status-fg, #000)'}}/>
    </svg>
)

export const StatusSuccess: IIconType = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fillRule="evenodd">
            <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" style={{fill: 'var(--svg-status-fg, #000)'}}/>
            <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" style={{fill: 'var(--svg-status-bg, #fff)'}}/>
            <path
                d="M6.278 7.697L5.045 6.464a.296.296 0 0 0-.42-.002l-.613.614a.298.298 0 0 0 .002.42l1.91 1.909a.5.5 0 0 0 .703.005l.265-.265L9.997 6.04a.291.291 0 0 0-.009-.408l-.614-.614a.29.29 0 0 0-.408-.009L6.278 7.697z"
                style={{fill: 'var(--svg-status-fg, #000)'}}/>
        </g>
    </svg>
)

export const StatusWaiting: IIconType = (props) => (
    <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M6 10a4 4 0 100-8 4 4 0 000 8zm0 2A6 6 0 106 0a6 6 0 000 12z"
              fill="#000" style={{fill: 'var(--svg-status-fg, #000)'}}/>
    </svg>
)

export const StatusWarning: IIconType = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fillRule="evenodd">
            <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" style={{fill: 'var(--svg-status-fg, #000)'}}/>
            <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" style={{fill: 'var(--svg-status-bg, #fff)'}}/>
            <path
                d="M6 3.5c0-.3.2-.5.5-.5h1c.3 0 .5.2.5.5v4c0 .3-.2.5-.5.5h-1c-.3 0-.5-.2-.5-.5v-4m0 6c0-.3.2-.5.5-.5h1c.3 0 .5.2.5.5v1c0 .3-.2.5-.5.5h-1c-.3 0-.5-.2-.5-.5v-1"
                style={{fill: 'var(--svg-status-fg, #000)'}}/>
        </g>
    </svg>
);

export const BranchIcon: IIconType = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}>
        <path style={{fill: 'var(--svg-fg, #000)'}} fillRule="evenodd"
              d="M6,3 C6,3.74028 5.5978,4.38663 5,4.73244 L5,7.99963 C5.83566,7.37194 6.87439,7 8,7 C9.42133,7 10.6118,6.01158 10.9215,4.6846 C10.3673,4.32903 10,3.70741 10,3 C10,1.89543 10.8954,1 12,1 C13.1046,1 14,1.89543 14,3 C14,3.76403 13.5716,4.42799 12.9419,4.76478 C12.5738,7.16318 10.5014,9 8,9 C6.57867,9 5.3882,9.98842 5.07847,11.3154 C5.63273,11.671 6,12.2926 6,13 C6,14.1046 5.10457,15 4,15 C2.89543,15 2,14.1046 2,13 C2,12.2597 2.4022,11.6134 3,11.2676 L3,4.73244 C2.4022,4.38663 2,3.74028 2,3 C2,1.89543 2.89543,1 4,1 C5.10457,1 6,1.89543 6,3 Z"/>
    </svg>
);

export const PipelineIcon: IIconType = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}>
        <path style={{fill: 'var(--svg-fg, #000)'}}
              fillRule="evenodd"
              d="M8,0 C12.4183,0 16,3.58172 16,8 C16,12.4183 12.4183,16 8,16 C3.58172,16 0,12.4183 0,8 C0,3.58172 3.58172,0 8,0 Z M8.99665,2.0824 C8.95475,2.59614 8.52454,3 8,3 C7.47546,3 7.04525,2.59614 7.00335,2.0824 C6.20919,2.21517 5.46786,2.50394 4.81194,2.91613 C4.93028,3.08041 5,3.28206 5,3.5 C5,4.05228 4.55228,4.5 4,4.5 C3.74005,4.5 3.50327,4.40081 3.32544,4.23822 C2.89297,4.77495 2.55104,5.3877 2.32287,6.05327 C2.71672,6.18755 3,6.56069 3,7 C3,7.55228 2.55228,8 2,8 C2,11.3137 4.68629,14 8,14 C11.3137,14 14,11.3137 14,8 C13.4477,8 13,7.55228 13,7 C13,6.56069 13.2833,6.18755 13.6771,6.05327 C13.449,5.3877 13.107,4.77495 12.6746,4.23822 C12.4967,4.40081 12.2599,4.5 12,4.5 C11.4477,4.5 11,4.05228 11,3.5 C11,3.28206 11.0697,3.08041 11.1881,2.91613 C10.5321,2.50394 9.79081,2.21517 8.99665,2.0824 Z M8,4 L9,5 L9,8.26756 C9.5978,8.61337 10,9.25972 10,10 C10,11.1046 9.10457,12 8,12 C6.89543,12 6,11.1046 6,10 C6,9.25972 6.4022,8.61337 7,8.26756 L7,5 L8,4 Z"/>
    </svg>
);

export const ReleaseIcon: IIconType = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}>
        <path style={{fill: 'var(--svg-fg, #000)'}}
              fillRule="evenodd"
              d="M15.5715,1.74491 L14.6732,5.11944 C14.2816,6.59062 13.3453,7.85791 12.0542,8.66455 L4.81869,13.1849 L2.90789,11.2741 L7.42828,4.03863 C8.23492,2.74748 9.50221,1.81125 10.9734,1.41962 L14.3479,0.521322 C14.8816,0.379251 15.4294,0.696729 15.5715,1.23043 C15.6164,1.39899 15.6164,1.57635 15.5715,1.74491 Z M13.1949,2.8979 L11.4879,3.35232 C10.5071,3.6134 9.66223,4.23756 9.12446,5.09832 L6.01104,10.0818 L10.9945,6.96837 C11.8553,6.4306 12.4794,5.58574 12.7405,4.60496 L13.1949,2.8979 Z M6,14 L11,11 L11,12 C11,14.2091 9.20914,16 7,16 L6,16 L6,14 Z M2,10 L0,10 L0,9 C0,6.79086 1.79086,5 4,5 L5,5 L2,10 Z"
        />
    </svg>
);
