import React, {FunctionComponent, ReactChildren} from "react";

interface HeaderProps extends React.HTMLAttributes<HTMLElement> {
    children?: ReactChildren
}

const Header: FunctionComponent<HeaderProps> = ({children}) => {
    return <header className="header bg-gradient-primary pb-8">{children}</header>;
};

export default Header;
