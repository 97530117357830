import {SiApple, SiCentos, SiDebian, SiFreebsd, SiLinux, SiOpenbsd, SiUbuntu, SiRedhat, SiLinuxmint, SiGentoo, SiFedora} from "react-icons/all";
import {IconType} from "react-icons/lib";

export const DistributionsIcons = new Map<string, IconType>([
    ['ubuntu', SiUbuntu],
    ['centos', SiCentos],
    ['debian', SiDebian],
    ['redhat', SiRedhat],
    ['linuxmint', SiLinuxmint],
    ['gentoo', SiGentoo],
    ['fedora', SiFedora]
]);

export const KernelIcons = new Map<string, IconType>([
    ['linux', SiLinux],
    ['darwin', SiApple],
    ['freebsd', SiFreebsd],
    ['openbsd', SiOpenbsd]
]);