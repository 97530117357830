import React from "react";
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader, CardText, Container} from "reactstrap";
import Header from "../layouts/Header";
import {Link} from "react-router-dom";
import 'moment/locale/de';
import {useUserContext} from "../user";
import {RouteElement} from "../types";


/** Component to List all provided RouteElements */
const RouteView: React.FunctionComponent<{ route: RouteElement, routes?: RouteElement[] }> = (props) => {
    const {currentUser} = useUserContext();
    const {route, routes} = props;
    const routeList = routes ? routes : route?.subroutes;

    return <>
        <Header/>
        <Container className="mt--6 route-view" fluid>
            {routeList ? routeList.map((route, key) => {
                if (!route.in_nav || !route.userHasPermission(currentUser)) {
                    return null;
                }

                const Icon = route.icon;
                return <Link style={{display: 'block'}} to={route.path} key={key}>
                    <Card className={'shadow'}>
                        <CardHeader>
                            <div style={{textAlign: 'center'}}>
                                <Icon style={{fontSize: '5rem'}}/>
                            </div>
                            <h2>{route.name}</h2>
                        </CardHeader>
                        <CardBody>
                            <CardText>{route.description}</CardText>
                        </CardBody>
                    </Card>
                </Link>
            }) : null}
        </Container>
    </>;
};
RouteView.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.instanceOf(RouteElement).isRequired),
    route: PropTypes.instanceOf(RouteElement).isRequired
};

export default RouteView;