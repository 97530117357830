import Header from "../../layouts/Header";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useUserContext} from "../../user";
import classnames from 'classnames';
import AvatarEditor from "react-avatar-editor";
import {useDropzone} from "react-dropzone";
import {PasswordField} from "../../components/Forms";
import PropTypes from "prop-types";
import {FaEdit, FaTimes, FaTrash, FaUpload} from "react-icons/all";
import {dataURLtoFile} from "../../helper";
import CardBody from "reactstrap/lib/CardBody";
import {UserData} from "../../types";

interface IProfileAvatarEditor {
  onImageChange: (avatar: string | null) => void
  avatar: string | null
}

export const ProfileAvatarEditor: React.FunctionComponent<IProfileAvatarEditor> = (props) => {
  const {onImageChange, avatar} = props;
  const wrapRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<AvatarEditor>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [scale, setScale] = useState<number>(1);
  const onDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles[0]);
    setImageLoaded(true);
    setScale(1);
  }, []);
  const {getRootProps, getInputProps} = useDropzone({maxFiles: 1, onDropAccepted: onDrop});

  const deleteAvatar = () => {
    setImage(null);
    onImageChange(null);
    setImageLoaded(false);
    setScale(1);
  };

  const onAvatarWheel = useCallback((event) => {
    event.preventDefault();
    if (event.deltaY < 0) {
      setScale(s => {
        return s + .1;
      });
    }
    else if (event.deltaY > 0) {
      setScale(s => {
        return s - .1 < 1 ? 1 : s - .1;
      });
    }
  }, []);

  /** Get scaled Image as Base64 string */
  const getImage = (): string | null => {
    const canvas = canvasRef.current;
    if (canvas) {
      return canvas.getImageScaledToCanvas().toDataURL();
    }
    return null;
  };

  const onAvatarUpdate = useCallback(() => {
    if (!imageLoaded) {
      return;
    }
    onImageChange(getImage());
  }, [imageLoaded, onImageChange]);

  useEffect(() => {
    onImageChange(getImage());
  }, [onImageChange]);

  useEffect(() => {
    onImageChange(getImage());
  }, [scale, onAvatarUpdate, onImageChange]);

  useEffect(() => {
    const reference = wrapRef.current;
    if (!reference) {
      return;
    }
    reference.addEventListener('wheel', onAvatarWheel, true);
    return () => {
      reference.removeEventListener('wheel', onAvatarWheel, true);
    }
  }, [wrapRef, onAvatarWheel]);

  useEffect(() => {
    if (avatar) {
      setImage(avatar !== 'null' ? dataURLtoFile(avatar, 'avatar') : null);
      setScale(1);
      setImageLoaded(true);
    }
  }, [avatar]);

  let {onClick: uploadAvatar, ...rootProps} = getRootProps();

  return <div {...rootProps} ref={wrapRef} style={{display: 'inline-flex', flexDirection: 'column'}}>
    <AvatarEditor
        style={{background: 'repeating-conic-gradient(rgb(128, 128, 128) 0%, rgb(128, 128, 128) 25%, transparent 0%, transparent 50%) 50% center / 15px 15px'}}
        image={image || ''}
        ref={canvasRef}
        width={200}
        height={200}
        scale={scale}
        borderRadius={100}
        onImageChange={onAvatarUpdate}
    />
    <ButtonGroup style={{alignSelf: 'center', marginTop: 5}}>
      <Button outline color="primary" onClick={uploadAvatar}><FaUpload/></Button>
      <Button outline color="danger" onClick={deleteAvatar}><FaTrash/></Button>
    </ButtonGroup>
    <input {...getInputProps()} />
  </div>;
};
ProfileAvatarEditor.propTypes = {
  avatar: PropTypes.string,
  onImageChange: PropTypes.func.isRequired
};

const ProfileView: React.FunctionComponent = () => {
  const {currentUser, update: updateUser, request, addNotification, getAvatar} = useUserContext();
  const [avatar, setAvatar] = useState<string | null>(currentUser ? currentUser.avatar : null);
  const [editAvatar, setEditAvatar] = useState<boolean>(false);
  let history = useHistory();
  let match = useRouteMatch<{ activeTab: possibleTabs }>('/profile/:activeTab');

  type possibleTabs = 'overview' | 'credentials' | 'integrations';

  const [activeTab, setActiveTab] = useState<possibleTabs>('overview');

  useEffect(() => {
    if (!match) {
      setActiveTab('overview');
      return;
    }

    if (match.params.activeTab !== activeTab) {
      setActiveTab(match.params.activeTab);
    }
  }, [match, activeTab]);

  const toggle = (tab: possibleTabs) => {
    if (activeTab !== tab) {
      history.push(`/profile/${tab}`);
    }
  };

  const submitOverview = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    // noinspection JSIncompatibleTypesComparison
    if (editAvatar && currentUser && currentUser.avatar !== avatar) {
      form.append('avatar', avatar || 'null');
    }
    setEditAvatar(false);
    updateUser(form as Partial<UserData>)
        .then((status) => {
          if (status === true) {
            addNotification('Gespeichert', '', 'success');
            if (currentUser) setAvatar(currentUser.avatar);
            return;
          }
          addNotification('Error', status, 'danger');
        })
        .catch(reason => addNotification('Error', reason.toString(), 'danger'));
  };

  const submitCredentials = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    request(`/user/update_credentials`, 'PUT', form)
        .then(() => {
          addNotification('Erfolgreich', 'Das Passwort wurde erfolgreich geändert', 'success');
          event.currentTarget.reset();
        })
        .catch(reason => addNotification('Error', reason, 'danger'));
  };

  const submitIntegration = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    updateUser(form as Partial<UserData>)
        .then((status) => {
          if (typeof status === "string") {
            addNotification('Error', status, 'danger');
          }
        })
        .catch(reason => addNotification('Error', reason.toString(), 'danger'));
  };

  return <>
    <Header/>
    <Container fluid className="mt--6">
      <Card>
        <CardHeader style={{paddingBottom: 0}}>
          <Nav tabs>
            <NavItem>
              <NavLink className={classnames({active: activeTab === 'overview'})} onClick={() => toggle('overview')}>
                Übersicht
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({active: activeTab === 'credentials'})}
                       onClick={() => toggle('credentials')}>
                Anmeldedaten
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({active: activeTab === 'integrations'})}
                       onClick={() => toggle('integrations')}>
                Integrationen
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={'overview'}>
              <Form onSubmit={submitOverview}>
                <FormGroup>
                  <Label for="id-field">ID</Label>
                  <Input id="id-field" type="number" name="id" readOnly={true}
                         defaultValue={currentUser ? currentUser.id : undefined}/>
                </FormGroup>
                <FormGroup>
                  <Label for="name-field">Name</Label>
                  <Input id="name-field" type="text" name="name" autoComplete="name"
                         defaultValue={currentUser ? currentUser.name : undefined}/>
                </FormGroup>
                <FormGroup>
                  <Label for="avatar-field">Avatar</Label>
                  <div className={'avatar-editor'}>
                    {editAvatar ?
                        <ProfileAvatarEditor avatar={currentUser ? currentUser.avatar : null}
                                             onImageChange={setAvatar}/> :
                        getAvatar(currentUser || '', {'className': 'avatar'})
                    }
                    <Button color={editAvatar ? 'danger' : 'primary'} onClick={() => setEditAvatar(!editAvatar)}>
                      {editAvatar ? <FaTimes/> : <FaEdit/>}
                    </Button>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button type="submit">Speichern</Button>
                </FormGroup>
              </Form>
            </TabPane>
            <TabPane tabId={'credentials'}>
              <Form onSubmit={submitCredentials}>
                <input type="hidden" name={"email"} autoComplete="email" value={currentUser ? currentUser.email : ''}/>
                <FormGroup>
                  <Label for="email-field">Email</Label>
                  <FormText>Derzeitige Email: {currentUser ? currentUser.email : ''}</FormText>
                  <Input id="email-field" type="email" name="email"/>
                </FormGroup>
                <FormGroup>
                  <Label for="new_password-field">Neues Password</Label>
                  <PasswordField id="new_password-field" name="new_password" autoComplete="new-password"/>
                </FormGroup>
                <FormGroup>
                  <Label for="old_password-field" className={'required'}>Altes Password</Label>
                  <PasswordField id="old_password-field" name="old_password" autoComplete="current-password" required/>
                </FormGroup>
                <FormGroup>
                  <Button type="submit">Speichern</Button>
                </FormGroup>
              </Form>
            </TabPane>
            <TabPane tabId={'integrations'}>
              <Form onSubmit={submitIntegration}>
                <FormGroup>
                  <Label for="slack-field">Slack</Label>
                  <Input id="slack-field"
                         type="text"
                         name="slack"
                         autoComplete="off"
                         defaultValue={currentUser ? currentUser.slack : undefined}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="gitlab-field">Gitlab</Label>
                  <Input id="gitlab-field"
                         type="text"
                         name="gitlab"
                         autoComplete="off"
                         defaultValue={currentUser ? currentUser.gitlab : undefined}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="sentry-field">Sentry</Label>
                  <Input id="sentry-field"
                         type="text"
                         name="sentry"
                         autoComplete="off"
                         defaultValue={currentUser ? currentUser.sentry : undefined}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="redmine-field">Redmine</Label>
                  <Input id="redmine-field"
                         type="text"
                         name="redmine"
                         autoComplete="off"
                         defaultValue={currentUser ? currentUser.redmine : undefined}
                  />
                </FormGroup>
                <FormGroup>
                  <Button type="submit">Speichern</Button>
                </FormGroup>
              </Form>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Container>
  </>
};

export default ProfileView;