import React, {InputHTMLAttributes, useState} from "react";
import {Button, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import classNames from "classnames";

interface PasswordFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    invalid?: boolean
}

const PasswordField = React.forwardRef<HTMLInputElement, PasswordFieldProps>((props, ref) => {
    const [fieldType, setFieldType] = useState<'password' | 'text'>('password');

    const toggle = () => {
        setFieldType(fieldType === 'password' ? 'text' : 'password');
    };

    return <InputGroup className={classNames({'is-invalid': props.invalid})}>
        <Input style={{paddingLeft: 5}} {...props} type={fieldType} innerRef={ref}/>
        <InputGroupAddon addonType={'append'}>
            <Button outline color="primary" onClick={toggle}>
              {fieldType === 'password' ? <FaEyeSlash/> : <FaEye/>}
            </Button>
        </InputGroupAddon>
    </InputGroup>;
});

export default PasswordField;