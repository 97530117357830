import React, {FunctionComponent, useState} from "react";
import {Link, NavLink as NavLinkRRD, useHistory} from "react-router-dom";
import {
    Col,
    Collapse,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
    Row
} from "reactstrap";
import {useUserContext} from "../user";
import {FaChevronDown, FaChevronUp, FaUser, FiLogIn, FiLogOut} from "react-icons/all";
import {useBreakpoint, SIZE_SM, SIZE_XS} from "../components/hooks";
import {RouteElement} from "../types";


/**
 * User profile shown in Navigation
 * @return {JSX.Element}
 * @constructor
 */
const UserProfile = () => {
    const history = useHistory();
    const {currentUser, logout, getAvatar} = useUserContext();
    const [isOpen, setIsOpen] = useState(false);
    const size = useBreakpoint();
    const mobile = [SIZE_XS, SIZE_SM].includes(size);

    if (!currentUser) {
        return <div id={'profile-overview'}>
            <Link to={'/login'}>Login <FiLogIn/></Link>
        </div>;
    }

    const avatarProps = {className: 'avatar'};

    return <div id={'profile-overview'}>
        <Dropdown
            isOpen={isOpen} toggle={() => setIsOpen(isOpen => !isOpen)}
            direction={mobile ? 'down' : 'up'}
        >
            <DropdownToggle nav>
                {getAvatar(currentUser, avatarProps)}
                {mobile ? null : <strong className={'name'}>{currentUser.name}</strong>}
                {isOpen ? <FaChevronDown className={'dropdown-icon'}/> : <FaChevronUp className={'dropdown-icon'}/>}
            </DropdownToggle>
            <DropdownMenu right={mobile}>
                {mobile ?
                    <DropdownItem header><strong className={'name'}>{currentUser.name}</strong></DropdownItem> : null}
                <DropdownItem onClick={() => history.push('/profile/overview')}>
                    <FaUser/> Profile
                </DropdownItem>
                <DropdownItem divider/>
                <DropdownItem onClick={logout}>
                    <FiLogOut/> Logout
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    </div>;
};


interface SidebarProps extends React.HTMLAttributes<HTMLElement> {
    routes: RouteElement[],
    logo: {
        innerLink?: string,
        outterLink?: string,
        imgSrc: string,
        imgAlt: string
    }
}


/** Sidebar of the Layout */
const Sidebar: FunctionComponent<SidebarProps> = (props): JSX.Element => {
    const {routes, logo} = props;
    const {currentUser} = useUserContext();
    const [isOpen, setOpen] = useState(false);

    /** creates the links that appear in the left menu / Sidebar */
    const createLinks = (routes: RouteElement[]): JSX.Element[] => {
        return routes.reduce((arr: JSX.Element[], route: RouteElement, key: number) => {
            if (!route.in_nav || !route.userHasPermission(currentUser)) {
                return arr;
            }
            const Icon = route.icon;
            arr.push(
                <NavItem key={key}>
                    <NavLink to={route.path} tag={NavLinkRRD} onClick={() => setOpen(false)} activeClassName="active">
                        {Icon ? <Icon className={'icon'}/> : null}
                        <span className={'title'}>{route.name}</span>
                    </NavLink>
                    {(route.subroutes) ? <Nav navbar vertical>{createLinks(route.subroutes)}</Nav> : null}
                </NavItem>
            );
            return arr;
        }, []);
    };

    let navbarBrandProps;
    if (logo && logo.innerLink) {
        navbarBrandProps = {
            to: logo.innerLink,
            tag: Link
        };
    }
    else if (logo && logo.outterLink) {
        navbarBrandProps = {
            href: logo.outterLink,
            target: "_blank"
        };
    }
    return <Navbar className="navbar-vertical fixed-left navbar-light bg-white" expand="md" id="sidenav-main">
        <Container fluid>
            <button className="navbar-toggler" type="button" onClick={() => setOpen(i => !i)}>
                <span className="navbar-toggler-icon"/>
            </button>
            {logo ? <NavbarBrand className="pt-0" {...navbarBrandProps}>
                <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc}/>
            </NavbarBrand> : null}
            <Collapse navbar isOpen={isOpen}>
                <div className="navbar-collapse-header d-md-none">
                    <Row>
                        {logo ? <Col className="collapse-brand" xs="6">
                            {logo.innerLink ?
                                <Link to={logo.innerLink}><img alt={logo.imgAlt} src={logo.imgSrc}/></Link> :
                                <a href={logo.outterLink}>
                                    <img alt={logo.imgAlt} src={logo.imgSrc}/>
                                </a>
                            }
                        </Col> : null}
                        <Col className="collapse-close" xs="6">
                            <button className="navbar-toggler" type="button" onClick={() => setOpen(i => !i)}>
                                <span/>
                                <span/>
                            </button>
                        </Col>
                    </Row>
                </div>
                <Nav navbar vertical>{createLinks(routes)}</Nav>
            </Collapse>
            <UserProfile/>
        </Container>
    </Navbar>;
};

export default Sidebar;
