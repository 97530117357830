import {BaseModel} from "./base";
import {ImCheckmark, ImInfo, ImNotification, ImWarning} from "react-icons/all";
import {IconType} from "react-icons/lib";

export type UserNotificationCallback = (notification: UserNotification) => void;
export type UserNotificationIcon = 'warning' | 'success' | 'danger' | 'info';
export type UserNotificationData = {
    title: string
    message?: string
    duration?: number | true
    icon: UserNotificationIcon
    onClose?: UserNotificationCallback | UserNotificationCallback[]
}

/** Notification for the logged-in User */
export class UserNotification {
    title: string;
    message: string;
    created: Date;
    duration: number | null;
    icon: IconType;
    closeCallbacks: UserNotificationCallback[] = [];
    _closeMethod: { (): void } | null = null;

    constructor(props: UserNotificationData) {
        if (!props.title) {
            throw Error('No title provided');
        }
        this.title = props.title;
        this.message = props.message ?? '';
        this.duration = props.duration ? typeof props.duration === 'number' ? props.duration : null : 5000;
        this.created = new Date();

        if (props.onClose) {
            this.closeCallbacks = props.onClose instanceof Array ? props.onClose : [props.onClose];
        }

        switch (props.icon) {
            case "success":
                this.icon = ImCheckmark;
                break;
            case "danger":
                this.icon = ImNotification;
                break;
            case "warning":
                this.icon = ImWarning;
                break;
            case "info":
            default:
                this.icon = ImInfo;
                break;
        }
    }

    /** Closes notification */
    close = () => {
        this.closeCallbacks.forEach((callback) => callback(this));
        if (this._closeMethod) this._closeMethod();
    };
}


export type UserPermissionsKeys =
    'view_user'
    | 'add_user'
    | 'remove_user'
    | 'update_user'
    | 'view_gitlab'
    | 'view_plone'
    | 'edit_plone'
    | 'view_server'
    | 'edit_server'
    | 'view_gitlab_runner';

export const UserPermissions: Readonly<Map<UserPermissionsKeys, { label: string, description?: string }>> = new Map([
    ['view_user', {
        label: 'Benutzer Auflistung',
        description: 'Berechtigung liste aller Benutzer zu sehen',
    }],
    ['add_user', {
        label: 'Benutzer hinzufügen',
        description: 'Berechtigung einen neuen Benutzer anzulegen',
    }],
    ['remove_user', {
        label: 'Benutzer entfernen',
        description: 'Berechtigung einen Benutzer zu löschen',
    }],
    ['update_user', {
        label: 'Benutzer verändern',
        description: 'Berechtigung einen Benutzer zu bearbeiten',
    }],
    ['view_gitlab', {
        label: 'Gitlab Projekte',
        description: 'Berechtigung Gitlab Projekte zu sehen',
    }],
    ['view_gitlab_runner', {
        label: 'Gitlab Runner',
        description: 'Berechtigung Gitlab Runner zu sehen',
    }],
    ['view_plone', {
        label: 'Plone',
        description: 'Berechtigung Plone-Installationen zu sehen',
    }],
    ['edit_plone', {
        label: 'Plone bearbeiten',
        description: 'Berechtigung Plone-Installationen zu bearbeiten',
    }],
    ['view_server', {
        label: 'Server',
        description: 'Berechtigung Server zu sehen',
    }],
    ['edit_server', {
        label: 'Server bearbeiten',
        description: 'Berechtigung Server-Settings zu bearbeiten',
    }],
]);

export type TUserPermissions = {
    [key in UserPermissionsKeys]: boolean | number[]
};

export type UserData = {
    // ID of User in System
    id: number
    // Visible name of User
    name: string
    // If user is deactivated
    deactivated?: boolean
    // Reason for deactivation
    deactivated_reason?: string
    // URL to Avatar or Base64 Avatar
    avatar: string
    // Email of User
    email: string
    // Slack username
    slack?: string | null
    // Gitlab username
    gitlab?: string | null
    // Sentry username
    sentry?: string | null
    // Redmine username
    redmine?: string | null
    // Date of registration
    registered: string
    // Date from last activity of user
    last_seen?: string
    // Session Hash for authorized calls to API
    session?: string
    // Object(str, bool) of all Permissions of User
    permissions?: TUserPermissions
}

/** User class to represent User in System */
export class User extends BaseModel {
    name: string;
    deactivated: boolean;
    deactivated_reason: string;
    // Base64|URL
    avatar: string | null;
    email: string;
    slack: string;
    gitlab: string;
    sentry: string;
    redmine: string;
    registered: Date;
    last_seen: Date | null;
    session?: string;
    permissions?: TUserPermissions;

    constructor(userData: UserData) {
        super(userData);
        this.name = userData.name;
        this.deactivated = userData.deactivated ?? false;
        this.deactivated_reason = userData.deactivated_reason ?? '';
        this.avatar = userData.avatar;
        this.email = userData.email;
        this.slack = userData.slack ?? '';
        this.gitlab = userData.gitlab ?? '';
        this.sentry = userData.sentry ?? '';
        this.redmine = userData.redmine ?? '';
        this.registered = this.convertDate(userData.registered) ?? new Date(0);
        this.last_seen = this.convertDate(userData.last_seen);
        this.session = userData.session;

        if (typeof userData.permissions != "undefined") {
            this.permissions = Array.from(UserPermissions).reduce((obj: TUserPermissions, [key, value]) => {
                let newPermission: boolean|number[] = false;

                if(userData.permissions && key in userData.permissions){
                    newPermission = userData.permissions[key];
                }

                obj[key] = newPermission;
                return obj;
            }, {} as TUserPermissions);
        }
    }

    update(data: Partial<UserData>): this {
        super.update(data);
        this.name = data.name ?? this.name;
        this.deactivated = data.deactivated ?? this.deactivated;
        this.deactivated_reason = data.deactivated_reason ?? this.deactivated_reason;
        this.avatar = data.avatar ?? this.avatar;
        this.email = data.email ?? this.email;
        this.slack = data.slack ?? this.slack;
        this.gitlab = data.gitlab ?? this.gitlab;
        this.sentry = data.sentry ?? this.sentry;
        this.redmine = data.redmine ?? this.redmine;
        this.registered = this.convertDate(data.registered) ?? this.registered;
        this.last_seen = this.convertDate(data.last_seen) ?? this.last_seen;
        this.session = data.session ?? this.session;
        this.permissions = data.permissions ?? this.permissions;
        return this;
    }

    /** Check to see if User has permission */
    hasPermission = (permission: UserPermissionsKeys): boolean => {
        if (this.permissions && this.permissions.hasOwnProperty(permission)) {
            return !!this.permissions[permission];
        }
        return false;
    }
}
