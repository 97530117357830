import PropTypes from "prop-types";
import {Table, TableProps} from "reactstrap";
import classNames from "classnames";
import React, {useEffect, useState} from "react";
import SortIcon from "./SortIcon";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import {FaChevronLeft, FaChevronRight} from "react-icons/all";
import Select from "react-select";
import {SIZE_LG, SIZE_XL, SIZE_XXL, useBreakpoint} from "../hooks/";


type TableEntry = Record<string, any>;
export type TSortDir = 'asc' | 'desc';
export type TableColumn<Item extends TableEntry = TableEntry> = {
  label: string
  // Gets called with row entry to render
  render?: ((entry: Item) => JSX.Element | string | null)
  sortable?: boolean
  className?: string
  sortCmp?: { (a: Item, b: Item): number }
};

interface SortTableProps<Item extends TableEntry = TableEntry> extends TableProps {
  columns: Record<Partial<keyof Item>, TableColumn<Item>>
  items: Item[] | null
  // needs to be a key of columns
  sortColumn?: keyof Item
  // needs to be a key of columns
  defaultSortColumn?: keyof Item
  sortDir?: TSortDir
  // Function gets called with entry as param on click
  onEntryClick?: { (entry: Item): void }
  onSort?: { (sortColumn: keyof Item, sortDir: TSortDir): void }
  emptyMessage?: string
  pagination?: boolean
}

/** Component to Sort Table entries */
export const SortTable = <Item extends TableEntry>(props: SortTableProps<Item>) => {
  const {
    columns,
    items,
    sortColumn: parentSortColumn,
    defaultSortColumn,
    sortDir: parentSortDir,
    onEntryClick,
    onSort,
    emptyMessage,
    pagination,
    ...extra_props
  } = props;
  const SizeOptions = [
    {
      value: 10,
      label: 10
    },
    {
      value: 20,
      label: 20
    },
    {
      value: 50,
      label: 50
    },
    {
      value: 100,
      label: 100
    }
  ];
  const [sortDir, setSortDir] = useState<TSortDir>(parentSortDir ? parentSortDir : 'desc');
  const [sortColumn, setSortColumn] = useState(props.sortColumn ?? defaultSortColumn ?? Object.keys(columns)[0]);
  const [pageSize, setPageSize] = useState<number>(pagination ? SizeOptions[0].value : items instanceof Array ? items.length : 1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);
  const [marginPagesDisplayed, setMarginPagesDisplayed] = useState(0);
  const [activePage, setActivePage] = useState<number>(0);
  const size = useBreakpoint();

  useEffect(() => {
    if (parentSortColumn) {
      setSortColumn(parentSortColumn);
    }
    if (parentSortDir) {
      setSortDir(parentSortDir);
    }
  }, [parentSortColumn, parentSortDir]);

  useEffect(() => {
    if (typeof onSort == 'function') onSort(sortColumn, sortDir);
  }, [sortDir, sortColumn, onSort]);

  useEffect(() => {
    if (pagination === false) {
      setPageSize(items ? items.length : 1);
    }
  }, [pagination, items]);

  const changePage = (page: number) => {
    if (page !== activePage) {
      setActivePage(page);
    }
  };

  const sortData = (entries: Item[]) => {
    const sortCmp = columns[sortColumn]?.sortCmp;
    entries.sort(sortCmp ? sortCmp : (a, b) => {
      let a_column = a[sortColumn];
      if (typeof a_column == 'function') {
        if (a_column.length > 0) {
          throw Error(`Function ${a_column} takes arguments, only methods without will work.`);
        }
        a_column = a_column();
      }
      let b_column = b[sortColumn];
      if (typeof b_column == 'function') {
        if (b_column.length > 0) {
          throw Error(`Function ${b_column} takes arguments, only methods without will work.`);
        }
        b_column = b_column();
      }

      return (a_column > b_column) ? 1 : (a_column === b_column) ? (a.id > b.id ? 1 : -1) : -1;
    });
    if (sortDir === 'desc') {
      entries.reverse();
    }

    return entries;
  }

  /** Toggle Sort direction */
  const sortDirToggle = () => {
    setSortDir((sortDir) => {
      sortDir = sortDir === 'desc' ? 'asc' : 'desc';
      return sortDir;
    });
  };

  /** Changes Sort column */
  const sortColumnChange = (column: string) => () => {
    if (column === sortColumn) {
      sortDirToggle();
      return;
    }
    setSortColumn(column);
  };

  const onClick = (entry: Item) => () => {
    if (typeof onEntryClick === 'function') {
      onEntryClick(entry);
    }
  };

  const sortedItems: Item[] = sortData(items || []);
  const pages: Item[][] = _.chunk(sortedItems, pageSize);

  let page_entries = pages[0] || [];
  if (activePage > pages.length) {
    changePage(pages.length);
  }
  else {
    page_entries = pages[activePage];
  }
  page_entries = page_entries || [];

  useEffect(() => {
    if ([SIZE_LG, SIZE_XL, SIZE_XXL].includes(size)) {
      setPageRangeDisplayed(5);
      setMarginPagesDisplayed(1);
    }
    else {
      setPageRangeDisplayed(0);
      setMarginPagesDisplayed(0);
    }
  }, [size]);

  return <div className="sort-table">
    <Table {...extra_props} hover={!!onEntryClick}>
      <thead>
      <tr>
        {_.entries(columns).map(([column, header]: [keyof Item, TableColumn<Item>]) => {
          const sortable = typeof header.sortable == 'undefined' || header.sortable;
          let clickHandler = sortable ? sortColumnChange(column.toString()) : undefined;
          return <th key={column.toString()}
                     className={classNames(header.className, {'sortable': sortable})}
                     onClick={clickHandler}>
            {header.label} {sortable && <SortIcon column={column.toString()} currentColumn={sortColumn.toString()} dir={sortDir}/>}
          </th>;
        })}
      </tr>
      </thead>
      <tbody>
      {page_entries.map((entry, index) => {
        return <tr key={index} onClick={onClick(entry)}>
          {_.entries(columns).map(([column, header]: [keyof Item, TableColumn<Item>], index) => {
            return <td key={index + column.toString()} className={header.className}>{header.render ? header.render(entry) : entry[column]}</td>;
          })}
        </tr>;
      })}
      {!items || items.length === 0 ? <tr>
        <td colSpan={Object.keys(columns).length}>
          <div className="text-muted text-center text-sm">{emptyMessage}</div>
        </td>
      </tr> : null}
      </tbody>
    </Table>
    {pagination && <div className="table-pagination">
      <nav>
        {pages.length > 1 &&
            <ReactPaginate
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                breakClassName={'page-item d-none d-sm-none d-md-none'}
                breakLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                previousLabel={<FaChevronLeft/>}
                nextLabel={<FaChevronRight/>}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                activeClassName={'active'}
                forcePage={activePage}
                pageCount={pages.length}
                pageRangeDisplayed={pageRangeDisplayed}
                marginPagesDisplayed={marginPagesDisplayed}
                onPageChange={(page) => {
                  changePage(page.selected);
                }}
            />}
      </nav>
      <div className="size-options">
        <Select
            menuPlacement="auto"
            aria-label="Seitengröße"
            isClearable={false}
            isSearchable={false}
            defaultValue={SizeOptions[0]}
            options={SizeOptions}
            onChange={(selectOption) => {
              if (selectOption) setPageSize(selectOption.value);
            }}
            theme={theme => {
              theme.colors = {
                ...theme.colors,
                primary25: 'var(--lighter)',
                primary: 'var(--primary)'
              };
              return {...theme};
            }}
        />
      </div>
    </div>}
  </div>;
};

function sortColumnValidator(props: Record<string, any>, propName: string, componentName: string): Error | null {
  const value = props[propName];
  if (typeof value == 'undefined') {
    return null;
  }
  if (!props.items.length) {
    return null;
  }
  if (!(value in props.items[0])) {
    return new Error(
        `'${componentName}' field '${propName}' only accepts keys inside items. missing key '${value}'.`);
  }
  return null;
}
SortTable.propTypes = {
  columns: (props: SortTableProps, propName: string, componentName: string) => {
    const columns: Record<string, TableColumn> = props[propName];
    if (typeof columns == "undefined") {
      return new Error(`'${componentName}' field '${propName}' is required.`);
    }
    if (typeof columns != 'object') {
      return new Error(`'${componentName}' field '${propName}' needs to be an Record<string, TableColumn>.`);
    }
    if (_.isNull(props.items) || !props.items.length) {
      return null;
    }
    for (let key in columns) {
      if (!columns.hasOwnProperty(key)) {
        continue
      }
      if (!(key in props.items[0])) {
        return new Error(`'${componentName}' field '${propName}' only accepts keys inside items. missing key '${key}'`);
      }
    }
    return null;
  },
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired).isRequired,
  sortColumn: sortColumnValidator,
  defaultSortColumn: sortColumnValidator,
  sortDir: PropTypes.oneOf(['asc', 'desc']),
  onEntryClick: PropTypes.func,
  onChange: PropTypes.func,
  emptyMessage: PropTypes.string,
  pagination: PropTypes.bool,
};
SortTable.defaultProps = {
  sortDir: 'desc',
  onEntryClick: undefined,
  onChange: undefined,
  emptyMessage: 'Keine Einträge gefunden.',
  pagination: false,
};
