import {BaseModel} from './base'
import {Plone, PloneData} from './plone'
import {DistributionsIcons, KernelIcons} from "../views/servers/mapping";
import {FaQuestion} from "react-icons/all";



/**
 * Keep in Sync with database.server.server.ServerHoster
 */

export const enum ServerHoster {
  other = 'other',
  internal = 'internal',
  hetzner_dedicated = 'hetzner_dedicated',
  hetzner_cloud = 'hetzner_cloud',
  hosteurope_vps = 'hosteurope_vps',
  hosteurope_vs10p = 'hosteurope_vs10p',
  plusserver = 'plusserver'
}

export const ServerHosterData: Record<ServerHoster, {label: string, url: string | null}> = {
  [ServerHoster.other]: {
    label: 'Unbekannt',
    url: null,
  },
  [ServerHoster.internal]: {
    label: 'Intern',
    url: null
  },
  [ServerHoster.hetzner_dedicated]: {
    label: 'Hetzner: Dedicated',
    url: 'https://robot.hetzner.com/server'
  },
  [ServerHoster.hetzner_cloud]: {
    url: 'https://console.hetzner.cloud/projects',
    label: 'Hetzner: Cloud'
  },
  [ServerHoster.hosteurope_vps]: {
    label: 'HostEurope: VPS',
    url: 'https://kis.hosteurope.de/administration/vps/'
  },
  [ServerHoster.hosteurope_vs10p]: {
    label: 'HostEurope: VS 10 Plus',
    url: 'https://kis.hosteurope.de/administration/virtual-server-10-plus/'
  },
  [ServerHoster.plusserver]: {
    label: 'PlusServer',
    url: 'https://login.plusserver.com/en/services/server'
  },
}


export type ServerData = {
  id: number
  hostname: string
  fqdn: string
  distribution: string
  distribution_version: string
  kernel: string
  kernel_version: string
  start_time: string
  python2: string
  python3: string
  node: string|null
  npm: string|null
  yarn: string|null
  cpu_name: string
  cpu_cores: number
  cpu_sockets: number
  cpu_cores_per_sockets: number
  cpu_frequency: number|null
  system_memory: number
  updated_at: string
  deactivated: boolean
  deactivated_reason: string
  hoster: ServerHoster
  plones?: PloneData[]
}

export class Server extends BaseModel {
  hostname: string;
  fqdn: string;
  distribution: string;
  distribution_version: string;
  kernel: string;
  kernel_version: string;
  start_time: Date;
  python2: string;
  python3: string;
  node: string|null;
  npm: string|null;
  yarn: string|null;
  cpu_name: string;
  cpu_cores: number;
  cpu_sockets: number;
  cpu_cores_per_sockets: number;
  cpu_frequency: number|null;
  system_memory: number;
  updated_at: Date|null;
  deactivated: boolean;
  deactivated_reason: string;
  _hoster: ServerHoster;
  plones: Plone[] = [];

  constructor (serverData: ServerData, plones?: Plone[]) {
    super(serverData);
    this.hostname = serverData.hostname;
    this.fqdn = serverData.fqdn;
    this.distribution = serverData.distribution;
    this.distribution_version = serverData.distribution_version;
    this.kernel = serverData.kernel;
    this.kernel_version = serverData.kernel_version;
    this.start_time = this.convertDate(serverData.start_time) ?? new Date(0);
    this.python2 = serverData.python2;
    this.python3 = serverData.python3;
    this.node = serverData.node;
    this.npm = serverData.npm;
    this.yarn = serverData.yarn;
    this.cpu_name = serverData.cpu_name;
    this.cpu_cores = serverData.cpu_cores;
    this.cpu_sockets = serverData.cpu_sockets;
    this.cpu_cores_per_sockets = serverData.cpu_cores_per_sockets;
    this.cpu_frequency = serverData.cpu_frequency;
    this.system_memory = serverData.system_memory;
    this.updated_at = this.convertDate(serverData.updated_at);
    this.deactivated = serverData.deactivated;
    this.deactivated_reason = serverData.deactivated_reason;
    this._hoster = serverData.hoster;

    if (serverData.plones) {
      this.plones = serverData.plones.map((ploneData) => {
        const existingPlone = plones ? plones.find((e) => e.id === ploneData.id) : null;
        return existingPlone??new Plone(ploneData);
      });
    }
  }

  update(data: Partial<ServerData>): this {
    super.update(data);
    this.hostname = data.hostname??this.hostname;
    this.fqdn = data.fqdn??this.fqdn;
    this.distribution = data.distribution??this.distribution;
    this.distribution_version = data.distribution_version??this.distribution_version;
    this.kernel = data.kernel??this.kernel;
    this.kernel_version = data.kernel_version??this.kernel_version;
    this.start_time = this.convertDate(data.start_time) ?? this.start_time;
    this.python2 = data.python2??this.python2;
    this.python3 = data.python3??this.python3;
    this.node = data.node??this.node;
    this.npm = data.npm??this.npm;
    this.yarn = data.yarn??this.yarn;
    this.cpu_name = data.cpu_name??this.cpu_name;
    this.cpu_cores = data.cpu_cores??this.cpu_cores;
    this.cpu_sockets = data.cpu_sockets??this.cpu_sockets;
    this.cpu_cores_per_sockets = data.cpu_cores_per_sockets??this.cpu_cores_per_sockets;
    this.cpu_frequency = data.cpu_frequency??this.cpu_frequency;
    this.system_memory = data.system_memory??this.system_memory;
    this.updated_at = this.convertDate(data.updated_at) ?? this.updated_at;
    this.deactivated = data.deactivated??this.deactivated;
    this.deactivated_reason = data.deactivated_reason??this.deactivated_reason;
    this._hoster = data.hoster??this._hoster;

    if (data.plones) {
      this.plones = data.plones.map((ploneData) => {
        const existingPlone = this.plones ? this.plones.find((e) => e.id === ploneData.id) : null;
        return existingPlone ? existingPlone.update(ploneData) : new Plone(ploneData);
      });
    }

    return this;
  }

  get readable_memory () {
    const bytes = this.system_memory * 1024
    const sizes = new Map(['B', 'KB', 'MB', 'GB', 'TB'].map((name, idx) => [name, bytes / 2 ** (10 * idx)]))

    for (const [name, value] of [...sizes.entries()].reverse()) {
      if (value > 2) return `${Math.round(value * 100) / 100} ${name}`
    }

    return `${Math.round(bytes * 100) / 100} B`
  }

  get hoster() {
    return ServerHosterData[this._hoster];
  }

  get Hoster(): JSX.Element {
    if (this.hoster.url) {
      return <a onClick={(e) => e.stopPropagation()} href={this.hoster.url} target={'_blank'} rel={'noopener noreferrer'}>{this.hoster.label}</a>;
    }
    return <span>{this.hoster.label}</span>;
  }

  get selectedHosterOption() {
    const options = this.hosterOptions;
    for(let entry of options){
      if(entry.value === this._hoster){
        return entry;
      }
    }
    return null;
  }

  get hosterOptions() {
    return Object.entries(ServerHosterData).map(([key, entry]) => {
      return {
        label: entry.label,
        value: key
      };
    });
  }

  // noinspection JSUnusedGlobalSymbols
  get OSIcon() {
    return DistributionsIcons.get(this.distribution.toLowerCase()) ?? FaQuestion;
  }

  // noinspection JSUnusedGlobalSymbols
  get KernelIcon() {
    return KernelIcons.get(this.kernel.toLowerCase()) ?? FaQuestion;
  }

  getURL(): string {
    return `/server/${this.hostname}`;
  }
}