import React, {useCallback, useEffect, useState} from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from "reactstrap";
import Header from "../../layouts/Header";
import {Link, useHistory, useParams} from "react-router-dom";
import {useUserContext} from "../../user";
import {Addon, Egg, NPMPackage, Plone, PloneAddonStates, PloneData, RouteElement} from "../../types";
import {LastUpdate, SortTable} from "../../components";
import {PloneLogo, ZopeLogo} from "../../components/Icons/plone";
import {
  BiSubdirectoryRight,
  CgTemplate,
  FaChevronLeft,
  FaCog,
  FaNodeJs,
  FaNpm,
  FaPython,
  GiCheckMark,
  GiPillow,
  RiCoreosFill
} from "react-icons/all";
import classnames from "classnames";
import PropTypes from "prop-types";
import Moment from "react-moment";
import {REFRESH_INTERVAL} from "../../config";
import {TableColumn} from "../../components/Table";

interface IPloneModal {
  isOpen: boolean
  onSubmit: (plone: Plone) => void
  onClose?: () => void
  plone: Plone
}

const PloneModal: React.FunctionComponent<IPloneModal> = (props) => {
  const {request} = useUserContext();
  const {isOpen, onSubmit, onClose, plone} = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  type possibleTabs = 'properties';
  const [activeTab, setActiveTab] = useState<possibleTabs>('properties');

  const toggleTab = (tab: possibleTabs) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onModalClose = () => {
    if (typeof onClose == "function") onClose();
  };

  const onFormSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setIsSubmitting(true);

    request<PloneData>(`/plone/${plone.id}/update_settings`, 'PUT', data)
        .then((ploneData) => onSubmit(plone.update(ploneData)))
        .catch(reason => setMessage(reason.toString()))
        .finally(() => {
          setIsSubmitting(false);
        })
  }, [onSubmit, request, plone]);

  return <Modal isOpen={isOpen} size={"lg"} backdrop className={'plone-modal'}>
    <Form onSubmit={onFormSubmit}>
      <ModalHeader>{`${plone.name} bearbeiten`}</ModalHeader>
      <ModalBody>
        <Alert color="danger" isOpen={!!message} toggle={() => setMessage('')}>{message}</Alert>
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({active: activeTab === 'properties'})}
                     onClick={() => toggleTab('properties')}>
              Eigenschaften
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={'properties'}>
            <FormGroup>
              <Label for="update-warranty-field">Update-Garantie</Label>
              <Input id="update-warranty-field"
                     type="checkbox"
                     name="update_warranty"
                     defaultChecked={plone.update_warranty}
              />
            </FormGroup>
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        {isSubmitting ?
            <Spinner size={'sm'}/>
            : <>
              <Button outline type="button" onClick={onModalClose}>Schließen</Button>
              <Button type="submit" color="primary">Speichern</Button>
            </>}
      </ModalFooter>
    </Form>
  </Modal>;
};
PloneModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  plone: PropTypes.instanceOf(Plone).isRequired
};

const PackageTabs: React.FunctionComponent<{ plone: Plone }> = ({plone}) => {
  const tabs = ['addons', 'npm', 'eggs'] as const;
  type possibleTabs = typeof tabs[number];
  const [activeTab, setActiveTab] = useState<possibleTabs>('addons');
  const toggle = (tab: possibleTabs) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [showNPMThirdparty, setShowNPMThirdparty] = useState(false);
  const [showInstalled, setShowInstalled] = useState(true);
  const [showBroken, setShowBroken] = useState(true);
  const [showNotInstalled, setShowNotInstalled] = useState(false);

  const filteredAddons = plone.addons.filter((addon) => {
    if (addon.status === 'broken' && !showBroken) {
      return false;
    }
    if (addon.status === 'installed' && !showInstalled) {
      return false;
    }
    return !(addon.status === 'not_installed' && !showNotInstalled);
  });
  const filteredNPMPackages = plone.npm_packages.filter((npm_package) => !showNPMThirdparty ? npm_package.direct : true);

  const BrokenIcon = PloneAddonStates['broken'].icon;
  const InstalledIcon = PloneAddonStates['installed'].icon;
  const NotInstalledIcon = PloneAddonStates['not_installed'].icon;

  // @ts-ignore: 2740
  const addonsColumns: Record<Partial<keyof Addon>, TableColumn<Addon>> = {
    name: {
      label: 'Name',
      sortable: true
    },
    version: {
      label: 'Version',
      sortable: true
    },
    status: {
      label: 'Status',
      sortable: true,
      render: (addon) => {
        const Icon = addon.status_icon;
        return <>
          <Icon/>
          {' ' + addon.status_label}
        </>;
      }
    }
  };
  // @ts-ignore: 2740
  const npmPackageColumns: Record<Partial<keyof NPMPackage>, TableColumn<NPMPackage>> = {
    name: {
      label: 'Name',
      sortable: true
    },
    version: {
      label: 'Version',
      sortable: true,
      render: (entry) => {
        if (!(entry instanceof NPMPackage)) throw new TypeError('Entry not instance of NPMPackage');
        return <a href={entry.getNPMRegistryURL()} target="_blank" rel="noopener noreferrer">{entry.version}</a>;
      }
    },
    latest_version: {
      label: 'Neuste',
      render: (entry) => {
        if (!(entry instanceof NPMPackage)) throw new TypeError('Entry not instance of NPMPackage');
        return <a href={entry.getNPMRegistryURL('latest')} target="_blank"
                  rel="noopener noreferrer">{entry.latest_version}</a>;
      }
    },
    wanted_version: {
      label: 'Gewünscht',
      render: (entry) => {
        if (!(entry instanceof NPMPackage)) throw new TypeError('Entry not instance of NPMPackage');
        return <a href={entry.getNPMRegistryURL('wanted')} target="_blank"
                  rel="noopener noreferrer">{entry.wanted_version ?? entry.version}</a>;
      }
    },
    is_latest: {
      label: 'Aktuell',
      sortable: true,
      render: (npm_package) => {
        if (!(npm_package instanceof NPMPackage)) throw new TypeError();
        if (npm_package.is_latest()) {
          return <>
            <GiCheckMark/>
          </>;
        }
        return null;
      }
    }
  };
  // @ts-ignore: 2740
  const eggColumns: Record<Partial<keyof Egg>, TableColumn<Egg>> = {
    name: {
      label: 'Name',
      sortable: true
    },
    version: {
      label: 'Version',
      sortable: true,
      render: (entry) => {
        if (!(entry instanceof Egg)) throw new TypeError('Entry not an instance of Egg');
        return <a href={entry.getPyPiURL()} target="_blank" rel="noopener noreferrer">{entry.version}</a>;
      }
    }
  };

  return <>
    <Nav tabs>
      <NavItem key={'addons'}>
        <NavLink className={classnames({active: activeTab === 'addons'})} onClick={() => toggle('addons')}>
          Addons <Badge>{filteredAddons.length}</Badge>
        </NavLink>
      </NavItem>
      <NavItem key={'npm'}>
        <NavLink className={classnames({active: activeTab === 'npm'})} onClick={() => toggle('npm')}>
          NPM <Badge>{filteredNPMPackages.length}</Badge>
        </NavLink>
      </NavItem>
      <NavItem key={'eggs'}>
        <NavLink className={classnames({active: activeTab === 'eggs'})} onClick={() => toggle('eggs')}>
          Eggs <Badge>{plone.eggs.length}</Badge>
        </NavLink>
      </NavItem>
    </Nav>
    <TabContent activeTab={activeTab}>
      <TabPane tabId={'addons'}>
        <div className="filter">
          <ButtonGroup size={"sm"}>
            <Button id={'toggle-installed'}
                    color={showInstalled ? 'primary' : 'secondary'}
                    onClick={() => setShowInstalled(s => !s)}>
              <InstalledIcon/>
            </Button>
            <UncontrolledTooltip target={'toggle-installed'}>
              {PloneAddonStates['installed'].label} {showInstalled ? 'eingeblendet' : 'ausgeblendet'}
            </UncontrolledTooltip>
            <Button id={'toggle-not_installed'}
                    color={showNotInstalled ? 'primary' : 'secondary'}
                    onClick={() => setShowNotInstalled(s => !s)}>
              <NotInstalledIcon/>
            </Button>
            <UncontrolledTooltip target={'toggle-not_installed'}>
              {PloneAddonStates['not_installed'].label} {showNotInstalled ? 'eingeblendet' : 'ausgeblendet'}
            </UncontrolledTooltip>
            <Button id={'toggle-broken'}
                    color={showBroken ? 'primary' : 'secondary'}
                    onClick={() => setShowBroken(s => !s)}>
              <BrokenIcon/>
            </Button>
            <UncontrolledTooltip target={'toggle-broken'}>
              {PloneAddonStates['broken'].label} {showBroken ? 'eingeblendet' : 'ausgeblendet'}
            </UncontrolledTooltip>
          </ButtonGroup>
        </div>
        <SortTable
            striped
            responsive
            columns={addonsColumns}
            items={filteredAddons}
            pagination
        />
      </TabPane>
      <TabPane tabId={'npm'}>
        <div className="filter">
          <Button id={'toggle-thirdparty'} size={"sm"}
                  color={showNPMThirdparty ? 'primary' : 'secondary'}
                  onClick={() => setShowNPMThirdparty(s => !s)}>
            <BiSubdirectoryRight/>
          </Button>
          <UncontrolledTooltip target={'toggle-thirdparty'}>
            Drittabhängigkeiten {showNPMThirdparty ? 'eingeblendet' : 'ausgeblendet'}
          </UncontrolledTooltip>
        </div>
        <SortTable
            striped
            responsive
            columns={npmPackageColumns}
            items={filteredNPMPackages}
        />
      </TabPane>
      <TabPane tabId={'eggs'}>
        <SortTable
            striped
            responsive
            columns={eggColumns}
            items={plone.eggs}
            pagination
        />
      </TabPane>
    </TabContent>
  </>;
};
PackageTabs.propTypes = {
  plone: PropTypes.instanceOf(Plone).isRequired
};

/** Detail view for Plone */
const PloneDetails: React.FunctionComponent<{route: RouteElement}> = (props) => {
  const {route} = props;
  const {request, addNotification, currentUser} = useUserContext();
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
  const {id: plone_id} = useParams<{ id: string }>();
  const history = useHistory();
  const [plone, setPlone] = useState<Plone | null>(null);

  useEffect(() => {
    const intervalFunction = (interval_duration?: number): NodeJS.Timer | undefined => {
      setIsLoading(true);
      request<PloneData>(`/plone/${plone_id}`, 'GET')
          .then((ploneData) => {
            setPlone(plone => plone ? plone.update(ploneData) : new Plone(ploneData));
            setIsLoading(false);
            setLastUpdate(new Date());
          })
          .catch(reason => {
            addNotification('Fehler', reason.toString(), 'danger');
            history.push(route.parentPath);
          });

      if (interval_duration) {
        return setInterval(() => intervalFunction(), interval_duration);
      }
      return undefined;
    }
    const interval = intervalFunction(REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [request, addNotification, plone_id, history, route])

  if (!plone) {
    return <>
      <Header/>
      <div className="mt-3 text-center"><Spinner/></div>
    </>
  }

  /** Called when plone was successfully edited */
  const editPlone = (plone: Plone) => {
    setEdit(false);
    setPlone(plone);
  };

  const repository_url = `/gitlab/projects/${plone.repository_namespace}/${plone.repository_name.replace('.git', '')}`

  return <>
    <Header/>
    <Container className="plone-details-view mt--6" fluid>
      <Card className="shadow mb-4 main-details">
        <CardHeader>
          <Link className="back" to={`/plone`}><FaChevronLeft/></Link>
          <img alt="" className="card-img" src={plone.favicon}/>
          <h1>{plone.name}</h1>
          {currentUser && currentUser.hasPermission('edit_plone') ?
              <Button role="edit" color="primary" onClick={() => setEdit(true)}><FaCog/></Button> : null}
        </CardHeader>
        <CardBody className="grid">
            <div>
              <h2 className="text-muted text-center text-sm">Eigenschaften</h2>
              <dl className="table-grid centered">
                <dt>Domain</dt>
                <dd>
                  <a href={'https://' + plone.domain} target="_blank" rel="noopener noreferrer">{plone.domain}</a>
                </dd>
                <dt>Repository</dt>
                <dd>
                  {currentUser && currentUser.hasPermission('view_gitlab') ? <Link to={repository_url}>{plone.repository_name}</Link> : plone.repository_name}
                </dd>
                <dt>Branch</dt>
                <dd>{plone.repository_branch}</dd>
                <dt>Update-Garantie</dt>
                <dd>{plone.update_warranty ? 'Ja' : 'Nein'}</dd>
                <dt>Server</dt>
                <dd>{plone.server ? <Link to={plone.server.getURL()}>{plone.server.hostname}</Link>: 'N/A'}</dd>
                <dt>Letztes Update</dt>
                <dd><Moment withTitle titleFormat={"HH:mm, DD.MM.YYYY"} date={plone.updated_at} fromNow interval={1000}/>
                </dd>
              </dl>
            </div>
            <div>
              <h2 className="text-muted text-center text-sm">Versionen</h2>
              <dl className="table-grid centered">
                <dt>Plone <PloneLogo/></dt>
                <dd>{plone.plone_version}</dd>
                <dt>Python <FaPython/></dt>
                <dd>{plone.python_version}</dd>
                <dt>Zope <ZopeLogo/></dt>
                <dd>{plone.zope_version}</dd>
                <dt>CMF <RiCoreosFill/></dt>
                <dd>{plone.cmf_version}</dd>
                <dt>PIL <GiPillow/></dt>
                <dd>{plone.pil_version}</dd>
                <dt>NPM <FaNpm/></dt>
                <dd>{plone.npm_version}</dd>
                <dt>NodeJS <FaNodeJs/></dt>
                <dd>{plone.nodejs_version}</dd>
                <dt>Master Plone <CgTemplate/></dt>
                <dd>{plone.master_plone_template_version}</dd>
              </dl>
            </div>
        </CardBody>
      </Card>
      <Card className="shadow mb-4 packages">
        <CardHeader><h2>Pakete</h2></CardHeader>
        <CardBody><PackageTabs plone={plone}/></CardBody>
      </Card>
      <PloneModal plone={plone} isOpen={edit} onSubmit={editPlone} onClose={() => setEdit(false)}/>
      <LastUpdate date={lastUpdate} loading={isLoading}/>
    </Container>
  </>;
};
PloneDetails.propTypes = {
  route: PropTypes.instanceOf(RouteElement).isRequired
}

export default PloneDetails;
