import {convertDate} from "../helper";
import {isNull, isUndefined} from "lodash";


export class BaseModel {
    id: number;

    constructor(data: Record<string, any>) {
        if(isUndefined(data.id)) {
            throw new Error(`Class ${this.constructor.name} of BaseModel needs to have ID`);
        }
        this.id = data.id;
    }

    protected convertDate(value: string | Date | undefined | null): Date | null {
        if (isUndefined(value) || isNull(value)) return null;
        return convertDate(value);
    }

    public update(data: Partial<Record<string, any>>): this {
        this.id = data.id ?? this.id;
        return this;
    }

    public same<T extends BaseModel>(a: T): boolean {
        return this.id === a.id;
    }

    public toString(params?: string[]): string {
        params = ['id', ...params||[]];
        const paramStr = (params).map(p => {
           return `${p}="${this.hasOwnProperty(p) ? this[p as keyof this] : undefined}"`;
        }).join(' ');
        return `<${this.constructor.name} ${paramStr}>`;
    };
}