import React, {useState} from "react";
import {randomAZ, safeCSSId} from "../../helper";
import {Popover, PopoverBody, PopoverHeader} from "reactstrap";
import {BranchIcon, PipelineIcon} from "../../components/Icons/gitlab";
import {GitLabPipeline, JobStatusEnum, PipelineStates} from "../../types";
import Moment from "react-moment";
import PropTypes from "prop-types";
import {IIconProps} from "../../components/Icons/type";


interface IPipelineStatusProps extends IIconProps {
    pipeline?: GitLabPipeline | null
    branch: string
}

/** Component to show Pipeline status with Job information */
const PipelineStatus: React.FunctionComponent<IPipelineStatusProps> = (props) => {
    const {pipeline, branch, ...extra_props} = props;
    const [popoverID] = useState<string>(`project-pipeline-${pipeline?.id}-${safeCSSId(branch)}-${randomAZ(24)}`);
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const togglePopover = () => setPopoverOpen(oldPopOverOpen => !oldPopOverOpen);

    const state = PipelineStates[pipeline?.status ?? 'null'];
    const Icon = state.icon;
    const title = state.label;
    const color = state.color;

    const style = {'--svg-status-fg': color} as React.CSSProperties;

    return <span className="pipeline-status">
    <Popover
        target={popoverID}
        isOpen={popoverOpen}
        toggle={togglePopover}
        className='project-pipeline-popover'
        trigger='touch hover click focus'
        positionFixed
    >
      <PopoverHeader>
        <PipelineIcon/> <span>#{pipeline?.id}</span>
      </PopoverHeader>
      <PopoverBody>
        <div><Icon style={style}/> <span>{title}</span></div>
        <div><BranchIcon/> <span>{branch}</span></div>
          {pipeline?.created_at &&
              <div><strong>Erstellt:</strong> <Moment fromNow date={pipeline.created_at}/></div>}
          {pipeline?.updated_at &&
              <div><strong>Update:</strong> <Moment fromNow date={pipeline.updated_at}/></div>}
          {pipeline?.jobs.length ? <>
              <hr/>
              <ul>
                  {pipeline.jobs.map((job, key) => {
                      const RenderIcon = JobStatusEnum[job.status].icon;
                      const title = JobStatusEnum[job.status].label;
                      const color = JobStatusEnum[job.status].color;
                      const style = {'--svg-status-fg': color} as React.CSSProperties;
                      return <li key={key}>
                          <RenderIcon title={title} style={style}/>
                          <a href={job.url}>{job.name}</a>
                      </li>;
                  })}
              </ul>
          </> : null}
      </PopoverBody>
    </Popover>
    <Icon
        {...extra_props}
        className="pipeline-icon"
        title={title}
        style={style}
        id={popoverID}
    />
  </span>;
}

PipelineStatus.propTypes = {
    branch: PropTypes.string.isRequired,
    pipeline: PropTypes.instanceOf(GitLabPipeline),
};

export default PipelineStatus;