import React, {useCallback, useEffect, useState} from "react";
import {Badge, Card, Container} from "reactstrap";
import Header from "../../layouts/Header";
import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import {useUserContext} from "../../user";
import Spinner from "reactstrap/lib/Spinner";
import PipelineStatus from "./PipelineStatus";
import {GitLabRunner, GitLabRunnerData} from "../../types";
import {SortTable, TableColumn, TSortDir} from "../../components/Table";
import _ from "lodash";
import {LastUpdate, useQuery} from "../../components";
import useUnmountSignal from "use-unmount-signal";


/** View to List GitLab Runners */
const RunnerListing: React.FunctionComponent = () => {
  const {request, addNotification} = useUserContext();
  const history = useHistory();
  const unmountSignal = useUnmountSignal();
  const location = useLocation();
  const {sort, dir} = useQuery<{ dir?: TSortDir, sort?: keyof GitLabRunner }>();
  const [sortDir, setSortDir] = useState<TSortDir>(dir ?? 'desc');
  const [sortColumn, setSortColumn] = useState<keyof GitLabRunner>(sort ?? 'id');
  const [runners, setRunners] = useState<GitLabRunner[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastUpdate, setLastUpdate] = useState<Date | null>(null);

  const onTableSort = useCallback((sortColumn: string, sortDir: TSortDir) => {
    const state = {sort: sortColumn, dir: sortDir};
    if (!_.isEqual(state, location.state)) {
      history.push({search: queryString.stringify(state)}, state);
    }
  }, [location.state, history]);

  useEffect(() => {
    setSortDir((dir === 'desc') ? 'desc' : 'asc');
    setSortColumn((sort) ? sort : 'id');
  }, [sortDir, sortColumn, dir, sort]);

  useEffect(() => {
    if (unmountSignal.aborted) return;
    const updateRunners = (interval_duration?: number): NodeJS.Timer | undefined => {
      setIsLoading(true);
      request<GitLabRunnerData[]>('/gitlab/runners', 'GET', undefined, unmountSignal)
          .then((runnersData) => {
            setIsLoading(false);
            setLastUpdate(new Date());
            setRunners(runners => runnersData.map((runnerData) => {
              const index = _.findIndex(runners, {id: runnerData.id});
              if (index >= 0) {
                return runners[index];
              }
              return new GitLabRunner(runnerData);
            }));
          })
          .catch(reason => unmountSignal.aborted ? '' : addNotification('Fehler', reason.toString(), 'danger'));

      if (interval_duration) {
        return setInterval(() => updateRunners(), interval_duration);
      }
      return undefined;
    }
    const interval = updateRunners(60 * 1000);
    return () => clearInterval(interval);
  }, [request, addNotification, unmountSignal]);

  if (!lastUpdate) {
    return <>
      <Header/>
      <div className="mt-3" style={{textAlign: 'center'}}><Spinner/></div>
    </>;
  }

  // @ts-ignore: 2740
  const tableColumns: Record<Partial<keyof GitLabRunner>, TableColumn<GitLabRunner>> = {
    id: {
      label: 'ID'
    },
    name: {
      label: 'Name'
    },
    status: {
      label: 'Status',
      render: (runner) => {
        if (!(runner instanceof GitLabRunner)) throw new TypeError();
        return <Badge color={runner.status === 'online' ? 'success' : 'danger'}>{runner.status}</Badge>;
      }
    },
    job: {
      label: 'Job',
      sortable: false,
      render: (runner) => {
        if (!(runner instanceof GitLabRunner)) throw new TypeError();
        return runner.job ? <PipelineStatus branch={runner.job.ref} pipeline={runner.job.pipeline}/> : null;
      }
    },
    ip: {
      label: 'IP'
    }
  };

  return <>
    <Header/>
    <Container className="mt--6 listing-view runner-listing" fluid>
      <Card className="shadow">
        <SortTable
            className="border-top-0"
            responsive
            striped
            columns={tableColumns}
            items={runners}
            sortDir={sortDir}
            sortColumn={sortColumn}
            onSort={onTableSort}
            pagination
        />
      </Card>
      <LastUpdate date={lastUpdate} loading={isLoading}/>
    </Container>
  </>
}


export default RunnerListing;
