import React from "react";
import {useLocation} from "react-router-dom";

/** Hook to get all current URL Query Parameter */
function useQuery<T extends Record<string, string | string[]>>(): T {
    const {search} = useLocation();

    return React.useMemo(() => {
        const params = new URLSearchParams(search);
        const searchData = Object();

        params.forEach((value, key) => {
            if (searchData.hasOwnProperty(key)) {
                let currentValue = searchData[key];
                if(!(currentValue instanceof Array)){
                    currentValue = [currentValue];
                }
                searchData[key] = [...currentValue, value];
                return;
            }
            searchData[key] = value;
        });

        return searchData;
    }, [search]);
}

export default useQuery;