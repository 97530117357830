import React from "react";
import {Spinner} from "reactstrap";
import Moment from "react-moment";
import PropTypes from "prop-types";


const LastUpdate: React.FunctionComponent<{ date: Date | null | undefined, loading: boolean }> = (props) => {
    const {loading, date} = props;

    let moment = null;
    const desc = 'Letzte Aktualisierung ';
    const format = `[${desc}]HH:mm:ss DD.MM.YYYY`;
    if (date instanceof Date) {
        moment = <>
            {desc}
            <Moment
                aria-label={desc}
                withTitle
                titleFormat={format}
                fromNow
                trim
                interval={200}
                date={date}
            />
        </>;
    }

    return <div className="last-update">
        {loading ? <Spinner size="sm"/> : moment}
    </div>;
}
LastUpdate.propTypes = {
    date: PropTypes.instanceOf(Date),
    loading: PropTypes.bool.isRequired
}

export default LastUpdate;