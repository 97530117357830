import React, {createContext, FunctionComponent, useContext, useEffect, useState} from "react";
import {
    User,
    UserData,
    UserNotification,
    UserNotificationCallback,
    UserNotificationData,
    UserNotificationIcon
} from "./types";
import {Toast, ToastBody, ToastHeader} from "reactstrap";
import {useCookies} from "react-cookie";
import PropTypes from "prop-types";
import Gravatar from "react-gravatar";
import {useHistory} from "react-router-dom";
import _ from "lodash";

interface IUserContext {
    currentUser: User | null
    sessionValidated: boolean
    /** Get React element avatar for supplied user */
    getAvatar: (ident: User | string, props: Record<string, any>) => JSX.Element
    /** Request to API, automatically adds auth header */
    request: <T = any>(path: string, method?: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH', data?: any, signal?: AbortSignal) => Promise<T>
    /** Updates the currently logged-in User */
    update: (params: Partial<UserData>) => Promise<true | string>
    /** login and set as signed-in */
    login: (email: string, password: string) => Promise<true | string>
    /** Logout currently signed-in User */
    logout: () => Promise<true | string>
    /**
     * Show notification
     *
     * @param {string} title - Title of Notification
     * @param {string} [message] - Content of Notification, fallback to title if not provided
     * @param {UserNotificationIcon} [icon] - Icon displayed beside title
     * @param {number|false|null} [duration] - defaults to 5000ms, with false/null provided will hold indefinitly
     * @param {UserNotificationCallback|UserNotificationCallback[]} [onClose] - Function(s) called on closing
     * @returns {UserNotification} - instance of notification
     */
    addNotification: (title: string, message?: string, icon?: UserNotificationIcon, duration?: number, onClose?: UserNotificationCallback) => UserNotification
}

export const UserContext = createContext<IUserContext>({
    currentUser: null,
    sessionValidated: false
} as IUserContext);
export const useUserContext = () => useContext(UserContext);

interface IToastNotification {
    notification: UserNotification
    onClose: () => void
}

const ToastNotification = ({notification, onClose}: IToastNotification) => {
    let Icon = notification.icon;

    useEffect(() => {
        if (_.isNull(notification.duration)) {
            return;
        }
        const timer = setTimeout(() => {
            onClose();
        }, notification.duration);

        return () => {
            clearTimeout(timer);
        }
    }, [notification.duration, onClose]);

    return <Toast onClick={() => onClose()}>
        <ToastHeader icon={<Icon/>}>
            {notification.title}
        </ToastHeader>
        {notification.message ? <ToastBody>{notification.message}</ToastBody> : null}
    </Toast>
};
ToastNotification.propTypes = {
    notification: PropTypes.instanceOf(UserNotification).isRequired,
    onClose: PropTypes.func.isRequired
}

/** Provider to handle logged-in User functions */
export const UserProvider: FunctionComponent<{ children: React.ReactNode }> = (props) => {
    const {children} = props;
    const history = useHistory();
    const [user, setUser] = useState<User | null>(null);
    const [notifications, setNotifications] = useState<UserNotification[]>([]);
    const [sessionValidated, setSessionValidated] = useState<boolean>(false);
    const [cookies, setCookie, removeCookie] = useCookies<'session', { 'session': string }>(['session']);

    const request: IUserContext['request'] = (path, method = 'GET', data, signal) => {
        const headers = new Headers({"Accept": "application/json"});
        if (user && user.session) {
            headers.append('Authenticated', user.session);
        }
        let body = undefined;
        const controller = new AbortController();
        const _signal = signal || controller.signal;

        if (data) {
            if (data instanceof FormData) {
                body = data;
            }
            else {
                headers.append("Content-Type", "application/json");
                body = JSON.stringify(data);
            }
        }

        return fetch(`${process.env.REACT_APP_API_ENDPOINT}${path}`, {
            method,
            headers,
            body,
            signal: _signal
        })
            .then((res) => {
                if (!res.ok) {
                    if (res.status === 423) {
                        return res.json().then(data => {
                            throw new Error(data.reason ? `${res.statusText}: ${data.reason}` : res.statusText);
                        });
                    }
                    throw new Error(res.statusText);
                }
                if (res.status === 403) {
                    history.push('/login');
                }
                if (res.headers.get('Content-Type') === 'application/json') {
                    return res.json();
                }
                return res;
            })
            .catch(reason => {
                if (!_signal.aborted) console.error(reason);
                return Promise.reject(reason);
            });
    };

    const login: IUserContext['login'] = (email, password) => {
        return request<UserData>('/user/login', "POST", {email, password})
            .then((userData) => {
                const user = new User(userData);
                setUser(user);
                setCookie('session', userData.session);
                return true;
            })
            .catch(reason => {
                return reason;
            });
    };

    const logout: IUserContext['logout'] = () => {
        return request<boolean>('/user/logout')
            .then(() => {
                setUser(() => {
                    removeCookie('session');
                    return null;
                });
                return true;
            })
            .catch(reason => {
                addNotification('Logout', reason.message, "danger");
                return reason;
            });
    };

    const update: IUserContext['update'] = (params) => {
        if (_.isNull(user)) {
            return Promise.reject('Not logged-in');
        }

        for (let param of Object.entries(params)) {
            const [key, value]: [key: string, value: UserData[keyof UserData]] = param;
            if (value === user[key as keyof UserData]) {
                delete params[key as keyof UserData];
            }
        }

        if (!params) {
            return Promise.reject('no Params');
        }

        return request<UserData>(`/user/update_profile`, 'PUT', params)
            .then((userData: UserData) => {
                user.update(userData);
                setUser(user);
                return true;
            })
            .catch((reason) => {
                return reason;
            })
    };

    /** Get React element avatar for supplied user */
    const getAvatar: IUserContext['getAvatar'] = (ident, props) => {
        if (typeof ident == "string") {
            return <Gravatar className={'avatar'} size={250} {...props} email={ident}/>;
        }
        return ident.avatar ? <img className={'avatar'} alt="" {...props} src={ident.avatar}/> :
            <Gravatar className={'avatar'} size={250} {...props} email={ident.email}/>;
    };

    const addNotification: IUserContext['addNotification'] = (title, message, icon = 'info', duration = 5000, onClose?: UserNotificationData['onClose']) => {
        const notification = new UserNotification({title, message, icon, duration, onClose});
        setNotifications(_notifications => {
            return [..._notifications, notification];
        });
        return notification;
    };

    useEffect(() => {
        if (sessionValidated) {
            return;
        }
        if (!cookies.session) {
            if (!sessionValidated) {
                setSessionValidated(true);
            }
            return;
        }

        const data = new FormData();
        data.append('session', cookies.session);

        fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/validate_session`, {
            method: 'POST',
            headers: new Headers({"Accept": "application/json"}),
            body: data
        })
            .then((res) => {
                if (!res.ok) {
                    if (res.status === 404) {
                        addNotification('Session', 'Session ist ausgelaufen', 'info');
                        removeCookie('session');
                    }
                }
                if (res.headers.get('Content-Type') === 'application/json') {
                    return res.json();
                }
                throw new Error(res.statusText);
            })
            .then((userData: UserData) => setUser(new User(userData)))
            .catch((reason) => {
                console.error(reason);
                addNotification('Error', reason.toString(), "danger");
            })
            .finally(() => setSessionValidated(true));
    }, [cookies.session, removeCookie, sessionValidated, setUser]);

    const value = {
        currentUser: user,
        sessionValidated,
        getAvatar,
        request,
        update,
        login,
        logout,
        addNotification,
    };

    return <UserContext.Provider value={value}>
        <div id={'toast-notifications'}>
            {notifications.map((notification, key) => {
                notification._closeMethod = () => {
                    setNotifications(_notifications => {
                        _notifications.splice(key, 1);
                        return [..._notifications];
                    });
                };
                return <ToastNotification key={key} notification={notification} onClose={() => notification.close()}/>;
            })}
        </div>
        {children}
    </UserContext.Provider>;
};

export default UserContext;