import PropTypes from 'prop-types';
import SyntaxHighlighter, {PrismAsyncLight as SyntaxBlock} from 'react-syntax-highlighter';
import {dracula} from 'react-syntax-highlighter/dist/cjs/styles/prism';
import ReactMarkdown from "react-markdown";
import React from "react";
import _ from "lodash";

interface ICodeBlock extends React.HTMLAttributes<HTMLDivElement> {
    inline?: boolean
    className?: string
    children: React.ReactNode
}

export const CodeBlock: React.FunctionComponent<ICodeBlock> = (props) => {
    const {inline, className, children, ...extra_props} = props;
    const match = /language-(\w+)/g.exec(className||'');
    let language = '';
    if (!_.isNull(match)) {
        language = match[1];
    }

    if (!inline && match && SyntaxHighlighter.supportedLanguages.includes(language)) {
        return <SyntaxBlock
            {...extra_props}
            language={language}
            PreTag="div"
            children={String(children).replace(/\n$/, '')}
            style={dracula}
        />;
    }

    return <code {...extra_props} className={className}/>;
}
CodeBlock.propTypes = {
    inline: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

const Markdown: React.FunctionComponent<{ text: string }> = (props) => {
    const {text} = props;

    function flatten(text: string, child: React.ReactNode): string {
        if (!child) {
            return text;
        }
        if (typeof child == 'string' || typeof child == 'number') {
            return text + child;
        }
        if (child === true) {
            return text;
        }
        if (!('props' in child)) {
            return text;
        }

        return React.Children.toArray(child.props.children).reduce(flatten, text);
    }

    interface IHeadingRenderer {
        node: JSX.Element
        level: number
        children: React.ReactNode
    }

    function headingRenderer({node, level, children, ...props}: IHeadingRenderer) {
        const children_arr = React.Children.toArray(children);
        const text: string = children_arr.reduce(flatten, '');
        const slug = text.toLowerCase().replace(/[\W:]/g, '-');
        return React.createElement(`h${level}`, {id: slug, ...props}, children);
    }

    const components: Record<string, any> = {
        h2: headingRenderer,
        h3: headingRenderer,
        h4: headingRenderer,
        h5: headingRenderer,
        h6: headingRenderer,
        code: CodeBlock
    };

    return <ReactMarkdown components={components} disallowedElements={["h1"]}>{text}</ReactMarkdown>;
}
Markdown.propTypes = {
    text: PropTypes.string.isRequired
};

export default Markdown;