import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {RouteElement, Server, ServerData} from '../../types';
import {useUserContext} from '../../user';
import queryString from 'query-string';
import Header from '../../layouts/Header';
import {Button, ButtonGroup, Card, Container, Spinner} from 'reactstrap';
import {SortTable, TableColumn, TSortDir} from '../../components/Table';
import PropTypes from "prop-types";
import _ from "lodash";
import {LastUpdate, useQuery} from "../../components";
import {REFRESH_INTERVAL} from "../../config";
import {GoEye, GoEyeClosed} from "react-icons/all";
import Moment from "react-moment";


const ServerListing: React.FunctionComponent<{ route: RouteElement }> = (props) => {
    const {route} = props;
    const {request, addNotification} = useUserContext();
    const {sort, dir} = useQuery<{ sort?: keyof Server; dir?: TSortDir }>();
    const history = useHistory();
    const location = useLocation();

    // State
    const [showDeactivated, setShowDeactivated] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
    const [servers, setServers] = useState<Server[]>([]);
    const [sortColumn, setSortColumn] = useState<keyof Server>(sort ?? 'id');
    const [sortDir, setSortDir] = useState<TSortDir>(dir ?? 'asc');

    // Methods
    const openServerDetailView = (server: Server) => {
        history.push(`${route.path}/${server.hostname}`);
    }

    const onTableSort = useCallback((sortColumn: string, sortDir: TSortDir) => {
        const state = {sort: sortColumn, dir: sortDir};
        if (!_.isEqual(state, location.state)) {
            history.push({search: queryString.stringify(state)}, state);
        }
    }, [location.state, history]);

    // Effects
    useEffect(() => {
        function intervalFunction(interval_duration?: number): NodeJS.Timer | undefined {
            setIsLoading(true);
            request<ServerData[]>('/server', 'GET')
                .then((serversData) => {
                    setIsLoaded(true);
                    setIsLoading(false);
                    setLastUpdate(new Date());
                    setServers(_servers => serversData.map((serverData) => {
                        const index = _.findIndex(_servers, {id: serverData.id});
                        if (index >= 0) {
                            const server = _servers[index];
                            return server.update(serverData);
                        }
                        return new Server(serverData);
                    }));
                })
                .catch(reason => addNotification('Fehler', reason.toString(), 'danger'));
            if (typeof interval_duration == "number") {
                return setInterval(() => intervalFunction(), interval_duration);
            }
            return undefined;
        }

        const interval = intervalFunction(REFRESH_INTERVAL);
        return () => clearInterval(interval);
    }, [request, addNotification]);

    useEffect(() => {
        setSortColumn((sort) ? sort : 'id');
        setSortDir((dir === 'desc') ? 'desc' : 'asc');
    }, [sort, dir]);

    // Render
    if (!isLoaded) {
        return <>
            <Header/>
            <div className="mt-3" style={{textAlign: 'center'}}><Spinner/></div>
        </>
    }

    // @ts-ignore: 2740
    const tableColumns: Record<Partial<keyof Server>, TableColumn<Server>> = {
        id: {
            label: 'ID'
        },
        hostname: {
            label: 'Hostname',
            render: ((server) => {
                const style = {
                    textDecoration: server.deactivated ? 'line-through': '',
                    color: server.deactivated ? 'grey': ''
                };
                return <span title={server.hostname} style={style} aria-label={server.hostname}>{server.fqdn}</span>;
            })
        },
        distribution: {
            label: 'Distribution',
            render: ((server) => {
                return <><server.OSIcon title={server.distribution}/> {server.distribution_version}</>;
            }),
            sortCmp: (a, b) => {
              const [major1, minor1] = a.distribution_version.split('.').map(Number);
              const [major2, minor2] = b.distribution_version.split('.').map(Number);

              if (major1 > major2) return 1;
              if (major1 < major2) return -1;
              if (minor1 > minor2) return 1;
              if (minor1 < minor2) return -1;
              return 0;
            }
        },
        kernel: {
            label: 'Kernel',
            render: (server) => {
                return <><server.KernelIcon title={server.kernel}/> {server.kernel_version}</>;
            },
            sortCmp: (a, b): number => {
                const [aVersion] = a.kernel_version.match(/^(\d+\.\d+\.\d+)/)??['0.0.0'];
                const [bVersion] = b.kernel_version.match(/^(\d+\.\d+\.\d+)/)??['0.0.0'];
                const aSuffix = a.kernel_version.slice(aVersion.length).replace(/^-+|-+$/g, '');
                const bSuffix = b.kernel_version.slice(bVersion.length).replace(/^-+|-+$/g, '');
                const [aMajor, aMinor, aPatch] = aVersion.split('.').map(Number);
                const [bMajor, bMinor, bPatch] = bVersion.split('.').map(Number);

                if (aMajor > bMajor) return 1;
                if (aMajor < bMajor) return -1;
                if (aMinor > bMinor) return 1;
                if (aMinor < bMinor) return -1;
                if (aPatch > bPatch) return 1;
                if (aPatch < bPatch) return -1;
                // Compare the suffixes if the main parts are equal
                if (aSuffix && bSuffix) {
                    // Split the suffixes into multiple parts
                    const aSuffixParts = aSuffix.split('-');
                    const bSuffixParts = bSuffix.split('-');
                    // Compare the parts as numbers or strings depending on their content
                    for (let i = 0; i < aSuffixParts.length; i++) {
                        if (aSuffixParts[i] !== bSuffixParts[i]) {
                            const isNumberA = !isNaN(Number(aSuffixParts[i]));
                            const isNumberB = !isNaN(Number(bSuffixParts[i]));
                            if (isNumberA && isNumberB) {
                                // Compare the parts as numbers if they are both numbers
                                if (Number(aSuffixParts[i]) > Number(bSuffixParts[i])) return 1;
                                if (Number(aSuffixParts[i]) < Number(bSuffixParts[i])) return -1;
                            }
                            else {
                                // Compare the parts as strings if they are not both numbers
                                return aSuffixParts[i].localeCompare(bSuffixParts[i]);
                            }
                        }
                    }
                }
                else if(aSuffix) {
                    return 1;
                }
                else if(bSuffix) {
                    return -1;
                }
                return 0;
            }
        },
        hoster: {
            label: 'Hoster',
            render: (server) => server.Hoster,
            sortCmp: (a, b) => a.hoster.label.localeCompare(b.hoster.label)
        },
        updated_at: {
            label: "Letztes Update",
            sortable: true,
            render: (server) => {
                if (!server.updated_at)
                    return 'Nie';
                return <Moment withTitle titleFormat={"HH:mm, DD.MM.YYYY"} date={server.updated_at}
                               fromNow interval={1000}/>;
            }
        },
    };

    return <>
        <Header/>
        <Container className="listing-view server-listing" fluid>
            <header>
                <h1>Server</h1>
                <ButtonGroup>
                  <Button role={showDeactivated ? 'hide' : 'show'} color={showDeactivated ? "primary" : "secondary"} onClick={() => setShowDeactivated(t => !t)}>
                    {showDeactivated ? <GoEyeClosed/> : <GoEye/>}
                  </Button>
                </ButtonGroup>
            </header>
            <section className="server-list">
                <Card className="shadow">
                    <SortTable
                        responsive
                        striped
                        items={servers.filter((s) => showDeactivated ? true: !s.deactivated)}
                        columns={tableColumns}
                        sortColumn={sortColumn}
                        sortDir={sortDir}
                        onEntryClick={openServerDetailView}
                        onSort={onTableSort}
                    />
                </Card>
            </section>
            <LastUpdate date={lastUpdate} loading={isLoading}/>
        </Container>
    </>
}
ServerListing.propTypes = {
    route: PropTypes.instanceOf(RouteElement).isRequired
}

export default ServerListing;
