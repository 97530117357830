import {BaseModel} from "./base";
import {GiBrokenBone, GiCancel, GiCheckMark} from "react-icons/all";
import {IconType} from "react-icons/lib";
import {parseSemver} from "../helper";
import {isNull} from "lodash";
import {Server, ServerData} from "./server";

export type NPMData = {
    id: number
    name: string
    version: string
    latest_version: string
    wanted_version: null | string
    direct: boolean
}

export type EggData = {
    id: number
    name: string
    version: string
    plones?: PloneData[] | Plone[]
}

export type PloneAddonStatusKeys = 'installed' | 'not_installed' | 'broken'

export type AddonData = {
    name: string
    version: string
    status: PloneAddonStatusKeys
    plone?: PloneData | Plone | null
}

export type PloneData = {
    id: number
    name: string
    domain: string
    favicon: string
    repository_name: string
    repository_branch: string
    repository_namespace: string
    update_warranty: boolean
    os: string
    os_version: string
    python_version: string
    plone_version: string
    zope_version: string
    cmf_version: string
    pil_version: string
    npm_version: string
    nodejs_version: string
    debug_mode: boolean
    updated_at: string
    master_plone_template_version: string
    tiles_version: string
    addons?: AddonData[]
    eggs?: EggData[]
    npm_packages?: NPMData[]
    server: ServerData|null
}

export type PloneAddonStateKeys = 'installed' | 'not_installed' | 'broken';

export type PloneAddonStateValue = {
    label: string
    icon: IconType
}

export const PloneAddonStates: Readonly<Record<PloneAddonStateKeys, PloneAddonStateValue>> = Object.freeze({
    installed: {
        label: 'Installiert',
        icon: GiCheckMark,
    },
    not_installed: {
        label: 'Nicht installiert',
        icon: GiCancel,
    },
    broken: {
        label: 'Gebrochen',
        icon: GiBrokenBone,
    }
});

/** Model for PloneAddon  {python:database.plone.addon.PloneAddon} */
export class Addon extends BaseModel {
    name: string;
    version: string;
    status: PloneAddonStateKeys;
    plone: Plone | null;

    constructor(addonData: AddonData, plone?: Plone) {
        super(addonData);
        this.name = addonData.name;
        this.version = addonData.version;
        this.status = addonData.status;
        this.plone = plone ?? null;

        if (!plone && addonData.plone) {
            this.plone = (addonData.plone instanceof Plone) ? addonData.plone : new Plone(addonData.plone);
        }
    }

    update(data: Partial<AddonData>, plone?: Plone): this {
        this.name = data.name ?? this.name;
        this.version = data.version ?? this.version;
        this.status = data.status ?? this.status;

        if (data.plone) {
            if (this.plone) {
                if (data.plone instanceof Plone) {
                    this.plone = data.plone;
                }
                else {
                    this.plone = this.plone.update(data.plone);
                }
            }
            else {
                this.plone = (data.plone instanceof Plone) ? data.plone : new Plone(data.plone);
            }
        }
        this.plone = plone ?? this.plone;
        return this;
    }

    get status_label(): string {
        return PloneAddonStates[this.status].label;
    }

    get status_icon(): IconType {
        return PloneAddonStates[this.status].icon;
    }

    toString(): string {
        return super.toString(['name', 'version']);
    }
}

/** Model for PloneEgg {python:database.plone.egg.PloneEgg} */
export class Egg extends BaseModel {
    name: string;
    version: string;
    plones: Plone[] = [];

    constructor(eggData: EggData, plone?: Plone) {
        super(eggData);
        this.name = eggData.name;
        this.version = eggData.version;

        if (eggData.plones) {
            this.plones = eggData.plones.map((ploneData) => {
                if (ploneData instanceof Plone) return ploneData;
                return (plone?.id === ploneData.id) ? plone : new Plone(ploneData);
            });
        }

        if (plone && !this.plones.find((e) => e.id === plone.id)) {
            this.plones.push(plone);
        }
    }

    update(data: Partial<EggData>): this {
        super.update(data);
        this.name = data.name ?? this.name;
        this.version = data.version ?? this.version;

        if (data.plones) {
            this.plones = data.plones.map((ploneData) => {
                if (ploneData instanceof Plone) return ploneData;
                const existingPlone = this.plones.find((e) => e.id === ploneData.id);
                return existingPlone ? existingPlone.update(ploneData) : new Plone(ploneData);
            });
        }
        return this;
    }

    toString(): string {
        return super.toString(['name', 'version']);
    }

    getPyPiURL(): string {
        return `https://pypi.org/project/${this.name}/${this.version}/`;
    }
}

/** Model for PloneNPMPackage {python:database.plone.npm_package.PloneNPMPackage} */
export class NPMPackage extends BaseModel {
    name: string;
    version: string;
    latest_version: string;
    wanted_version: string | null;
    direct: boolean = false;

    constructor(npmData: NPMData) {
        super(npmData);
        this.name = npmData.name;
        this.version = npmData.version;
        this.latest_version = npmData.latest_version;
        this.wanted_version = npmData.wanted_version;
        this.direct = npmData.direct;
    }

    update(data: Partial<NPMData>): this {
        super.update(data);
        this.name = data.name ?? this.name;
        this.version = data.version ?? this.version;
        this.latest_version = data.latest_version ?? this.latest_version;
        this.wanted_version = data.wanted_version ?? this.wanted_version;
        this.direct = data.direct ?? this.direct;
        return this;
    }

    is_latest = (): boolean => {
        const version = parseSemver(this.version);
        if (isNull(version)) {
            return false;
        }
        const latest_version = parseSemver(this.latest_version);
        if (isNull(latest_version)) {
            return false;
        }

        const [current_major, current_minor, current_patch] = version;
        const [latest_major, latest_minor, latest_patch] = latest_version;

        if (current_major < latest_major) {
            return false;
        }

        if (current_minor < latest_minor) {
            return false;
        }

        return current_patch >= latest_patch;
    };

    toString(): string {
        return super.toString(['name', 'version']);
    }

    getNPMRegistryURL(version?: 'latest'|'wanted'): string {
        let versionString;
        switch(version){
            case 'wanted':
                versionString = this.wanted_version ?? this.version;
                break;
            case 'latest':
                versionString = this.latest_version;
                break;
            default:
                versionString = this.version;
        }
        return `https://www.npmjs.com/package/${this.name}/v/${versionString}`;
    }

}

/** Model for Plone {python:database.plone.plone.Plone} */
export class Plone extends BaseModel {
    name: string;
    domain: string;
    /** base64 encoded string */
    __favicon__: string | null;
    repository_name: string;
    repository_branch: string;
    repository_namespace: string;
    update_warranty: boolean;
    os: string;
    os_version: string;
    python_version: string;
    plone_version: string;
    zope_version: string;
    cmf_version: string;
    pil_version: string;
    nodejs_version: string;
    npm_version: string;
    debug_mode: boolean;
    updated_at: Date;
    master_plone_template_version: string;
    tiles_version: string;
    addons: Addon[] = [];
    eggs: Egg[] = [];
    npm_packages: NPMPackage[] = [];
    server: Server|null;

    constructor(ploneData: PloneData, server?: Server) {
        super(ploneData);
        this.name = ploneData.name;
        this.domain = ploneData.domain;
        this.__favicon__ = ploneData.favicon ?? null;
        this.repository_name = ploneData.repository_name;
        this.repository_branch = ploneData.repository_branch;
        this.repository_namespace = ploneData.repository_namespace;
        this.update_warranty = ploneData.update_warranty;
        this.os = ploneData.os;
        this.os_version = ploneData.os_version;
        this.python_version = ploneData.python_version;
        this.plone_version = ploneData.plone_version;
        this.zope_version = ploneData.zope_version;
        this.cmf_version = ploneData.cmf_version;
        this.pil_version = ploneData.pil_version;
        this.nodejs_version = ploneData.nodejs_version;
        this.npm_version = ploneData.npm_version;
        this.debug_mode = ploneData.debug_mode;
        this.updated_at = this.convertDate(ploneData.updated_at) ?? new Date(0);
        this.master_plone_template_version = ploneData.master_plone_template_version;
        this.tiles_version = ploneData.tiles_version;
        this.server = server ?? null;

        if(ploneData.server && !server){
            this.server = new Server(ploneData.server);
        }

        if (ploneData.addons) {
            this.addons = ploneData.addons.map((addonData) => {
                return new Addon(addonData, this);
            });
        }
        if (ploneData.eggs) {
            this.eggs = ploneData.eggs.map((eggData) => {
                return new Egg(eggData, this);
            });
        }
        if (ploneData.npm_packages) {
            this.npm_packages = ploneData.npm_packages.map((npmPackageData) => {
                return new NPMPackage(npmPackageData);
            });
        }
    }

    update(ploneData: Partial<PloneData>, server?: Server): this {
        super.update(ploneData);
        this.name = ploneData.name ?? this.name;
        this.domain = ploneData.domain ?? this.domain;
        this.__favicon__ = ploneData.favicon ?? this.__favicon__;
        this.repository_name = ploneData.repository_name ?? this.repository_name;
        this.repository_branch = ploneData.repository_branch ?? this.repository_branch;
        this.repository_namespace = ploneData.repository_namespace ?? this.repository_namespace;
        this.update_warranty = ploneData.update_warranty ?? this.update_warranty;
        this.os = ploneData.os ?? this.os;
        this.os_version = ploneData.os_version ?? this.os_version;
        this.python_version = ploneData.python_version ?? this.python_version;
        this.plone_version = ploneData.plone_version ?? this.plone_version;
        this.zope_version = ploneData.zope_version ?? this.zope_version;
        this.cmf_version = ploneData.cmf_version ?? this.cmf_version;
        this.pil_version = ploneData.pil_version ?? this.pil_version;
        this.nodejs_version = ploneData.nodejs_version ?? this.nodejs_version;
        this.npm_version = ploneData.npm_version ?? this.npm_version;
        this.debug_mode = ploneData.debug_mode ?? this.debug_mode;
        this.updated_at = this.convertDate(ploneData.updated_at) ?? this.updated_at;
        this.master_plone_template_version = ploneData.master_plone_template_version ?? this.master_plone_template_version;
        this.tiles_version = ploneData.tiles_version ?? this.tiles_version;
        this.server = server ?? this.server;

        if(ploneData.server && !server){
            this.server = new Server(ploneData.server);
        }

        if (ploneData.addons) {
            this.addons = ploneData.addons.map((addonData) => {
                const existingAddon = this.addons.find(({name}) => name === addonData.name);
                return existingAddon ? existingAddon.update(addonData) : new Addon(addonData);
            });
        }

        if (ploneData.eggs) {
            this.eggs = ploneData.eggs.map((eggData) => {
                const existingEgg = this.eggs.find((e) => e.id === eggData.id);
                return existingEgg ? existingEgg.update(eggData) : new Egg(eggData);
            });
        }

        if (ploneData.npm_packages) {
            this.npm_packages = ploneData.npm_packages.map((npmData) => {
                const existingPackage = this.npm_packages.find((e) => e.name === npmData.name);
                return existingPackage ? existingPackage.update(npmData) : new NPMPackage(npmData);
            });
        }
        return this;
    }

    get favicon(): string {
        if(this.__favicon__) return this.__favicon__;
        return '/fallback.svg';
    }

    getURL() {
        return `/plone/${this.domain}`;
    }

    toString(): string {
        return super.toString(['id', 'name']);
    }
}
