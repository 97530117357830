import Header from "../../layouts/Header";
import {
  Alert,
  Button,
    ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane
} from "reactstrap";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  GitLabProjectData,
  PloneData,
  RouteElement,
  ServerData,
  TUserPermissions,
  User,
  UserData,
  UserPermissions,
  UserPermissionsKeys
} from "../../types";
import {useUserContext} from "../../user";
import Moment from "react-moment";
import PropTypes from "prop-types";
import {FaCheck, FaCog, FaEdit, FaQuestion, FaTimes, FaTrash, GoPlus, GoEye, GoEyeClosed} from "react-icons/all";
import {PasswordField} from "../../components/Forms";
import {ProfileAvatarEditor} from "./ProfileView";
import classnames from "classnames";
import {LastUpdate} from "../../components";
import _ from "lodash";
import {REFRESH_INTERVAL} from "../../config";
import Select, {MultiValue} from "react-select";
import {DistributionsIcons} from "../servers/mapping";
import {useHistory, useRouteMatch} from "react-router-dom";

type Option = {
  label: string,
  value: number,
  richLabel: JSX.Element
};
type OptionsValue = {
  isLoading: boolean
  options: Option[]
};

interface IUserModal {
  isOpen?: boolean
  onSubmit: (user: User) => void
  onClose?: () => void
  user?: User
  serverOptions: OptionsValue
  projectOptions: OptionsValue
  ploneOptions: OptionsValue
}

const UserModal: React.FunctionComponent<IUserModal> = (props) => {
  const {request, currentUser, getAvatar} = useUserContext();
  const {isOpen, onSubmit, onClose, user, serverOptions, projectOptions, ploneOptions} = props;
  const formRef = useRef<HTMLFormElement>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [avatar, setAvatar] = useState<string | null>(null);
  const [editAvatar, setEditAvatar] = useState<boolean>(false);
  const defaultPermissions: TUserPermissions = Array.from(UserPermissions).reduce((obj, [key]) => {
    obj[key] = false;
    return obj;
  }, {} as TUserPermissions);
  const [permissions, setPermissions] = useState<TUserPermissions>(user?.permissions ?? defaultPermissions);
  type possibleTabs = 'overview' | 'credentials' | 'integrations' | 'permissions';
  const [activeTab, setActiveTab] = useState<possibleTabs>('overview');

  const toggleTab = (tab: possibleTabs) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onAvatarChange = (img: string | null) => setAvatar(img);

  const onModalClose = () => {
    formRef.current?.reset();
    setPermissions(user?.permissions ?? defaultPermissions);
    setAvatar(null);
    if (typeof onClose == "function") onClose();
  };

  useEffect(() => setAvatar(user ? user.avatar : null), [user]);

  const onFormSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    if (editAvatar && ((user && avatar !== user.avatar) || (!user && avatar))) {
      formData.append('avatar', avatar ? avatar.toString() : 'null');
    }

    // reset avatar editor
    setEditAvatar(false);

    let keys_to_delete: string[] = [];
    for (let key of formData.keys()) {
      const value = formData.get(key);
      if (!user) continue;
      if (value === user[key as keyof User]) keys_to_delete.push(key);
    }
    keys_to_delete.forEach((key) => formData.delete(key));

    if(currentUser?.hasPermission('update_user')){
      formData.set('permissions', '');
      Object.entries(permissions).forEach(([permission, value]) => {
        if(value instanceof Array){
          value.forEach((entry) => {
            formData.append(`permissions.${permission}`, entry.toString());
          });
        }
        else {
          formData.set(`permissions.${permission}`, value === true ? 'on' : 'off');
        }
      });
    }

    if (!formData.get('password')) {
      formData.delete('password');
    }
    if (formData.has('email') && !formData.has('password')) {
      setMessage('Beim ändern der Email muss das Passwort gesetzt werden');
      return;
    }
    setIsSubmitting(true);

    request<UserData>(user ? `/user/${user.id}` : '/user/add', user ? 'PUT' : 'POST', formData)
        .then((userData) => onSubmit(user ? user.update(userData) : new User(userData)))
        .catch(reason => setMessage(reason.toString()))
        .finally(() => setIsSubmitting(false));
  }, [avatar, onSubmit, request, user, editAvatar, permissions, currentUser]);

  const optionsMap = new Map<UserPermissionsKeys, OptionsValue>([
    ['view_gitlab', projectOptions],
    ['view_server', serverOptions],
    ['view_plone', ploneOptions],
    ['edit_plone', ploneOptions],
    ['edit_server', serverOptions]
  ]);

  return <Modal isOpen={isOpen} size={"lg"} backdrop className={'user-modal'}>
    <Form onSubmit={onFormSubmit} innerRef={formRef}>
      <ModalHeader>{user ? `Mitarbeiter ${user.name} bearbeiten` : 'Mitarbeiter hinzufügen'}</ModalHeader>
      <ModalBody>
        <Alert color="danger" isOpen={!!message} toggle={() => setMessage('')}>{message}</Alert>
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({active: activeTab === 'overview'})} onClick={() => toggleTab('overview')}>
              Übersicht
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({active: activeTab === 'credentials'})}
                     onClick={() => toggleTab('credentials')}>
              Anmeldedaten
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({active: activeTab === 'integrations'})}
                     onClick={() => toggleTab('integrations')}>
              Integrationen
            </NavLink>
          </NavItem>
          {currentUser && currentUser.hasPermission('update_user') ?
              <NavItem>
                <NavLink className={classnames({active: activeTab === 'permissions'})}
                         onClick={() => toggleTab('permissions')}>
                  Berechtigungen
                </NavLink>
              </NavItem> : null
          }
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={'overview'}>
            <FormGroup>
              <Label for="name-field">Name</Label>
              <Input id="name-field"
                     type="text"
                     name="name"
                     autoComplete="name"
                     defaultValue={user ? user.name : ''}
                     required
              />
            </FormGroup>
            <FormGroup>
              <Label for="avatar-field">Avatar</Label>
              <div className={'avatar-editor'}>
                {editAvatar ?
                    <ProfileAvatarEditor avatar={user ? user.avatar : null} onImageChange={onAvatarChange}/> :
                    getAvatar(user || '', {className: 'avatar'})
                }
                <Button color={editAvatar ? 'danger' : 'primary'} onClick={() => setEditAvatar(!editAvatar)}>
                  {editAvatar ? <FaTimes/> : <FaEdit/>}
                </Button>
              </div>
            </FormGroup>
            {user && currentUser && currentUser.hasPermission('update_user') ?
                <>
                  <FormGroup check>
                    <Input id="deactivated-field" type="checkbox" name="deactivated"
                           defaultChecked={user ? user.deactivated : false}/>
                    <Label for="deactivated-field">
                      Deaktiviert
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label for="deactivated_reason-field">Name</Label>
                    <Input id="deactivated_reason-field"
                           type="text"
                           name="deactivated_reason"
                           defaultValue={user ? user.deactivated_reason : ''}
                    />
                  </FormGroup>
                </>
                : null}
          </TabPane>
          <TabPane tabId={'credentials'}>
            <FormGroup>
              <Label for="email-field">Email</Label>
              <Input id="email-field"
                     type="email"
                     name="email"
                     autoComplete="email"
                     defaultValue={user ? user.email : ''}
                     required
              />
            </FormGroup>
            <FormGroup>
              <Label for="password-field">Password</Label>
              <PasswordField id="password-field" name="password" autoComplete="new-password" required={!user}/>
            </FormGroup>
          </TabPane>
          <TabPane tabId={'integrations'}>
            <FormGroup>
              <Label for="gitlab-field">Gitlab</Label>
              <Input id="gitlab-field"
                     type="text"
                     name="gitlab"
                     autoComplete="off"
                     defaultValue={user ? user.gitlab : ''}/>
            </FormGroup>
            <FormGroup>
              <Label for="sentry-field">Sentry</Label>
              <Input id="sentry-field"
                     type="text"
                     name="sentry"
                     autoComplete="off"
                     defaultValue={user ? user.sentry : ''}/>
            </FormGroup>
          </TabPane>
          {currentUser?.hasPermission('update_user') &&
              <TabPane tabId={'permissions'}>
                <input type="hidden" name="permissions"/>
                <ul className={'permissions'}>
                  {Array.from(UserPermissions).map(([permission, {label, description}], key) => {
                    const optionsValue = optionsMap.get(permission);
                    let selectedOptions: Option[] = [];
                    const currentPermissions = permissions[permission];
                    if (optionsValue?.options instanceof Array) {
                      selectedOptions = optionsValue.options.filter((option) => currentPermissions instanceof Array ? currentPermissions.includes(option.value) : false);
                    }

                    const onChange = (data: React.ChangeEvent<HTMLInputElement> | MultiValue<Option>) => {
                      setPermissions(oldPermissions => {
                        let newValue: boolean | number[] = !oldPermissions[permission];
                        if (data instanceof Array) {
                          newValue = data.map(({value}) => value);
                        }
                        else if (selectedOptions.length) {
                          newValue = true;
                        }
                        return {...oldPermissions, [permission]: newValue};
                      });
                    };

                    return <li key={key}>
                      <Label for={`permission-${permission}-field`}>{label}</Label>
                      <Input id={`permission-${permission}-field`}
                             type="checkbox"
                             onChange={onChange}
                             checked={permissions[permission] === true}
                      />
                      <p className="field-description text-muted">{description}</p>
                      {optionsValue ? <Select
                          isDisabled={permissions[permission] === true}
                          isMulti
                          value={selectedOptions}
                          onChange={onChange}
                          options={optionsValue.options}
                          isLoading={optionsValue.isLoading}
                          formatOptionLabel={(option) => option.richLabel}
                      /> : null}
                    </li>;
                  })}
                </ul>
              </TabPane>
          }
        </TabContent>
      </ModalBody>
      <ModalFooter>
        {isSubmitting ?
            <Spinner size={'sm'}/>
            : <>
              <Button outline type="button" onClick={onModalClose}>Schließen</Button>
              <Button type="submit" color="primary">{user ? 'Speichern' : 'Erstellen'}</Button>
            </>}
      </ModalFooter>
    </Form>
  </Modal>;
};
const optionsValidator = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    richLabel: PropTypes.element.isRequired
  }).isRequired).isRequired
}).isRequired;
UserModal.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  user: PropTypes.instanceOf(User),
  serverOptions: optionsValidator,
  projectOptions: optionsValidator,
  ploneOptions: optionsValidator,
};


interface IUserEntry {
  route: RouteElement
  user: User
  onEditToggle?: (editState: boolean, user: User) => void
  onSubmit?: (user: User) => void
  onDelete?: (user: User) => void
  serverOptions: OptionsValue
  projectOptions: OptionsValue
  ploneOptions: OptionsValue
}

/** Entry of a User in UsersView */
const UserEntry: React.FunctionComponent<IUserEntry> = (props) => {
  const {user, onEditToggle, onDelete, onSubmit, serverOptions, projectOptions, ploneOptions, route} = props;
  const {getAvatar, request, addNotification, currentUser} = useUserContext();
  const match = useRouteMatch<{user_id: string}>(`${route.path}/:user_id`);
  const [edit, setEdit] = useState<boolean>(match?.params.user_id ? parseInt(match.params.user_id) === user.id : false);
  const [deletion, setDeletion] = useState<boolean>(false);
  const [deleteProgress, setDeleteProgress] = useState<boolean>(false);

  const editUser = (editState: boolean, user: User) => {
    setEdit(editState);
    if(typeof onEditToggle == 'function') onEditToggle(editState, user);
    if(editState){
      if (typeof onSubmit == 'function') onSubmit(user);
    }
  };

  const deleteUser = () => {
    setDeleteProgress(true);
    request<boolean>(`/user/${user.id}`, 'DELETE')
        .then(() => {
          addNotification('Erfolgreich', `Der Benutzer ${user.name} wurde erfolgreich gelöscht`, "success");
          if (typeof onDelete == 'function') onDelete(user);
        })
        .catch(reason => addNotification('Fehler', reason.toString(), 'danger'))
        .finally(() => setDeleteProgress(false))
  };

  return <Card className={'user-entry'}>
    <CardHeader>
      {getAvatar(user, {className: 'avatar'})} <h2>{user.name}</h2>
      {currentUser && currentUser.hasPermission('update_user') ?
          <Button role="edit" color="primary" onClick={() => editUser(true, user)}><FaCog/></Button> : null}
      {currentUser && currentUser.hasPermission('remove_user') ?
          <Button role="remove" color="danger" onClick={() => setDeletion(true)}><FaTrash/></Button> : null}
    </CardHeader>
    <CardBody>
      <dl className={'table-grid'}>
        <dt>ID</dt>
        <dd>{user.id}</dd>
        <dt>Email</dt>
        <dd>{user.email}</dd>
        <dt>Letzte Aktivität</dt>
        <dd>{user.last_seen ? <Moment fromNow titleFormat={"HH:mm:ss, DD.MM.YYYY"} withTitle interval={200}>{user.last_seen}</Moment> : 'Nie'}</dd>
        <dt>Deaktiviert</dt>
        <dd>{user.deactivated ? <span><FaCheck/> {user.deactivated_reason}</span> : <FaTimes/>}</dd>
        <dt>Registriert</dt>
        <dd><Moment format="LLL">{user.registered}</Moment></dd>
      </dl>
    </CardBody>
    <UserModal
        user={user}
        isOpen={edit}
        onSubmit={(u) => editUser(false, u)}
        onClose={() => editUser(false, user)}
        projectOptions={projectOptions}
        serverOptions={serverOptions}
        ploneOptions={ploneOptions}
    />
    <Modal isOpen={deletion}>
      <ModalHeader>{user.name} löschen</ModalHeader>
      <ModalBody>Soll der User {user.name}({user.id}) wirklich gelöscht werden?</ModalBody>
      <ModalFooter>
        {deleteProgress ? null : <Button outline onClick={() => setDeletion(false)}>Schließen</Button>}
        {deleteProgress ? <Spinner/> : <Button color="primary" onClick={deleteUser}>Löschen</Button>}
      </ModalFooter>
    </Modal>
  </Card>;
};
UserEntry.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  onEditToggle: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  serverOptions: optionsValidator,
  projectOptions: optionsValidator,
  ploneOptions: optionsValidator,
};

/** List of all Users */
const UsersView: React.FunctionComponent<{route: RouteElement}> = ({route}) => {
  const {request, addNotification, currentUser} = useUserContext();
  const history = useHistory();
  const [users, setUsers] = useState<User[]>([]);
  const [usersLoaded, setUsersLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<Date|null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeactivatedUsers, setShowDeactivatedUsers] = useState<boolean>(false);
  const [projectOptions, setProjectOptions] = useState<OptionsValue>({isLoading: false, options: []});
  const [serverOptions, setServerOptions] = useState<OptionsValue>({isLoading: false, options: []});
  const [ploneOptions, setPloneOptions] = useState<OptionsValue>({isLoading: false, options: []});

  const getProjectOptions = useCallback(() => {
    setProjectOptions(oldOptions => {
      return {...oldOptions, isLoading: true};
    });

    request<GitLabProjectData[]>('/gitlab/projects', 'GET')
        .then((projects) => setProjectOptions({isLoading: false, options: projects.map(project => {
            return {
              label: project.name,
              value: project.id,
              richLabel: <><img height={32} width={32} alt="" src={project.avatar ?? '/fallback.svg'}/> {project.name}</>
            } as Option;
          })}))
        .catch(reason => console.error(reason));
  }, [request]);
  const getServerOptions = useCallback(() => {
    setServerOptions(oldOptions => {
      return {...oldOptions, isLoading: true};
    });
    request<ServerData[]>('/server')
        .then((servers) => setServerOptions({isLoading: false, options: servers.map(server => {
            const Icon = DistributionsIcons.get(server.distribution.toLowerCase()) ?? FaQuestion;
            return {
              label: server.hostname,
              value: server.id,
              richLabel: <><Icon/> {server.hostname}</>
            } as Option;
          })
        }))
        .catch(reason => console.error(reason));
  }, [request]);
  const getPloneOptions = useCallback(() => {
    setPloneOptions(oldOptions => {
      return {...oldOptions, isLoading: true};
    });
    request<PloneData[]>('/plone')
        .then((plones) => setPloneOptions({
          isLoading: false, options: plones.map(plone => {
            return {
              label: plone.name,
              value: plone.id,
              richLabel: <><img height={32} width={32} alt="" src={plone.favicon ?? '/fallback.svg'}/> {plone.name}</>
            } as Option;
          })
        }))
        .catch(reason => console.error(reason));
  }, [request]);

  useEffect(() => {
    const intervalFunction = (interval_duration?: number): NodeJS.Timer | undefined => {
      setIsLoading(true);
      request<UserData[]>('/user/all', 'GET')
          .then((usersData) => {
            setUsersLoaded(true);
            setIsLoading(false);
            setLastUpdate(new Date());
            setUsers(_users => usersData.map((userData) => {
              const index = _.findIndex(_users, {id: userData.id});
              if (index >= 0) {
                const user = _users[index];
                return user.update(userData);
              }
              return new User(userData);
            }));
          })
          .catch((reason) => addNotification('Fehler', reason.toString(), 'danger'));

      if(currentUser?.hasPermission('update_user')){
        if(currentUser.hasPermission('view_gitlab')) getProjectOptions();
        if(currentUser.hasPermission('view_server')) getServerOptions();
        if(currentUser.hasPermission('view_plone')) getPloneOptions();
      }

      if (interval_duration) {
        return setInterval(() => intervalFunction(), interval_duration);
      }
      return undefined;
    }
    const interval = intervalFunction(REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [request, addNotification, currentUser, getPloneOptions, getProjectOptions, getServerOptions]);

  const onAdd = (user: User) => {
    setShowModal(false);
    setUsers(userList => {
      userList.push(user);
      return [...userList];
    });
  };

  const onSubmit = (index: number) => (user: User) => {
    setUsers(userList => {
      userList[index] = user;
      return [...userList];
    });
  };

  const onDelete = (index: number) => () => {
    setUsers(userList => {
      userList.splice(index, 1);
      return [...userList];
    });
  };

  const onEditToggle = (editState: boolean, user: User) => {
    if(editState) history.push(`${route.path}/${user.id}`);
    else history.push(`${route.path}`);
  };

  return <>
    <Header/>
    <Container fluid className="users-view listing-view">
      <header>
        <h1>Benutzer</h1>
        <ButtonGroup>
          <Button role={showDeactivatedUsers ? 'hide' : 'show'} color={showDeactivatedUsers ? "primary" : "secondary"} onClick={() => setShowDeactivatedUsers(t => !t)}>
            {showDeactivatedUsers ? <GoEyeClosed/> : <GoEye/>}
          </Button>
          {currentUser && currentUser.hasPermission('add_user') ? <Button role="add" color="primary" onClick={() => setShowModal(true)}><GoPlus/></Button> : null}
        </ButtonGroup>
      </header>
      {usersLoaded ? null : <div style={{textAlign: 'center'}}><Spinner/></div>}
      <section className={'listing'}>
        {users.filter((u) => showDeactivatedUsers ? true : !u.deactivated).map((user, key) => {
          return <UserEntry
              route={route}
              user={user}
              key={key}
              onSubmit={onSubmit(key)}
              onDelete={onDelete(key)}
              onEditToggle={onEditToggle}
              projectOptions={projectOptions}
              ploneOptions={ploneOptions}
              serverOptions={serverOptions}
          />;
        })}
      </section>
      <LastUpdate date={lastUpdate} loading={isLoading}/>
      <UserModal
          isOpen={showModal}
          onSubmit={onAdd}
          onClose={() => setShowModal(false)}
          projectOptions={projectOptions}
          ploneOptions={ploneOptions}
          serverOptions={serverOptions}
      />
    </Container>
  </>
}

export default UsersView;